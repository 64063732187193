import EasyInfiniteScroll from "components/basics/EasyInfiniteScroll";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { CollectionsByIds, UserOffersFetcher } from "graphql/api";
import OfferRow from "./OfferRow";
import { useCallWith404OnFailure } from "helpers/Hooks";

let userOffersFetcher = new UserOffersFetcher();

export default function Offers({ address }) {
  const [offers, setOffers] = useState([]);
  const [collectionsInfo, setCollectionInfo] = useState([]);

  const fetchUserOffers = useCallWith404OnFailure(
    async (_pageNum) => await userOffersFetcher.fetch(address)
  );

  useEffect(() => {
    (async () => {
      if (address == null) {
        return;
      }
      let collectionIds = [];
      for (let nft of offers) {
        if (collectionIds.indexOf(nft.nftAddress) === -1) {
          collectionIds.push(nft.nftAddress);
        }
      }
      const fetchedCollectionInfo = await CollectionsByIds(collectionIds);
      setCollectionInfo(fetchedCollectionInfo);
    })();
  }, [address, offers]);

  return (
    <>
      <div className="flex border max-w-4xl rounded-t-lg flex-1 border-darkGray flex-col">
        <div className=" border-2xl flex bg-opacity-20 align-center justify-center">
          <EasyInfiniteScroll
            pageStart={1}
            loader={
              <div className="flex w-full justify-center my-24">
                <Loading hidePadding hideTopPadding />
              </div>
            }
            threshold={800}
            onDataChanged={setOffers}
            hasNextPage={(fetchedData) => fetchedData.length !== 0}
            renderData={(offers) => {
              return (
                <div className="flex flex-col w-full">
                  {offers.map((offer, index) => (
                    <OfferRow
                      collectionInfo={collectionsInfo.find(
                        (collectionInfo) =>
                          collectionInfo.id === offer.nftAddress
                      )}
                      data={offer}
                      key={index}
                    />
                  ))}
                </div>
              );
            }}
            renderEmptyState={() => (
              <div className="font-semibold py-8 rounded-t-lg text-lg text-white">
                No offers yet
              </div>
            )}
            fetchPage={fetchUserOffers}
            sensitivityListForPaginationReset={[address]}
          />
        </div>
      </div>
    </>
  );
}
