import Notifications from "./Notifications";

export default function Settings() {
  return (
    <div className="w-full flex flex-col items-center text-white bg-cover">
      <div className="flex w-full gap-6 mt-8 flex-col max-w-7xl">
        <span className="font-bold text-5xl">Profile Settings</span>
        <Notifications />
      </div>
    </div>
  );
}
