import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collectionMeta } from "../../helpers/collectionInfo";
import { useEVM } from "../../hooks/EVMhook";
import ListingDetails from "./ListingDetails/ListingDetails";
import PriceHistory from "./PriceHistory/PriceHistory";
import OfferModal from "../../components/Offer/OfferModal";
import ListItemModal from "../../components/List/ListItemModal";
import Offers from "./Offers/Offers";
import {
  ERC1155Balance,
  TokenActivity,
  TokenDetail,
  TokenListings,
} from "../../graphql/api";
import { isSameAddress } from "../../helpers/address";
import BuyModal from "../../components/BuyModal";
import CancelListing from "../../components/List/CancelListing";
import { useTokenData } from "../../helpers/tokens";
import SendModal from "components/SendModal";
import Header from "./Header";
import ERC1155Listings from "./ERC1155Listings/ERC1155Listings";
import ActivityList from "components/activity/ActivityList";
import Container from "./Info/Container";
import NFTImage from "./NFTImage";
import { useSelector } from "react-redux";
import { useCallWith404OnFailure } from "helpers/Hooks";
import BundleContents from "./Info/BundleContents";
import AuctionModal from "components/Auction/AuctionModal";
import BidModal from "components/Auction/BidModal";
import SettleModal from "components/Auction/SettleModal";
import UnbundleModal from "components/Bundle/UnbundleModal";
import BundleBreakdownTable from "./BundleBreakdownTable/BundleBreakdownTable";

export default function Listing() {
  const dataRefresher = useSelector(
    (state) => state.dataRefresher.transactionCounter
  );
  const { nftId, collection } = useParams();
  const [data, setData] = useState({});
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [showCancelListModal, setShowCancelListModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showAuction, setShowAuction] = useState(false);
  const [showSettleAuction, setShowSettleAuction] = useState(false);
  const [showUnbundle, setShowUnbundle] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [erc1155Balance, setErc1155Balance] = useState(null);
  const [listings, setListings] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);

  const { address, orbitBundlesContract } = useEVM();

  const tokenData = useTokenData(nftId, collection, data?.token?.isERC1155);

  const isBundle =
    data?.token?.nftAddress != null &&
    orbitBundlesContract?.instance?.address &&
    isSameAddress(
      data?.token?.nftAddress,
      orbitBundlesContract?.instance?.address
    );

  const fetchTokenActivityPage = useCallWith404OnFailure(async (pageNum) => {
    return await TokenActivity(nftId, collection, pageNum);
  });

  const viewerListing = listings?.find((listing) =>
    isSameAddress(listing.owner, address)
  );

  function getOwner() {
    if (data?.auction) {
      return data?.auction.owner;
    } else if (data?.token?.isERC1155) {
      return erc1155Balance?.owner;
    } else {
      return data?.token?.owner;
    }
  }

  const owner = getOwner();

  useEffect(() => {
    if (address) {
      ERC1155Balance(collection?.toLowerCase(), nftId, address).then((d) => {
        setErc1155Balance(d);
      });
    }

    TokenDetail(nftId, collection?.toLowerCase(), address).then((d) => {
      const bundle = isSameAddress(
        d?.token?.nftAddress,
        orbitBundlesContract?.instance?.address
      );
      setCollectionData({
        ...d.collection,
        ...collectionMeta[bundle ? d?.token?.bundle?.nftAddress : collection],
      });
      setData(d);
      TokenListings(nftId, collection, d?.token?.isERC1155).then((d) => {
        setListings(d);
      });
    });
  }, [
    address,
    collection,
    nftId,
    dataRefresher,
    orbitBundlesContract?.instance?.address,
  ]);

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="flex w-full  max-w-7xl gap-10 py-8 px-2 flex-col"
    >
      {showBuyModal && (
        <BuyModal
          data={{ ...data?.token, ...tokenData, ...data?.listing }}
          collectionMeta={collectionData}
          closeModal={() => setShowBuyModal(false)}
          listings={listings}
        />
      )}
      {showAuction && (
        <AuctionModal
          tokenData={{ ...data?.token, ...tokenData }}
          collectionData={collectionData}
          viewerHasOffer={data?.viewerOffer}
          nftId={nftId}
          collection={collection}
          closeModal={() => setShowAuction(false)}
        />
      )}
      {showUnbundle && (
        <UnbundleModal
          closeModal={() => setShowUnbundle(false)}
          tokenData={{ ...data?.token, ...tokenData }}
        />
      )}
      {showOfferModal && (
        <OfferModal
          tokenData={{ ...data?.token, ...tokenData }}
          collectionData={collectionData}
          viewerHasOffer={data?.viewerOffer}
          nftId={nftId}
          collection={collection}
          closeModal={() => setShowOfferModal(false)}
        />
      )}
      {showListModal && (
        <ListItemModal
          tokenData={{ ...data?.token, ...tokenData }}
          collectionData={collectionData}
          nftId={nftId}
          viewerListing={viewerListing}
          erc1155Balance={erc1155Balance}
          collection={collection}
          closeModal={() => setShowListModal(false)}
        />
      )}
      {showCancelListModal && (
        <CancelListing
          data={{ ...data?.token, ...tokenData }}
          closeModal={() => setShowCancelListModal(false)}
          collectionData={collectionData}
        />
      )}

      {showBidModal && (
        <BidModal
          tokenData={{ ...data?.token, ...tokenData, auction: data?.auction }}
          collectionData={collectionData}
          viewerHasOffer={data?.viewerOffer}
          nftId={nftId}
          collection={collection}
          closeModal={() => setShowBidModal(false)}
        />
      )}

      {showSendModal && isSameAddress(owner, address) && (
        <SendModal
          data={{ ...data?.token, ...tokenData, ...data?.listing }}
          erc1155Balance={erc1155Balance}
          collectionMeta={collectionData}
          closeModal={() => setShowSendModal(false)}
        />
      )}

      {showSettleAuction && (
        <SettleModal
          data={{ ...data?.auction, ...tokenData }}
          closeModal={() => setShowSettleAuction(false)}
        />
      )}

      <div className="md:hidden flex w-full gap-7 flex-col">
        {/* Right Column */}
        <Header
          setShowSendModal={setShowSendModal}
          address={address}
          isBundle={isBundle}
          meta={tokenData}
          collection={collection}
          collectionData={collectionData}
          nftId={nftId}
          data={data?.token}
          owner={owner}
          erc1155Balance={erc1155Balance}
        />
      </div>
      <div className="flex md:flex-row flex-col w-full gap-10">
        {/* Left Column */}
        <div className="flex max-w-mdd w-full gap-5 md:gap-10 flex-col">
          {!isBundle && <NFTImage data={{ ...data?.token, ...tokenData }} />}
          {isBundle && (
            <BundleContents token={{ ...data?.token, ...tokenData }} />
          )}
          <div className="flex md:hidden w-full gap-7 flex-col">
            {/* Right Column */}
            <div className=" gap-3 mt-5 w-full flex-col">
              <ListingDetails
                handleBuyItem={() => setShowBuyModal(true)}
                handleList={() => setShowListModal(true)}
                handleOffer={() => setShowOfferModal(true)}
                handleCancel={() => setShowCancelListModal(true)}
                data={data}
                viewerListing={viewerListing}
                listings={listings}
                handleBid={() => setShowBidModal(true)}
                owner={owner}
                address={address}
                highestOffer={data?.offers?.length > 0 ? data?.offers[0] : null}
                viewerHasOffer={data?.viewerOffer}
                handleAuction={() => setShowAuction(true)}
                handleSettle={() => setShowSettleAuction(true)}
                handleUnbundle={() => setShowUnbundle(true)}
              />
            </div>
            {!isBundle && (
              <div className=" gap-3 w-full flex-col">
                <PriceHistory
                  data={data?.sells}
                  openOnMount={true}
                  owner={data?.token?.owner}
                  address={address}
                />
              </div>
            )}
            {isBundle && (
              <div className=" gap-3 w-full flex-col">
                <BundleBreakdownTable bundle={data?.token?.bundle} />
              </div>
            )}
            {data?.token?.isERC1155 && (
              <div className="flex gap-3 w-full flex-col">
                <ERC1155Listings
                  data={{ ...data?.token, ...tokenData }}
                  collectionMeta={collectionData}
                  viewerListing={viewerListing}
                  address={address}
                  listings={listings}
                  erc1155Balance={erc1155Balance}
                />
              </div>
            )}
            <div className="flex gap-3 w-full flex-col">
              <Offers
                address={address}
                collectionMeta={collectionData}
                tokenId={nftId}
                owner={data?.token?.owner}
                meta={tokenData}
              />
            </div>
          </div>
          <Container
            data={{ ...data?.token, ...tokenData }}
            collectionData={collectionData}
            collection={collection}
          />
        </div>
        <div className="hidden md:flex w-full gap-7 flex-col">
          {/* Right Column */}
          <div className=" w-full">
            <Header
              setShowSendModal={setShowSendModal}
              address={address}
              isBundle={isBundle}
              meta={tokenData}
              collection={collection}
              collectionData={collectionData}
              nftId={nftId}
              data={data?.token}
              owner={owner}
              erc1155Balance={erc1155Balance}
            />
          </div>
          <div className="flex gap-3 mt-5 w-full flex-col">
            <ListingDetails
              handleBuyItem={() => setShowBuyModal(true)}
              handleList={() => setShowListModal(true)}
              handleOffer={() => setShowOfferModal(true)}
              handleCancel={() => setShowCancelListModal(true)}
              data={data}
              viewerListing={viewerListing}
              listings={listings}
              handleBid={() => setShowBidModal(true)}
              owner={owner}
              address={address}
              highestOffer={data?.offers?.length > 0 ? data?.offers[0] : null}
              viewerHasOffer={data?.viewerOffer}
              handleAuction={() => setShowAuction(true)}
              handleSettle={() => setShowSettleAuction(true)}
              handleUnbundle={() => setShowUnbundle(true)}
            />
          </div>
          {!isBundle && (
            <div className="flex gap-3 w-full flex-col">
              <PriceHistory
                data={data?.sells}
                openOnMount={true}
                owner={data?.token?.owner}
                address={address}
              />
            </div>
          )}
          {isBundle && (
            <div className=" gap-3 w-full flex-col">
              <BundleBreakdownTable bundle={data?.token?.bundle} />
            </div>
          )}
          {data?.token?.isERC1155 && (
            <div className="flex gap-3 w-full flex-col">
              <ERC1155Listings
                data={{ ...data?.token, ...tokenData }}
                collectionMeta={collectionData}
                viewerListing={viewerListing}
                address={address}
                listings={listings}
                erc1155Balance={erc1155Balance}
              />
            </div>
          )}
          <div className="flex gap-3 w-full flex-col">
            <Offers
              address={address}
              collectionMeta={collectionData}
              tokenId={nftId}
              collectionAddress={collection}
              owner={data?.token?.owner}
              meta={{ ...data?.token, ...tokenData }}
            />
          </div>
        </div>
      </div>
      <ActivityList fetchPage={fetchTokenActivityPage} />
    </div>
  );
}
