import { useEffect, useState } from "react";
import Orb from "../../assets/icons/WOrb.svg";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { ethers } from "ethers";
import Approve from "../Approve";
import cs from "classnames";
import { useApproveERC20 } from "../../hooks/marketTransactions";
import { DEFAULT_ERC20 } from "../../helpers/payTokens";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { isSameAddress } from "helpers/address";
import { nFormatter } from "helpers/formatNumber";

export default function PlaceBid({ tokenData, collectionData, closeModal }) {
  const dispatch = useDispatch();
  const [listingPrice, setListingPrice] = useState(0);

  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [balance, setBalance] = useState(0);

  const { auctionContract, address, erc20Contract } = useEVM();

  const isInvalidAmount =
    listingPrice > balance ||
    !listingPrice ||
    listingPrice === "" ||
    listingPrice === undefined;

  const {
    loading: loadingApprove,
    approve,
    checkApproval,
    transactionStarted,
  } = useApproveERC20((step) => {
    setStep(step);
  });

  useEffect(() => {
    if (address) {
      erc20Contract?.instance
        ?.attach(DEFAULT_ERC20)
        .balanceOf(address)
        .then((_balance) => {
          setBalance(parseFloat(ethers.utils.formatEther(_balance)));
        });
    }
  }, [address, erc20Contract?.instance]);

  async function createOffer() {
    setLoading(true);
    try {
      const transaction = await auctionContract?.instance.placeBid(
        tokenData?.nftAddress,
        tokenData?.tokenId,
        ethers.utils.parseEther(listingPrice.toString())
      );
      const toastId = loadingToast("Placing bid...");
      auctionContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          setStep(3);
          successToast("Bid placed!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    approve(DEFAULT_ERC20, auctionContract?.instance?.address);
  }

  async function handleOffer() {
    if (listingPrice > balance) {
      return;
    }
    checkApproval(address, DEFAULT_ERC20, auctionContract?.instance?.address);
  }

  return (
    <>
      {transactionStarted ? (
        <Approve
          step={step}
          success={"Bid submited!"}
          loading={loading || loadingApprove}
          approve={"Approve"}
          action={"Submit bid"}
          closeModal={closeModal}
          applyStep1={handleApprove}
          applyStep2={createOffer}
        />
      ) : (
        <>
          <div className="flex border-gray gap-3 flex-col border-b border-t py-6 justify-between px-10 ">
            <div className="flex justify-between">
              <div className="flex items-start w-full justify-between gap-3">
                <div className="flex gap-3">
                  {!tokenData?.bundle && (
                    <div className="w-16 self-center flex items-center flex-col justify-center">
                      <img
                        className="rounded-xl"
                        alt="marketCard"
                        src={tokenData?.image}
                      />
                    </div>
                  )}

                  <div className="flex flex-col">
                    <span className="font-semibold text-sm text-purple">
                      {collectionData?.name}
                    </span>
                    <span className="text-lg font-bold">
                      {tokenData?.tokenId}
                    </span>
                  </div>
                </div>
                <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
                  <span className="text-white text-lg font-medium">
                    <span className="mr-1">Highest bid </span>
                    {isSameAddress(tokenData?.auction?.currentBidder, address)
                      ? "(yours)"
                      : ""}
                  </span>
                  <div className="flex text-2xl gap-1.5 font-bold items-center">
                    <img className="h-5" alt="eth" src={Orb} />
                    {tokenData?.auction?.maximumBid
                      ? nFormatter(
                          ethers.utils.formatEther(
                            tokenData?.auction?.maximumBid
                          ),
                          2
                        )
                      : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-1 flex-col">
                <span className="font-bold w-24">Amount</span>

                <div className="flex items-center border  border-darkGray rounded">
                  <div className="flex items-center bg-darkGray rounded-l gap-1 py-1 w-26 px-2.5">
                    <img className="h-3 mt-0.5" alt="eth" src={Orb} />
                    <span className="font-semibold">WEVMOS</span>
                  </div>
                  <input
                    value={listingPrice}
                    type="number"
                    onChange={(e) =>
                      parseInt(e.target.value) >= 0 || !e.target.value
                        ? setListingPrice(parseFloat(e.target.value))
                        : null
                    }
                    className="flex-1 outline-none rounded-r bg-transparent border-darkGray border-l py-1 pl-2"
                    placeholder="Amount"
                  />
                </div>
              </div>

              <div
                className={cs("text-xs font-bold self-end text-gray", {
                  "text-red": listingPrice > balance,
                })}
              >
                Balance: {balance} WEVMOS
              </div>
            </div>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <button
              disabled={loading || loadingApprove || isInvalidAmount}
              onClick={handleOffer}
              className={cs("font-bold h-8 text-white rounded-md w-36 ", {
                "bg-purple": !(loading || loadingApprove) || !isInvalidAmount,
                "bg-purple opacity-40":
                  loading || loadingApprove || isInvalidAmount,
              })}
            >
              {loading || loadingApprove ? "Loading.." : "Place bid"}
            </button>
          </div>
        </>
      )}
    </>
  );
}
