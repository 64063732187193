import { useEffect, useState } from "react";
import Orb from "../../assets/icons/WOrb.svg";
import Datetime from "react-datetime";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { ethers } from "ethers";
import Approve from "../Approve";
import cs from "classnames";
import { useApproveERC20 } from "../../hooks/marketTransactions";
import { DEFAULT_ERC20 } from "../../helpers/payTokens";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";

export default function CreateOffer({ tokenData, collectionData, closeModal }) {
  const dispatch = useDispatch();
  const [listingPrice, setListingPrice] = useState(0);
  const [startTime] = useState(new Date(new Date().getTime() + 2 * 60 * 1000));
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10)
  );
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [balance, setBalance] = useState(0);

  const { erc721Contract, orbitContract, address, erc20Contract } = useEVM();

  const isInvalidAmount =
    listingPrice > balance ||
    !listingPrice ||
    listingPrice === "" ||
    listingPrice === undefined;

  const {
    loading: loadingApprove,
    approve,
    checkApproval,
    transactionStarted,
  } = useApproveERC20((step) => {
    setStep(step);
  });

  useEffect(() => {
    if (address) {
      erc20Contract?.instance
        ?.attach(DEFAULT_ERC20)
        .balanceOf(address)
        .then((_balance) => {
          setBalance(parseFloat(ethers.utils.formatEther(_balance)));
        });
    }
  }, [address, erc20Contract?.instance]);

  async function createOffer() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.createOffer(
        erc721Contract?.instance?.attach(tokenData?.nftAddress).address,
        tokenData?.tokenId,
        DEFAULT_ERC20,
        1,
        ethers.utils.parseEther(listingPrice.toString()),
        parseInt(endTime.getTime() / 1000)
      );
      const toastId = loadingToast("Creating offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          setStep(3);
          successToast("Offer created!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    approve(DEFAULT_ERC20);
  }

  async function handleOffer() {
    if (listingPrice > balance) {
      return;
    }
    checkApproval(address, DEFAULT_ERC20);
  }

  return (
    <>
      {transactionStarted ? (
        <Approve
          step={step}
          success={"Offer created!"}
          loading={loading || loadingApprove}
          approve={"Approve"}
          action={"Create offer"}
          closeModal={closeModal}
          applyStep1={handleApprove}
          applyStep2={createOffer}
        />
      ) : (
        <>
          <div className="flex border-gray gap-3 flex-col border-b border-t py-6 justify-between px-10 ">
            <div className="flex justify-between">
              <div className="flex items-start gap-3">
                {!tokenData?.bundle && (
                  <div className="w-16 self-center flex items-center flex-col justify-center">
                    <img
                      className="rounded-xl"
                      alt="marketCard"
                      src={tokenData?.image}
                    />
                  </div>
                )}

                <div className="flex flex-col">
                  <span className="font-semibold text-sm text-purple">
                    {collectionData?.name}
                  </span>
                  <span className="text-lg font-bold">
                    {tokenData?.tokenId}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-1 flex-col">
                <span className="font-bold w-24">Amount</span>

                <div className="flex items-center border  border-darkGray rounded">
                  <div className="flex items-center bg-darkGray rounded-l gap-1 py-1 w-26 px-2.5">
                    <img className="h-3 mt-0.5" alt="eth" src={Orb} />
                    <span className="font-semibold">WEVMOS</span>
                  </div>
                  <input
                    value={listingPrice}
                    type="number"
                    onChange={(e) =>
                      parseInt(e.target.value) >= 0 || !e.target.value
                        ? setListingPrice(parseFloat(e.target.value))
                        : null
                    }
                    className="flex-1 outline-none rounded-r bg-transparent border-darkGray border-l py-1 pl-2"
                    placeholder="Amount"
                  />
                </div>
              </div>

              <div
                className={cs("text-xs font-bold self-end text-gray", {
                  "text-red": listingPrice > balance,
                })}
              >
                Balance: {balance} WEVMOS
              </div>

              <div className="flex justify-between">
                <div className="flex gap-1 text-white flex-col">
                  <span className="font-bold w-24">Expiration</span>
                  <Datetime
                    value={endTime}
                    className={
                      "calendarAboveInput outline-none bg-dark border-darkGray border rounded-md pl-2 p-0.5 py-1.5 self-end"
                    }
                    onChange={(val) => setEndTime(val.toDate())}
                    inputProps={{
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                    closeOnSelect
                    isValidDate={(cur) =>
                      cur.valueOf() >
                        startTime.getTime() - 1000 * 60 * 60 * 23 &&
                      cur.valueOf() <
                        startTime.getTime() + 31 * 1000 * 60 * 60 * 23
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <button
              disabled={loading || loadingApprove || isInvalidAmount}
              onClick={handleOffer}
              className={cs("font-bold h-8 text-white rounded-md w-36 ", {
                "bg-purple": !(loading || loadingApprove) || !isInvalidAmount,
                "bg-purple opacity-40":
                  loading || loadingApprove || isInvalidAmount,
              })}
            >
              {loading || loadingApprove ? "Loading.." : "Create offer"}
            </button>
          </div>
        </>
      )}
    </>
  );
}
