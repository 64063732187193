import cs from "classnames";
import OfferRow from "./OfferRow";
import EasyInfiniteScroll from "components/basics/EasyInfiniteScroll";
import { TokenOffers } from "graphql/api";
import Loading from "components/Loading";

export default function Offers({
  meta,
  owner,
  address,
  collectionMeta,
  tokenId,
  collectionAddress,
}) {
  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        className={cs(
          "flex w-full border-b border-darkGray justify-between rounded-t-lg bg-dark bg-transparent  py-3  px-4"
        )}
      >
        <span className="font-semibold text-white text-lg">Offers</span>
      </div>
      {true && collectionMeta.contract && (
        <div className="bg-grayDark flex flex-col">
          <div className="flex text-white py-1 border-b px-4 bg-dark border-darkGray">
            <span className="w-3/12">Price</span>
            <span className="w-5/12">Expiration</span>
            <span className="w-3/12">From</span>

            <div className="flex w-28 mdd:w-36" />
          </div>
          <EasyInfiniteScroll
            renderData={(data) => (
              <div
                style={{ maxHeight: 190 }}
                className="flex overflow-y-scroll flex-col"
              >
                {data?.map((offer, index) => {
                  return (
                    <OfferRow
                      collectionMeta={collectionMeta}
                      tokenId={tokenId}
                      meta={meta}
                      key={index}
                      offer={offer}
                      owner={owner}
                      address={address}
                    />
                  );
                })}
              </div>
            )}
            renderEmptyState={() => (
              <span className="text-center text-white py-4">No offers yet</span>
            )}
            sensitivityListForPaginationReset={[collectionMeta]}
            pageStart={1}
            loader={<Loading hidePadding={true} />}
            fetchPage={async (pageNum) => {
              return await TokenOffers(
                tokenId,
                collectionAddress,
                owner,
                pageNum
              );
            }}
            threshold={400}
          ></EasyInfiniteScroll>
        </div>
      )}
    </div>
  );
}
