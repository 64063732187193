import cs from "classnames";

export default function Checkbox({ value, onChange }) {
  return (
    <label for="toggleA" class="flex items-center cursor-pointer">
      <div class="relative">
        <input
          onClick={() => onChange(!value)}
          id="toggleA"
          type="checkbox"
          className="sr-only"
          value={value}
        />
        <div class="w-10 h-4  bg-gray rounded-full shadow-inner"></div>
        <div
          className={cs(
            "absolute w-6 h-6 dot rounded-full shadow -left-1 -top-1 transition",
            {
              "bg-grayLight": !value,
              "bg-purple": value,
              checked: value,
            }
          )}
        ></div>
      </div>
    </label>
  );
}
