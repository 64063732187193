import cs from "classnames";

export default function HorizontalSelector({
  selectedValue,
  onValueChanged,
  options,
}) {
  return (
    <div className="flex  rounded flex-1 border border-purple   ">
      {options.map(({ value, label }) => (
        <button
          onClick={() => onValueChanged(value)}
          className={cs(
            "flex-1 py-0.5 border-r px-1 text-center border-purple",
            {
              "bg-purple bg-opacity-30 font-medium": value === selectedValue,
            }
          )}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
