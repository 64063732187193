export function getHighestListing(listings) {
  let max;
  listings?.forEach((listing, index) => {
    if (index === 0) {
      max = listing;
    }
    if (parseInt(listing?.pricePerItem) < parseInt(max?.pricePerItem)) {
      max = listing;
    }
  });
  return max;
}
