import cs from "classnames";

const sizes = {
  md: "w-4 h-4",
  sm: "w-3 h-3",
  lg: "w-5 h-5",
};

export default function Loading({ hidePadding, hideTopPadding, size = "md" }) {
  return (
    <div
      className={cs("flex align-center justify-center items-center gap-4", {
        "pr-80": !hidePadding,
        "pt-12": !hideTopPadding,
      })}
    >
      <div
        className={cs(
          "inline-block bg-purple rounded-full animate-bouncee first-circle",
          sizes[size]
        )}
      ></div>
      <div
        className={cs(
          "inline-block bg-purple rounded-full animate-bouncee second-circle",
          sizes[size]
        )}
      ></div>
      <div
        className={cs(
          "inline-block bg-purple rounded-full animate-bouncee third-circle",
          sizes[size]
        )}
      ></div>
    </div>
  );
}
