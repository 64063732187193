import { isProd } from "./env";

const testCollections = {
  "0xfa870dc94b617584d92646ae950eb8290d8ed8e8": {
    name: "Evmos Domains",
    index: 0,
    contract: "0xfa870dc94b617584d92646ae950eb8290d8ed8e8",
    description:
      "Evmos Domains lets you register your own human-readable .evmos domain names that link, or 'resolve', to addresses on the Evmos blockchain.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
  },
  "0x2633404b036db701ddb11d5850faa8a47549f779": {
    name: "Orbital Apes",
    index: 0,
    contract: "0x2633404b036db701ddb11d5850faa8a47549f779",
    description:
      "Orbital Apes is a collection of 10,000 NFTS coming to EVMOS. Each Orbital Ape created from a high quality 3D generation consisting of over 200 different attributes and 4 unique generations. Join our community to expand your network, earn rewards, and play exclusive games.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP", "Utility", ""],
  },
  "0x1ce489ebe654f686c91c5cecb570b881c8260640": {
    name: "Honorary Orbital Apes",
    index: 1,
    contract: "0x1ce489ebe654f686c91c5cecb570b881c8260640",
    description: "Honorary members of Orbital Apes",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP"],
  },
  "0xcaa087e6531fb9aaae423e439da522ccd8d57b97": {
    name: "Bored Puppets Yacht Club",
    contract: "0xcaa087e6531fb9aaae423e439da522ccd8d57b97",
    description:
      "The Bored Puppets Yacht Club is a collection of 9,999 unique Bored Puppets NFTs— unique digital collectibles living across all EVM blockchains",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Bored+Puppets+Yacht+Club/banner.jpg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Bored+Puppets+Yacht+Club/profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://evmos.boredpuppetsyachtclub.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/boredpuppetsnft",
      },
      {
        type: "discord",

        value: "https://discord.gg/MCrWwzmwvM",
      },
      {
        type: "telegram",
        value: "https://t.me/boredpuppetsnft",
      },
    ],
  },
  "0x5e19a7606f91283038bb4ea4b2afed2c98cdb0a2": {
    name: "EvmOS Punks",
    index: 2,
    contract: "0x5e19a7606f91283038bb4ea4b2afed2c98cdb0a2",
    description: "Punks on the EvmOS chain built w/ Cosmos SDK",
    bannerURL:
      "https://pbs.twimg.com/profile_banners/1479136987596697602/1641490079",
    imageURL:
      "https://pbs.twimg.com/profile_images/1479140981714915329/oQaIdTFh_400x400.jpg",
    socials: [
      {
        type: "web",
        value: "https://ospunks.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmOSPunks",
      },
      {
        type: "discord",

        value: "https://discord.gg/XjSN8k22ue",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
  "0x09ed7a7af929bd6c08706ec8781ad4182b8fd63c": {
    name: "Evmos Explorers",
    index: 2,
    contract: "0x09ed7a7af929bd6c08706ec8781ad4182b8fd63c",
    description:
      "Evmos Explorers... 10000 Explorers doing their thing on the EVM Cosmos or EVMOS blockchain! Launch Date: Evmos Launch",
    bannerURL:
      "https://pbs.twimg.com/profile_banners/1469335780107689991/1649879585/1500x500",
    imageURL:
      "https://pbs.twimg.com/media/FP0y99ZXwAMw-H1?format=jpg&name=4096x4096",
    socials: [
      {
        type: "web",
        value: "https://twitter.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evmosexplorers",
      },
      {
        type: "discord",

        value: "https://discord.gg/wtsutGCguG",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
  "0x000f0374476912d0119df44aa06dccdfe02b5933": {
    name: "Bored Ape Yacht Club",
    index: 0,
    contract: "0x000f0374476912d0119df44aa06dccdfe02b5933",
    description:
      "The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape NFTs— unique digital collectibles living on the Ethereum blockchain. Your Bored Ape doubles as your Yacht Club membership card, and grants access to members-only benefits, the first of which is access to THE BATHROOM, a collaborative graffiti board.",
    bannerURL:
      "https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=h600",
    imageURL:
      "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130",
    socials: [
      {
        type: "web",
        value: "https://twitter.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com",
      },
      {
        type: "discord",

        value: "https://twitter.com",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
  "0xb79f760165febe32012ddbc8a913cdc67fa56b10": {
    name: "Cool Cats Collabs",
    index: 1,
    contract: "0xb79f760165febe32012ddbc8a913cdc67fa56b10",
    description:
      "Cool Cats Collabs is a collections of collaborations between Cool Cats NFT and a variety of other artists, projects, and brands.",
    bannerURL:
      "https://lh3.googleusercontent.com/og8k9_QV9UztkcISUVlxLGeb7eQDB6Z2iDAhwT0IWqtHsliS-8sab7Tv6AlJhJRbu7PK1i1B0Vq8JC2A-nahIEnrIeAugC1WboxW=h600",
    imageURL:
      "https://lh3.googleusercontent.com/hrWReQTO0S4jyKNrJZ4pN5mXALCNJ-mm7Y45zNoiGX6H4ReqJWKVd7ep4ghmaqOWvxHSeD9ghk24hm7K2t4W8FY4H-cS1AIDWDNG87M=s0",
    socials: [
      {
        type: "web",
        value: "https://twitter.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com",
      },
      {
        type: "discord",

        value: "https://twitter.com",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
  "0xedb30f52965aa34020eaf6c531023fd5f95b0ede": {
    name: "Cool Cats",
    index: 1,
    contract: "0xedb30f52965aa34020eaf6c531023fd5f95b0ede",
    description:
      "Cool Cats is a collection of 9,999 randomly generated and stylistically curated NFTs that exist on the Ethereum Blockchain. Cool Cat holders can participate in exclusive events such as NFT claims, raffles, community giveaways, and more.",
    imageURL:
      "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s130",
    bannerURL:
      "https://lh3.googleusercontent.com/H4Iu36XQNJqVlF99-0BuQna0sUlUcIrHt97ss3le_tAWw8DveEBfTktX3S0bP6jpC9FhN1CKZjoYzZFXpWjr1xZfQIwSSLeDjdi0jw=h600",
    socials: [
      {
        type: "web",
        value: "https://twitter.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com",
      },
      {
        type: "discord",

        value: "https://twitter.com",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
};

const mainnetCollections = {
  "0xcce4071459c70465428e1f03d0f381b4f4ea04af": {
    name: "Honorary Orbital Apes",
    index: 1,
    contract: "0xcce4071459c70465428e1f03d0f381b4f4ea04af",
    description: "Honorary members of Orbital Apes",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP"],
  },
  "0xcaa087e6531fb9aaae423e439da522ccd8d57b97": {
    name: "Bored Puppets Yacht Club",
    contract: "0xcaa087e6531fb9aaae423e439da522ccd8d57b97",
    description:
      "The Bored Puppets Yacht Club is a collection of 9,999 unique Bored Puppets NFTs— unique digital collectibles living across all EVM blockchains",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Bored+Puppets+Yacht+Club/banner.jpg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Bored+Puppets+Yacht+Club/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://evmos.boredpuppetsyachtclub.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/boredpuppetsnft",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/MCrWwzmwvM",
      },
      {
        type: "telegram",
        value: "https://t.me/boredpuppetsnft",
      },
    ],
    category: ["PFP"],
  },
  "0xb19da44293147ad2dd0ea3ded47949d2971a3818": {
    name: "Evmos Mfers",
    contract: "0xb19da44293147ad2dd0ea3ded47949d2971a3818",
    description:
      "First mfers exploring evmos blockchain! Unaffiliated w@sartoshi_nft. No discord. No roadmap. No drama. MINT NOW at https://evmfers.com",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+mfers/Banner.jpg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+mfers/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://evmfers.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evmos_mfers",
      },
    ],
    category: ["Pixel Art", "PFP"],
  },
  "0x5e19a7606f91283038bb4ea4b2afed2c98cdb0a2": {
    name: "OSPunks",
    contract: "0x5e19a7606f91283038bb4ea4b2afed2c98cdb0a2",
    index: 6,
    description:
      "10000 Original Punks on the Evmos Chain. They have fallen from the cosmos and are ready to invade the EVM space.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/OSPunks/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/OSPunks/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://ospunks.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmOSPunks",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/XjSN8k22ue",
      },
    ],
    category: ["PFP", "Pixel Art"],
  },
  "0xc9a295cd47c2812d1492919e9140863ab4a1bcb5": {
    name: "Evmosians",
    contract: "0xc9a295cd47c2812d1492919e9140863ab4a1bcb5",
    description:
      "Evmosians NFTs collections will consist of many races and creatures. Each collection will have its own meaning and value.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmosians/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmosians/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.evmosians.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evmosians",
      },
      {
        type: "telegram",
        value: "https://t.me/evmosians",
      },
    ],
    category: ["PFP"],
  },
  "0x03f4ee9eac9c86ac001824166c6d269abac1f83d": {
    name: "Crypto Renaissance I",
    contract: "0x03f4ee9eac9c86ac001824166c6d269abac1f83d",
    description:
      "Welcome to Crypto Renaissance, a universe created by CREvmos and the Web3 community.Crypto Renaissance is the first generative art project from the mind of CREvmos. 2000 CRNFT  will be launched on the EVMOS network. Owning a genesis CRNFT unlocks access the exclusive holders club.Be part of the Crypto Renaissance Mint your on crevmos.com",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Crypto+Renaissance+I/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Crypto+Renaissance+I/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://crevmos.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/crevmos",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/ntfzV8surG",
      },
    ],
    category: ["PFP"],
  },
  "0xedf6e953f0ecfc10aee6d3a8508b5fbe14b94a2c": {
    name: "Covalent x Evmos",
    contract: "0xedf6e953f0ecfc10aee6d3a8508b5fbe14b94a2c",
    description:
      "Commemorating Evmos' integration with Covalent with these special edition NFTs!",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+integrates+with+Covalent!+/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+integrates+with+Covalent!+/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "www.covalenthq.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/Covalent_HQ",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/fgZPpq69Dd",
      },
      {
        type: "telegram",
        value: "https://t.me/CovalentHQ",
      },
    ],
    category: ["3D"],
  },
  "0xf2e8a8509ab69af07c7b3636a1db8d2b600e0572": {
    name: "evBirds",
    contract: "0xf2e8a8509ab69af07c7b3636a1db8d2b600e0572",
    index: 4,
    description:
      "EvBirds is a collection of 2500 NFTs on Evmos Network which has a very peculiar minting process. The mint price will increases exponentially by double every 625 NFT minted for a total of 4 batches. EvBirds will be the first NFT project with a 'Halving' method, where everyone is rewarded, thus laying the foundations for a strong and lasting community. Join the Squad!",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvBirds+NFTs/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvBirds+NFTs/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evbirds.io/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evBirdsNFT",
      },
    ],
    category: ["PFP", "Utility"],
  },
  "0x9c0023f5317a70de48716a488ffc59ee0c6c7d1b": {
    name: "Evbits",
    contract: "0x9c0023f5317a70de48716a488ffc59ee0c6c7d1b",
    description:
      "The #EVbits are 1,001 unique 3D #NFTs voxel characters, created by a custom generative algorithm on $Evmos chain ☄️ ",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evbits/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evbits/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evbits.io/mint",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EVbitsNFT",
      },
    ],
    category: ["3D", "PFP"],
  },
  "0x6552ed1bb59fa845857c2f20a537127bc8720bdc": {
    name: "Legendary Explorers",
    contract: "0x6552ed1bb59fa845857c2f20a537127bc8720bdc",
    description:
      "Custom made Explorers dedicated to cool people supporting Evmos!",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Legendary+Explorers/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Legendary+Explorers/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evmosexplorers.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evmosexplorers",
      },
      {
        type: "discord",
        value: "https://discord.gg/wtsutGCguG",
      },
    ],
    category: ["PFP"],
  },
  "0xc1d5224bd38e79042037ea75f908a22275669691": {
    name: "Official PageDAO Membership 2022",
    contract: "0xc1d5224bd38e79042037ea75f908a22275669691",
    description:
      "Official PageDAO Membership NFT grants access to mint NFTBooks via https://mint.nftbookbazaar.com/",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Official+PageDAO+Membership+2022/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Official+PageDAO+Membership+2022/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://pagedao.org",
      },
      {
        type: "twitter",
        value: "https://twitter.com/page_dao",
      },
      {
        type: "discord",
        value: "https://discord.gg/GB9pvT5qAt",
      },
    ],
    category: ["Utility"],
  },
  "0x137b4f8ec6f49a831c43cd306c12c08412d039f2": {
    name: "THE POWER OF THE RINGS",
    contract: "0x137b4f8ec6f49a831c43cd306c12c08412d039f2",
    description:
      "THE RINGS OF POWER WILL GIVE INFINITE ACCESS TO THE CRYPTOGRAPHIC WORLD TO EVERYONE WHO HAS A RING, THERE ARE ONLY 1,000 RINGS FOR 1,000 RULERS. 💍💍💍",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/THE+POWER+OF+THE+RINGS/Banner.jpg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/THE+POWER+OF+THE+RINGS/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://thepoweroftherings.xyz/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/rings_powers",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/jhpasUr5b6",
      },
      {
        type: "telegram",
        value: "https://t.me/the_power_of_the_rings",
      },
    ],
    category: ["PFP"],
  },
  "0xabbaa322a763b36587e3f63e46a81deacb2957a7": {
    name: "Evmos Domains",
    index: 2,
    contract: "0xabbaa322a763b36587e3f63e46a81deacb2957a7",
    description:
      "Evmos Domains lets you register your own human-readable .evmos domain names that link, or 'resolve', to addresses on the Evmos blockchain.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evmos.domains/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosDomains",
      },
      {
        type: "discord",
        value: "https://chat.evmos.domains/",
      },
      {
        type: "telegram",
        value: "https://t.me/the_power_of_the_rings",
      },
    ],
    category: ["Domains"],
  },
  "0x4c275ade386af17276834579b1a68146cef3c770": {
    name: "Orbital Apes",
    index: 0,
    contract: "0x4c275ade386af17276834579b1a68146cef3c770",
    description:
      "Orbital Apes is a collection of 10,000 NFTS coming to EVMOS. Each Orbital Ape created from a high quality 3D generation consisting of over 200 different attributes and 4 unique generations. Join our community to expand your network, earn rewards, and play exclusive games.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP", "Utility"],
  },
  "0x3e4b3f8dad1de9843f80789618988a9b6a3ff71a": {
    name: "Evmos Dolphin",
    contract: "0x3e4b3f8dad1de9843f80789618988a9b6a3ff71a",
    description:
      "10000 EvmosDolphin Memes Non-Fungiable Token Living on Evmos blockcain.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvmosDolphin/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvmosDolphin/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evdolphin.space",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosDolphin",
      },
    ],
    category: ["PFP"],
  },
  "0xe8c83e696868fe0dcb60d2ed1c1987a7ace78aa9": {
    name: "Pepe Gang Club",
    contract: "0xe8c83e696868fe0dcb60d2ed1c1987a7ace78aa9",
    description:
      "Original 6969 NFT Pepe Gang Club Living in #EVMOS Blockchain | Public Mint Here : https://mint.pepegang.club",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Pepe+Gang+Club/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Pepe+Gang+Club/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://mint.pepegang.club/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/PepeGang_NFT",
      },
    ],
    category: ["PFP"],
  },
  "0xefc8272abebb63b25382a5c15c5556baab459abe": {
    name: "The Unimpressed Cheetah",
    contract: "0xefc8272abebb63b25382a5c15c5556baab459abe",
    description:
      "5678 Unimpressed Cheetahs come across the city of Speedah, where sports and politics rule the masses. Train , develop, and dominate the playing field.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/The+Unimpressed+Cheetah/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/The+Unimpressed+Cheetah/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://unimpressedcheetah.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/UnimpressedChe1",
      },
      {
        type: "discord",
        value: "https://discord.gg/NPn9xdZS",
      },
    ],
    category: ["PFP", "Utility"],
  },
  "0x64ee262341a504958b8b92015af7a2f0102edec8": {
    name: "Space Medal NFT",
    contract: "0x64ee262341a504958b8b92015af7a2f0102edec8",
    description:
      "Space Medal NFT is a special honor for SpaceFi community contributors. Holders will have various rights on the SpaceFi platform.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Space+Medal+NFT/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Space+Medal+NFT/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.spacefi.io/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/spacefi_io",
      },
      {
        type: "discord",
        value: "http://discord.io/spacefi",
      },
    ],
    category: ["Utility"],
  },
  "0xa05e4ae95fb9b32fcd80ffd6c275db8b0842883b": {
    name: "Orbital Apes Limited",
    contract: "0xa05e4ae95fb9b32fcd80ffd6c275db8b0842883b",
    description:
      "Exclusive and time limited Orbital Ape Secondary Collection. A reward to our delegators through $OAV",
    index: 1,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes+Limited/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes+Limited/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP"],
  },
  "0x6d58cbb9104be5666e856983eab1cf8b0237c75e": {
    name: "Evmos Guardians",
    contract: "0x6d58cbb9104be5666e856983eab1cf8b0237c75e",
    description: "Collection of 7777 unique NFTs on EVMOS chain.",
    index: 2,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Guardians/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Guardians/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evmosguardians.io",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosGuardians",
      },
      {
        type: "discord",

        value: "https://discord.gg/qZgqHgNcxK",
      },
    ],
    category: ["PFP", "Utility"],
  },
  "0x0ec23669dad631a159f66c865beb60266b16d157": {
    name: "Honorary Guardians",
    contract: "0x0ec23669dad631a159f66c865beb60266b16d157",
    description: "Honorary Guardians designed for special events.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Guardians/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Guardians/Profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evmosguardians.io",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosGuardians",
      },
      {
        type: "discord",

        value: "https://discord.gg/qZgqHgNcxK",
      },
    ],
    category: ["PFP", "Utility"],
  },
  "0x31782794fd38803e16bc4d7c504cf31e8e6dd282": {
    name: "BoredOSApes",
    contract: "0x31782794fd38803e16bc4d7c504cf31e8e6dd282",
    index: 3,
    description:
      "9,999 unique and algorithmically generated Bored Apes migrating on to EVMOS blockchain",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/BoresOsApes/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/BoresOsApes/Profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://boredosapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/boredosapes",
      },
      {
        type: "discord",
        value: "discord.gg/wngAPgknhq",
      },
    ],
    category: ["Utility", "PFP", "Pixel Art"],
  },
  "0x2bcb6be17f975423af354d5f878c2d7c94e17c42": {
    name: "Evmos Cadets",
    contract: "0x2bcb6be17f975423af354d5f878c2d7c94e17c42",
    description: "3000 EVMOS Cadets taking off into the Cosmos!",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Cadets/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Cadets/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.evmoscadets.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/evmoscadets",
      },
      {
        type: "discord",

        value: "https://discord.gg/9M2wWNhtm7",
      },
      {
        type: "telegram",
        value: "https://t.me/EVMOSCadets",
      },
    ],
    category: ["PFP"],
  },
  "0xd72ef54da083c7610bc517a9091bd85cbd98694d": {
    name: "Evmos Rocks",
    contract: "0xd72ef54da083c7610bc517a9091bd85cbd98694d",
    description:
      "100 right facing rocks with innovative upside down technology",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Rocks/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Rocks/Profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evmos.rocks",
      },
      {
        type: "twitter",
        value: "https://twitter.com/NftImmutable",
      },
      {
        type: "discord",
        value: "https://discord.gg/ag3FTDD92z",
      },
      {
        type: "telegram",
        value: "https://t.me/immutablerocks",
      },
    ],
    category: ["PFP"],
  },
  "0xa5c9cb9a6d1a6added8060b58a3f8386938104da": {
    name: "Evmos Doodles",
    contract: "0xa5c9cb9a6d1a6added8060b58a3f8386938104da",
    description:
      "10000 Original Doodles Living in $EVMOS Blockchain Minting on : https://evdoodles.space",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Doodles/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Doodles/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://evdoodles.space/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvDoodlesNFT",
      },
    ],
    category: ["PFP"],
  },
  "0x348118c75b91a8a95d47d3a0da7c2d619a8deed4": {
    name: "OS Toadz",
    contract: "0x348118c75b91a8a95d47d3a0da7c2d619a8deed4",
    description:
      "OS Toadz is the first collection of 10,000 Stylish Toadz made up of more than 100+ different traits on EVMOS Blockchain",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/OS+Toadz/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/OS+Toadz/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://ostoadz.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OSToadz",
      },
    ],
    category: ["Pixel Art", "PFP"],
  },
  "0xfffb7b3f37f640b263c36e53b41507dfd0b51b4a": {
    name: "EvSquitos",
    contract: "0xfffb7b3f37f640b263c36e53b41507dfd0b51b4a",
    description:
      "2500 randomly de-generated NFTs in the EVMOS network. EvSquitos ready to disrupt your sweet dreams. Join the Resistance!",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvSquitos/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/EvSquitos/Profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evsquitos.io",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvSquitosNFT",
      },
      {
        type: "discord",
        value: "https://discord.gg/rZUmfqfunp",
      },
    ],
    category: ["PFP"],
  },
  "0xe96cecc1c15ca7d80fb76ba74727a1c39e28b1db": {
    name: "Evmos Invaders",
    contract: "0xe96cecc1c15ca7d80fb76ba74727a1c39e28b1db",
    description: "Collection of 777 unique NFTs on EVMOS chain.",
    index: 5,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Invaders/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Invaders/Profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evmosguardians.io",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosGuardians",
      },
      {
        type: "discord",
        value: "https://discord.gg/qZgqHgNcxK",
      },
    ],
    category: ["PFP"],
  },
  "0xb8582757ef0fe0e68e7e7d0c1c1ea96e66907b63": {
    name: "Evmos Alpha Pass",
    contract: "0xb8582757ef0fe0e68e7e7d0c1c1ea96e66907b63",
    description: "Collection of 300 exclusive Alpha Pass for Evmos Nfts.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Alpha+Pass/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Alpha+Pass/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.evmosalpha.com/",
      },
      {
        type: "twitter",
        value:
          "https://twitter.com/EvmosAlphaPass?s=20&t=u-3pNujGiNocSMr5GlfWaw",
      },
      {
        type: "discord",
        value: "https://discord.gg/WxWMYBFCV6",
      },
    ],
    category: ["Utility"],
  },
  "0x6ad439c980d6eee165a256d1026cc98d9739d6bf": {
    name: "Evmos Aliens ",
    contract: "0x6ad439c980d6eee165a256d1026cc98d9739d6bf",
    description:
      "Evmos Aliens is a generative collection of 2,999 unique aliens in Evmos Blockchain and Cosmos Ecosystem. This is a community project",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Aliens/Banner.jpeg",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Aliens/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.evmosaliens.net/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosAliens",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/NPENJGTqAe",
      },
    ],
    category: ["PFP"],
  },
  "0x32c8e6e1ad9d356f1b010cca8de00c11510e8470": {
    name: "SpaceFi Planet",
    contract: "0x32c8e6e1ad9d356f1b010cca8de00c11510e8470",
    description:
      "SpaceFi is a cross-chain web3 platform on Evmos and zkSync, with DEX+NFT+Starter+Spacebase. Planet NFT is a pass of ecosystem, with STAR and rights.",
    index: 2,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/SpaceFi+Planet/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/SpaceFi+Planet/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.spacefi.io/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/spacefi_io",
      },
      {
        type: "discord",
        value: "https://discord.io/spacefi",
      },
    ],
    category: ["Utility"],
  },
  "0x8a00a3155b1d97486f5471c372623cf52577be05": {
    name: "Evmos Momentum Hackathon NFT",
    contract: "0x8a00a3155b1d97486f5471c372623cf52577be05",
    description:
      "Honorary Participants of Evmos Momentum Hackathon 2022",
    index: 2,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Momentum+Hackathon+NFT/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Momentum+Hackathon+NFT/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.huobi.co.ma/huobiincubator/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/huobi_incubator",
      },
      {
        type: "discord",
        value: "https://discord.gg/evmos",
      },
    ],
    category: ["PFP"],
  },
  "0x1e317bee46c7ca928bd8678c9c2d7a7fb91b7cf4": {
    name: "TYCHE'S ODYSSEY - EXPLORING THE COSMOS",
    contract: "0x1e317bee46c7ca928bd8678c9c2d7a7fb91b7cf4",
    description:
      "Goddess of fortune and prosperity, adorned with a crown and jewelry, holding a scepter and money bag, surrounded by luxurious backgrounds.",
    index: 2,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Tyche's+Odyssey/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Tyche's+Odyssey/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.tyche.zone/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/tychezone",
      },
      {
        type: "discord",
        value: "https://discord.com/invite/4hknJYn2TY",
      },
    ],
    category: ["PFP"],
  },
  "0x2e779749c40cc4ba1cab4c57ef84d90755cc017d": {
    name: "Quadrata Passport",
    contract: "0x2e779749c40cc4ba1cab4c57ef84d90755cc017d",
    description:
      "The Identity Solution for Web3. A soulbound token that grants you access to an entire ecosystem of Web3 applications, in a privacy preserving way.",
    index: 2,
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Quadriga/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Quadriga/Profile.png",
    socials: [
      {
        type: "web",
        value: "https://quadrata.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/QuadrataNetwork",
      }
    ],
    category: ["Utility"],
  },
};

export const collectionMeta = isProd ? mainnetCollections : testCollections;
