import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import { useState } from "react";
import cs from "classnames";

function AttributeChip({ data, collectionData }) {
  function formatValue() {
    if (data?.value?.length > 14) {
      return `${data?.value?.substring(0, 13)}...`;
    }
    return data?.value;
  }

  return (
    <div className="border w-36 items-center py-1.5 rounded-md flex flex-col border-purple bg-purple bg-opacity-10">
      <span className="font-semibold text-sm text-purple uppercase">
        {data?.trait_type}
      </span>
      <span className="text-white text-lg">{formatValue(data?.value)}</span>
      {data.count && (
        <span className="text-gray text-sm">
          {parseFloat(
            (data.count / collectionData?.collectionSummary?.nftCount) * 100
          ).toFixed(2)}
          % have this trait
        </span>
      )}
    </div>
  );
}

export default function Attributes({ data, collectionData }) {
  const [open, setOpen] = useState(true);

  const attributes =
    data?.traits?.length > 0
      ? data?.traits?.map((t) => {
          return { ...t, trait_type: t.type };
        })
      : data?.attributes?.length > 0
      ? data?.attributes
      : [];
  return (
    <div className="flex w-full border-b border-darkGray flex-col">
      <div
        onClick={() => setOpen(!open)}
        className={cs(
          "flex w-full justify-between  cursor-pointer bg-transparent  py-3  px-4"
        )}
      >
        <span className="font-semibold text-white text-lg">Attributes</span>
        <img alt="arrow indicator" src={open ? UpArrow : DownArrow} />
      </div>
      {open && (
        <div className="flex gap-2.5 border-t border-darkGray p-3 flex-wrap">
          {attributes?.map((attribute, index) => (
            <AttributeChip
              collectionData={collectionData}
              data={attribute}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}
