import { ethers } from "ethers";
import cs from "classnames";
import moment from "moment";
import { useState } from "react";
import { collectionMeta } from "../../../helpers/collectionInfo";
import { useTokenData, getTokenName } from "../../../helpers/tokens";
import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import AcceptOfferModal from "../../../components/AcceptOfferModal";
import { nFormatter } from "helpers/formatNumber";
import Address from "components/Address";
import { Link } from "react-router-dom";

function OfferInnerRow({ data, meta, collectionMeta }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="flex rounded-t-2xl pl-10 px-4 py-3 border-b border-dark items-center">
      <div className="flex flex-1">
        <span className="w-3/12 text-white">
          {nFormatter(ethers.utils.formatEther(data?.pricePerItem), 2)} EVMOS
        </span>
        <Link to={`/profile/${data?.creator}`} className="w-3/12 text-purple">
          <Address address={data?.creator} length={10} />
        </Link>
        <span className="w-4/12 text-white">
          {`${moment(new Date(data?.deadline * 1000).toISOString()).format(
            "MMMM Do YYYY, h:mm a"
          )}`}
        </span>
      </div>
      <div className="flex self-end w-32">
        <button
          onClick={() => {
            setShowModal(true);
          }}
          className=" h-8 font-bold text-lightbg rounded-md w-full bg-purple"
        >
          {"Accept offer"}
        </button>
      </div>
      {showModal && (
        <AcceptOfferModal
          collectionMeta={collectionMeta}
          data={{ ...data, ...meta }}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default function OfferRow({ data, collectionInfo }) {
  const [open, setOpen] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  const meta = useTokenData(data?.tokenId, data?.nftAddress, data?.isERC1155);

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className="flex p-4 bg-dark justify-between rounded-t-xl border-b border-darkGray items-center"
      >
        <div className="flex gap-3">
          <Link
            to={`/nft/${data?.nftAddress}/${data?.tokenId}`}
            className="flex items-center gap-2"
          >
            {!imageLoaded && (
              <div
                alt="item"
                className="w-16 rounded-full h-16 bg-darkGray animate-pulse"
              />
            )}
            <img
              onLoad={() => setImageLoaded(true)}
              className={cs("w-16 rounded-full h-16", {
                hidden: !imageLoaded,
                flex: imageLoaded,
              })}
              src={meta?.image}
              alt="item"
            />
          </Link>
          <div className="flex justify-between flex-col">
            <div className="flex items-center gap-4">
              <span className="text-purple text-xl font-bold">
                {collectionMeta[data?.nftAddress].name}
              </span>
              <span className="text-gray">{data?.groups?.length} offers</span>
            </div>
            <span className="text-white self-start text-lg mb-2 font-medium">
              {getTokenName({ ...meta, ...data })}
            </span>
          </div>
        </div>
        <img
          className="w-8"
          alt="arrow indicator"
          src={open ? UpArrow : DownArrow}
        />
      </button>
      {open && (
        <div className="flex bg-darkGray bg-opacity-40 flex-col">
          <div className="flex pl-10 font-semibold rounded-t-lg text-lg text-white py-2 border-b px-4  border-dark">
            <div className="flex flex-1">
              <span className="w-3/12">Price</span>
              <span className="w-3/12">From</span>
              <span className="w-4/12">Expiration</span>
            </div>
            <div className="flex w-32" />
          </div>
          <div className="flex bg-darkGray flex-col">
            {data?.groups?.map((offer, index) => (
              <OfferInnerRow
                collectionMeta={{
                  ...collectionMeta[data?.nftAddress],
                  ...collectionInfo,
                }}
                meta={meta}
                key={index}
                data={{ ...offer, tokenId: data?.tokenId }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
