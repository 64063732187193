import { ethers } from "ethers";
import Close from "assets/icons/close.svg";
import { collectionMeta } from "helpers/collectionInfo";
import { payTokenMeta } from "helpers/payTokens";

function getPaytokenName(allFilters) {
  return payTokenMeta[allFilters.payToken].name;
}

function getFilterChipComponents(filter, value, allFilters) {
  if (filter === "pricePerItem_gt") {
    return (
      <div className="flex gap-1">
        <span>Price</span>
        <span>{">"}</span>
        <span>{ethers.utils.formatEther(value)}</span>
        <span>{getPaytokenName(allFilters)}</span>
      </div>
    );
  }
  if (filter === "pricePerItem_lt") {
    return (
      <div className="flex gap-1">
        <span>Price</span>
        <span>{"<"}</span>
        <span>{ethers.utils.formatEther(value)}</span>
        <span>{getPaytokenName(allFilters)}</span>
      </div>
    );
  }
  if (filter === "payToken_in") {
    return (
      <div className="flex gap-1">
        <span>Price in</span>
        <span>{payTokenMeta[value[0]].name}</span>
        <span className="flex items-center">
          <img className="h-4" alt="eth" src={payTokenMeta[value[0]].image} />
        </span>
      </div>
    );
  }
  if (filter === "traits_contains") {
    const splittedId = value.split(":");
    const type = splittedId[1];
    const typeValue = splittedId[2];

    return (
      <div className="flex gap-1">
        <span>{type}:</span>
        <span>{typeValue}</span>
      </div>
    );
  }
  if (filter === "listed") {
    return (
      <div className="flex gap-1">
        <span>Listed</span>
      </div>
    );
  }
  if (filter === "nftAddress") {
    return (
      <>
        <span>{collectionMeta[value].name}</span>
      </>
    );
  }
  if (filter === "auction_") {
    if (value?.startTime_lt) {
      return (
        <div className="flex gap-1">
          <span>Auction: In progress</span>
        </div>
      );
    } else {
    }
    return (
      <div className="flex gap-1">
        <span>Auction: Upcoming</span>
      </div>
    );
  }
}

function FilterChipCloseButton({ filter, onClick }) {
  if (filter === "payToken") {
    return null;
  }
  return (
    <button onClick={onClick} className="text-xl font-semibold pt-0.5">
      <img alt="close" className="w-3.5" src={Close} />
    </button>
  );
}

function FilterChip({ allFilters, value, filter, onRemove }) {
  return (
    <div className="bg-purple h-9 items-center gap-2 bg-opacity-10 border-2 rounded px-3 border-purple text-white flex">
      {getFilterChipComponents(filter, value, allFilters)}
      <FilterChipCloseButton
        onClick={() => onRemove({ filter, value })}
        filter={filter}
      />
    </div>
  );
}

export function renderActiveFilterChips({ filters, onRemove }) {
  const chips = [];
  const hasPriceFilter =
    filters.pricePerItem_gt != null || filters.pricePerItem_lt != null;
  for (const f in filters) {
    if (f === "payToken" && hasPriceFilter) {
      continue; // FilterChip will add the payToken display in the price filter
    }
    if (f === "traits_contains") {
      for (let filterId of filters[f]) {
        chips.push(
          <FilterChip
            allFilters={filters}
            onRemove={onRemove}
            key={filterId}
            filter={f}
            value={filterId}
          />
        );
      }
    } else {
      chips.push(
        <FilterChip
          allFilters={filters}
          onRemove={onRemove}
          key={f}
          filter={f}
          value={filters[f]}
        />
      );
    }
  }
  return chips;
}

export default function ActiveFilters({ filters, onRemove, chips = null }) {
  chips = chips || renderActiveFilterChips({ filters, onRemove });

  return <div className="flex flex-wrap flex-1 gap-2.5">{chips}</div>;
}
