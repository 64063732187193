import Category from "../../assets/icons/Category.svg";
import Image from "../../assets/icons/Image.svg";
import Wallet from "../../assets/icons/Wallet.svg";
import Orb from "../../assets/icons/Orb.svg";

export default function Perks() {
  return (
    <div className="bg-gradient-to-b py-28  bg-dark items-center flex justify-center flex-wrap  w-full">
      <div className="max-w-7xl items-center flex justify-center flex-wrap  w-full">
        <div
          data-aos="flip-up"
          className="flex pt-5 max-w-xs px-5 gap-3 items-center flex-col"
        >
          <div className="bg-darkGray p-3 flex justify-center items-center rounded-xl w-14 h-14">
            <img alt="wallet" className="w-full" src={Wallet} />
          </div>
          <span className="font-semibold text-2xl text-white">
            Set Up Your Wallet
          </span>
          <span className="text-sm text-center text-white">
            Once you've set up your wallet of choice, connect it to Orbit by
            clicking on “My Wallet” the top right corner.
          </span>
        </div>

        <div
          data-aos="flip-up"
          className="flex pt-5 max-w-xs px-5 gap-3 items-center flex-col"
        >
          <div className="bg-darkGray p-3 flex justify-center items-center rounded-xl w-14 h-14">
            <img alt="wallet" className="w-full" src={Category} />
          </div>
          <span className="font-semibold text-2xl text-white">
            Access Your Profile
          </span>
          <span className="text-sm text-center text-white">
            In your profile you can view your NFTs, list them in the
            marketplace, and see your activity, listings, and offers.
          </span>
        </div>

        <div
          data-aos="flip-up"
          className="flex pt-5 max-w-xs px-5 gap-3 items-center flex-col"
        >
          <div className="bg-darkGray p-3 flex justify-center items-center rounded-xl w-14 h-14">
            <img alt="wallet" className="w-full" src={Image} />
          </div>
          <span className="font-semibold text-2xl text-white">Trade NFTs</span>
          <span className="text-sm text-center text-white">
            On Orbit you'll find the highest quality collections in Evmos!{" "}
          </span>
        </div>

        <div
          data-aos="flip-up"
          className="flex pt-5 max-w-xs px-5 gap-3 items-center flex-col"
        >
          <div className="bg-darkGray p-3 flex justify-center items-center rounded-xl w-14 h-14">
            <img alt="wallet" className="w-full" src={Orb} />
          </div>
          <span className="font-semibold text-2xl text-white">Earn $EVMOS</span>
          <span className="text-sm text-center text-white">
            Orbital Ape owners earn part of the platform fees across all
            collections! Check your dashboard to view and claim earned rewards.
          </span>
        </div>
      </div>
    </div>
  );
}
