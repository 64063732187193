import OldEasyInfiniteScroll from "components/basics/OldEasyInfiniteScroll";
import InfiniteScroll from "components/infinite_scroll/InfiniteScroll";
import { LISTED_ITEMS_PER_PAGE } from "graphql/api";

const useNewScroll = true;

let renderScrollComponent;
if (!useNewScroll) {
  renderScrollComponent = OldEasyInfiniteScroll;
} else {
  renderScrollComponent = function ({
    fetchPage,
    renderData,
    renderEmptyState,
    pageStart,
    hasNextPage: propsHasNextPage,
    onDataChanged,
    loader,
    threshold,
    sensitivityListForPaginationReset,
  }) {
    return (
      <InfiniteScroll
        renderData={renderData}
        renderEmptyState={renderEmptyState}
        renderLoader={() => loader}
        threshold={threshold}
        queryData={sensitivityListForPaginationReset}
        fetchPage={async ({ pageNum, queryData }) => {
          const pageData = await fetchPage(pageNum + pageStart);
          const hasNextPage = propsHasNextPage
            ? propsHasNextPage(pageData)
            : pageData.length === LISTED_ITEMS_PER_PAGE;
          return { pageData, hasNextPage };
        }}
        onDataChanged={onDataChanged}
      />
    );
  };
}

export default renderScrollComponent;
