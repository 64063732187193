import Send from "../../assets/icons/send.svg";
import { isSameAddress } from "../../helpers/address";
import User from "../../assets/icons/user.svg";
import Group from "../../assets/icons/Group.svg";
import All from "../../assets/icons/all.svg";
import { getTokenName } from "helpers/tokens";
import Address from "components/Address";
import { ZERO_ADDRESS } from "helpers/payTokens";
import { Link } from "react-router-dom";

export default function Header({
  collection,
  collectionData,
  nftId,
  data,
  address,
  setShowSendModal,
  erc1155Balance,
  meta,
  owner,
  isBundle,
}) {
  const _collection = isBundle ? data?.bundle?.nftAddress : collection;
  function renderERC721Header() {
    return (
      <div className="flex flex-1 justify-between">
        <div className="flex gap-5 flex-col">
          <div className="flex gap-0 flex-col">
            <Link
              to={`/collection/${_collection}`}
              className="font-medium text-lg text-purple"
            >
              {collectionData?.name}
            </Link>
            <span className="text-4xl text-white font-bold">
              {isBundle ? "Bundle: " : ""}{" "}
              {getTokenName({ ...data, ...meta }) || ""}
            </span>
          </div>
          {isBundle && (
            <div className="text-white flex gap-2 font-semibold">
              {" "}
              Tokens:{" "}
              <div className="flex font-normal flex-wrap">
                {data?.bundle?.tokenIds?.map((id, index) => (
                  <Link
                    to={`/nft/${data?.bundle?.nftAddress}/${id}`}
                    className="text-purple"
                    key={index + id}
                  >
                    {index === 0 ? `#${id}` : `, #${id}`}
                  </Link>
                ))}
              </div>
            </div>
          )}

          <div className="flex gap-1">
            {isSameAddress(owner, ZERO_ADDRESS) && (
              <>
                <span className="text-white font-medium underline">BURNED</span>
              </>
            )}

            {!isSameAddress(owner, ZERO_ADDRESS) && (
              <>
                <span className="text-white">Owned by</span>
                <Link to={`/profile/${owner}`} className="text-purple">
                  <Address address={owner} length={10} />
                </Link>
              </>
            )}
          </div>
        </div>
        {isSameAddress(owner, address) && (
          <button
            onClick={() => setShowSendModal(true)}
            className="border inline-flex w-10 h-10 p-2.5 hover:bg-darkGray border-darkGray rounded-lg"
          >
            <img alt="send" src={Send} />
          </button>
        )}
      </div>
    );
  }

  function renderERC1155Header() {
    return (
      <div className="flex flex-1 flex-col gap-5 justify-between">
        <div className="flex justify-between">
          <div className="flex gap-5 flex-col">
            <div className="flex gap-0 flex-col">
              <Link
                to={`/collection/${collection}`}
                className="font-medium text-lg text-purple"
              >
                {collectionData?.name}
              </Link>

              <span className="text-4xl text-white font-bold">
                {getTokenName({ ...meta, tokenId: nftId })}
              </span>
            </div>
          </div>
          {erc1155Balance && (
            <button
              onClick={() => setShowSendModal(true)}
              className="border inline-flex w-10 h-10 p-2.5 hover:bg-darkGray border-darkGray rounded-lg"
            >
              <img alt="send" src={Send} />
            </button>
          )}
        </div>
        <div className="flex gap-6">
          <div className="flex items-end gap-2">
            <img alt="group" src={Group} className="h-4 mb-1" />
            <span className="text-white">
              {data?.erc1155Info?.owners} owners
            </span>
          </div>
          <div className="flex items-end gap-2">
            <img alt="all" src={All} className="h-6" />
            <span className="text-white">
              {data?.erc1155Info?.supply} total
            </span>
          </div>

          {erc1155Balance && (
            <div className="flex items-end gap-1.5">
              <img alt="user" src={User} className="h-6" />
              <span className="text-white">
                You own {erc1155Balance?.amount}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return <>{data?.isERC1155 ? renderERC1155Header() : renderERC721Header()}</>;
}
