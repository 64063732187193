import { useState } from "react";
import Orb from "../../assets/icons/Orb.svg";
import WOrb from "../../assets/icons/WOrb.svg";

import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import ListingCoinDropdown, {
  options as paytokenDropdownOptions,
} from "../CoinDropdowns/ListingCoinDropdown";
import cs from "classnames";
import { nFormatter } from "helpers/formatNumber";
import { isSameAddress } from "helpers/address";
import { formatEther, parseEther, ZERO_ADDRESS } from "helpers/payTokens";
import { useDispatch } from "react-redux";
import { WaitForTransaction } from "graphql/api";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { getTokenName } from "helpers/tokens";

export default function UpdateListingItem({
  data,
  collectionData,
  closeModal,
  viewerListing,
}) {
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState();
  const [payToken, setPayToken] = useState(
    paytokenDropdownOptions.find((option) =>
      isSameAddress(option.value, data?.payToken)
    )
  );
  const [success, setSuccess] = useState(false);

  const { orbitContract } = useEVM();

  async function handleUpdate() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.updateListing(
        data?.nftAddress,
        data?.tokenId,
        payToken.value,
        parseEther(price, payToken.value)
      );
      const toastId = loadingToast("Updating listing...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          setSuccess(true);
          dismissToast(toastId);
          successToast("Listing updated!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex border-gray gap-5 flex-col border-b border-t py-12 justify-between px-10 ">
        <div className="flex justify-between">
          <div className="flex items-start gap-3">
            {!data?.bundle && (
              <div className="w-16 self-center flex items-center flex-col justify-center">
                <img
                  className="rounded-xl"
                  alt="marketCard"
                  src={data?.image}
                />
              </div>
            )}

            <div className="flex flex-col">
              <span className="font-semibold text-sm text-purple">
                {collectionData?.name}
              </span>
              <span className="text-lg font-bold">{getTokenName(data)}</span>
            </div>
          </div>
          <div className="flex mr-1 flex-col">
            <span className="text-white">Current price</span>
            <div className="flex text-xl gap-1.5 font-bold items-center">
              <img
                className="h-3 mt-0.5"
                alt="eth"
                src={data?.payToken === ZERO_ADDRESS ? Orb : WOrb}
              />
              {nFormatter(
                formatEther(
                  viewerListing?.pricePerItem,
                  viewerListing?.payToken
                ),
                2
              )}
            </div>
          </div>
        </div>
        <div className="flex z-30 gap-1 flex-col">
          <span className="font-bold w-24">Amount</span>

          <div className="flex items-center border bg-darkGray border-darkGray rounded">
            <div className="flex bg-darkGray items-center rounded-l gap-1  w-26 ">
              <ListingCoinDropdown onChange={setPayToken} value={payToken} />
            </div>
            <input
              onChange={(e) => setPrice(e.target.value)}
              min="0"
              type="number"
              className="flex-1 outline-none rounded-r text-white bg-darkGray border-gray border-l py-1 pl-2"
              placeholder="Amount"
            />
          </div>
        </div>

        {data?.isERC1155 && (
          <div className="flex gap-1 flex-col">
            <span className="font-bold">Quantity</span>
            <span className="text-sm font-light">
              Current listing quantity: {viewerListing?.quantity}
            </span>
          </div>
        )}
      </div>
      <div className=" py-6 pb-6 self-center">
        <button
          disabled={loading}
          onClick={() => (success ? closeModal() : handleUpdate())}
          className={cs("font-bold h-8 text-white rounded-md w-40 bg-purple", {
            "opacity-40": loading,
          })}
        >
          {success
            ? "Listing updated!"
            : loading
            ? "Loading..."
            : "Edit listing"}
        </button>
      </div>
    </>
  );
}
