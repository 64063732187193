import { useEffect, useState } from "react";
import { useEVM } from "../hooks/EVMhook";
import Modal from "react-modal";
import Button from "./Button";
import Transfer from "../assets/icons/Transfer.svg";
import { ethers } from "ethers";
import { DEFAULT_ERC20 } from "helpers/payTokens";
import { nFormatter } from "helpers/formatNumber";
import { loadingToast, dismissToast, successToast } from "../components/Toasts";
import { handleError } from "../helpers/errors";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function WrapEVMOS({ closeModal }) {
  const [loading, setLoading] = useState();
  const [action, setAction] = useState("wrap");
  const [wbalance, setWbalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amountToSwap, setAmountToSwap] = useState(0);

  const { wrapperContract, address } = useEVM();

  const inputIsInvalid =
    !amountToSwap || amountToSwap === undefined || amountToSwap === "";

  useEffect(() => {
    const getBalances = async () => {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provider.getBalance(address).then((evmosBal) => {
        setBalance(parseFloat(evmosBal.toString()) / 10 ** 18);
      });
      wrapperContract?.instance
        ?.attach(DEFAULT_ERC20)
        .balanceOf(address)
        .then((wevmosBal) => {
          setWbalance(parseFloat(wevmosBal.toString()) / 10 ** 18);
        });
    };
    if (address && wrapperContract?.instance) {
      getBalances();
    }
  }, [address, wrapperContract?.instance]);

  async function handleAction() {
    if (!amountToSwap || amountToSwap === undefined || amountToSwap === "")
      return;
    let transaction;
    let loadingToastMessage;
    let completedToastMessage;
    try {
      if (action === "wrap") {
        transaction = await wrapperContract?.instance
          ?.attach(DEFAULT_ERC20)
          ?.deposit({
            value: ethers?.utils?.parseEther(amountToSwap.toString()),
          });
        loadingToastMessage = "Wrapping evmos...";
        completedToastMessage = "Evmos wrap completed!";
      } else if (action === "unwrap") {
        transaction = await wrapperContract?.instance
          ?.attach(DEFAULT_ERC20)
          ?.withdraw(ethers?.utils?.parseEther(amountToSwap.toString()));
        loadingToastMessage = "Unwrapping wevmos...";
        completedToastMessage = "Wevmos unwrap completed!";
      }
      const toastId = loadingToast(loadingToastMessage);
      wrapperContract?.instance
        ?.attach(DEFAULT_ERC20)
        ?.provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast(completedToastMessage);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  function setSwapAmount(value) {
    if (action === "wrap") {
      if (value <= balance) {
        setAmountToSwap(value);
      }
    } else if (action === "unwrap") {
      if (value <= wbalance) {
        setAmountToSwap(value);
      }
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-120 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              EVMOS / WEVMOS Station
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>

          <>
            <div className="flex border-gray border-t gap-5 py-4 flex-col px-10 ">
              <span className="text-center self-center text-gray"></span>
              <div className="flex flex-col gap-2">
                <span className="font-semibold text-white text-xl">
                  {action === "wrap" ? "EVMOS" : "WEVMOS"}
                </span>
                <div className="border px-3 py-1.5 border-darkGray rounded-lg gap-3 flex items-center">
                  <span className=" flex">
                    Balance:{" "}
                    {action === "wrap"
                      ? nFormatter(balance, 2)
                      : nFormatter(wbalance, 2)}
                  </span>
                  <button
                    onClick={() =>
                      action === "wrap"
                        ? setAmountToSwap(balance)
                        : setAmountToSwap(wbalance)
                    }
                    className="border hover:bg-darkGray border-darkGray rounded-md px-2.5"
                  >
                    Max
                  </button>
                  <input
                    onClick={(e) => setSwapAmount("")}
                    onChange={(e) => setSwapAmount(e.target.value)}
                    className="outline-none flex-1 text-right"
                    value={amountToSwap}
                  />
                </div>
              </div>
              <button
                className="self-center"
                onClick={() => {
                  action === "wrap" ? setAction("unwrap") : setAction("wrap");
                }}
              >
                <img
                  alt="flip"
                  className="h-8 w-8 border-purple p-1.5 border rounded-full transform origin-center rotate-90"
                  src={Transfer}
                />
              </button>
              <div className="flex flex-col gap-2">
                <span className="font-semibold text-white text-xl">
                  {action === "unwrap" ? "EVMOS" : "WEVMOS"}
                </span>
                <div className="border px-3 py-1.5 border-darkGray rounded-lg gap-3 flex items-center">
                  <span className=" flex">
                    Balance:{" "}
                    {action === "unwrap"
                      ? nFormatter(balance, 2)
                      : nFormatter(wbalance, 2)}
                  </span>
                  <button
                    onClick={() =>
                      action === "unwrap"
                        ? setAmountToSwap(balance)
                        : setAmountToSwap(wbalance)
                    }
                    className="border hover:bg-darkGray border-darkGray rounded-md px-2.5"
                  >
                    Max
                  </button>
                  <input
                    onClick={(e) => setSwapAmount("")}
                    onChange={(e) => setSwapAmount(e.target.value)}
                    className="outline-none flex-1 text-right"
                    value={amountToSwap}
                  />
                </div>
              </div>
            </div>

            <div className="w-48 text-white py-6 self-center">
              <Button
                onClick={() => handleAction()}
                disabled={loading || inputIsInvalid}
                text={
                  loading ? "loading..." : action === "wrap" ? "Wrap" : "Unwrap"
                }
              />
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
}

export default WrapEVMOS;
