import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ScrollListener extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    element: PropTypes.node,
    ref: PropTypes.func,
  };

  static defaultProps = {
    element: "div",
    ref: null,
  };

  constructor(props) {
    super(props);

    this.scrollListener = this.scrollListener.bind(this);
    this.eventListenerOptions = this.eventListenerOptions.bind(this);
    this.mousewheelListener = this.mousewheelListener.bind(this);
  }

  componentDidMount() {
    this.options = this.eventListenerOptions();
    this.attachScrollListener();
  }

  componentWillUnmount() {
    this.detachScrollListener();
    this.detachMousewheelListener();
  }

  isPassiveSupported() {
    let passive = false;

    const testOptions = {
      get passive() {
        passive = true;
        return passive;
      },
    };

    try {
      document.addEventListener("test", null, testOptions);
      document.removeEventListener("test", null, testOptions);
    } catch (e) {
      // ignore
    }
    return passive;
  }

  eventListenerOptions() {
    if (this.isPassiveSupported()) {
      return {
        useCapture: false,
        passive: true,
      };
    } else {
      return {
        passive: false,
      };
    }
  }

  detachMousewheelListener() {
    let scrollEl = window;

    scrollEl.removeEventListener(
      "mousewheel",
      this.mousewheelListener,
      this.options
    );
  }

  detachScrollListener() {
    let scrollEl = window;

    scrollEl.removeEventListener("scroll", this.scrollListener, this.options);
    scrollEl.removeEventListener("resize", this.scrollListener, this.options);
  }

  getParentElement(el) {
    return el && el.parentNode;
  }

  attachScrollListener() {
    const parentElement = this.getParentElement(this.scrollComponent);

    if (!parentElement) {
      return;
    }

    let scrollEl = window;

    scrollEl.addEventListener(
      "mousewheel",
      this.mousewheelListener,
      this.options
    );
    scrollEl.addEventListener("scroll", this.scrollListener, this.options);
    scrollEl.addEventListener("resize", this.scrollListener, this.options);
  }

  mousewheelListener(e) {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1 && !this.isPassiveSupported()) {
      e.preventDefault();
    }
  }

  calculateOffset() {
    const el = this.scrollComponent;
    const scrollEl = window;

    const doc =
      document.documentElement || document.body.parentNode || document.body;
    const scrollTop =
      scrollEl.pageYOffset !== undefined ? scrollEl.pageYOffset : doc.scrollTop;
    return this.calculateOffsetFromElements(el, scrollTop);
  }

  scrollListener() {
    const el = this.scrollComponent;

    const offset = this.calculateOffset();

    // Here we make sure the element is visible
    if (el && el.offsetParent !== null) {
      this.props.onScroll({
        offset,
      });
    }
  }

  calculateOffsetFromElements(el, scrollTop) {
    if (!el) {
      return 0;
    }

    return (
      this.calculateTopPosition(el) +
      (el.offsetHeight - scrollTop - window.innerHeight)
    );
  }

  calculateTopPosition(el) {
    if (!el) {
      return 0;
    }
    return el.offsetTop + this.calculateTopPosition(el.offsetParent);
  }

  render() {
    const { children, element, ref, ...wrapperProps } = this.props;

    wrapperProps.ref = (node) => {
      this.scrollComponent = node;
      if (ref) {
        ref(node);
      }
    };

    return React.createElement(element, wrapperProps, [children]);
  }
}
