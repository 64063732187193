import cs from "classnames";
import { useState } from "react";
import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import { Link } from "react-router-dom";

export default function Info({ data, collectionData, collection }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex w-full text-white   flex-col">
      <div
        onClick={() => setOpen(!open)}
        className="flex border-b px-4 py-2.5 justify-between items-center border-darkGray rounded-t-lg bg-transparent"
      >
        <span className="font-semibold text-lg">About This Collection</span>
        <img alt="arrow indicator" src={open ? UpArrow : DownArrow} />
      </div>
      {open && (
        <div className={cs("py-4 flex flex-col gap-3")}>
          <div className="flex px-4 flex-col">
            <Link
              to={`/collection/${collection}`}
              className="font-semibold text-purple"
            >
              {collectionData?.name}
            </Link>
          </div>
          <div className="flex gap-2 px-4">
            <img
              className="w-32"
              alt="collection preview"
              src={collectionData?.imageURL}
            />
            <span>
              {collectionData?.description?.length > 140
                ? `${collectionData?.description?.substring(0, 140)}...`
                : collectionData?.description}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
