import { dismissToast, loadingToast, successToast } from "components/Toasts";
import { WaitForTransaction } from "graphql/api";
import { handleError } from "helpers/errors";
import { useEVM } from "hooks/EVMhook";
import { useState } from "react";
import Modal from "react-modal";
import Orb from "assets/icons/Orb.svg";
import { nFormatter } from "helpers/formatNumber";
import { ethers } from "ethers";
import Button from "components/Button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#000",
  },
  overlay: {
    backgroundColor: "rgba(0, 0,0, 0.8)",
  },
};

function SettleModal({ closeModal, data }) {
  const { auctionContract } = useEVM();
  const [loading, setLoading] = useState(false);

  async function handleSettle() {
    setLoading(true);
    try {
      const transaction = await auctionContract?.instance?.resultAuction(
        data.nftAddress,
        data.tokenId
      );
      const toastId = loadingToast("Settling auction...");
      auctionContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          successToast("Auction settled!");
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Resolve auction
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          <div className="flex justify-between px-6 border-gray border-t py-4">
            <img
              alt="marketCard"
              className="rounded-xl w-20 h-20"
              src={data?.image}
            />
            <div className="flex">
              <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
                <span className="text-white text-lg font-medium">
                  Reserved price
                </span>
                <div className="flex text-2xl gap-1.5 font-bold items-center">
                  <img className="h-5" alt="eth" src={Orb} />
                  {data?.maximumBid
                    ? nFormatter(
                        ethers.utils.formatEther(data?.reservePrice),
                        2
                      )
                    : 0}
                </div>
              </div>
              <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
                <span className="text-white text-lg font-medium">
                  {parseInt(data?.maximumBid) < parseInt(data?.reservePrice)
                    ? "Reserved price not met"
                    : `Highest Bid `}
                </span>
                <div className="flex text-2xl gap-1.5 font-bold items-center">
                  <img className="h-5" alt="eth" src={Orb} />
                  {data?.maximumBid
                    ? nFormatter(ethers.utils.formatEther(data?.maximumBid), 2)
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div className="w-48 text-white py-6 self-center">
            <Button
              onClick={handleSettle}
              disabled={loading}
              text={loading ? "loading..." : "Settle auction"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SettleModal;
