import cs from "classnames";
import { isSameAddress } from "helpers/address";

export default function Menu({ onChange, active, address, userAddress }) {
  function handleMenuChange(id) {
    onChange(id);
  }

  return (
    <div className="flex flex-wrap">
      <button
        onClick={() => handleMenuChange(0)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 0,
          "bg-darkGray": active !== 0,
        })}
      >
        Owned NFTs
      </button>
      <button
        onClick={() => handleMenuChange(4)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 4,
          "bg-darkGray": active !== 4,
        })}
      >
        Auctions
      </button>
      <button
        onClick={() => handleMenuChange(1)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 1,
          "bg-darkGray ": active !== 1,
        })}
      >
        Activity
      </button>
      {isSameAddress(address, userAddress) && (
        <>
          <button
            onClick={() => handleMenuChange(2)}
            className={cs("text-white text-lg font-medium px-5 py-1.5", {
              "bg-dark border-t-2 border-purple": active === 2,
              "bg-darkGray ": active !== 2,
            })}
          >
            Offers Made
          </button>
          <button
            onClick={() => handleMenuChange(3)}
            className={cs("text-white text-lg font-medium px-5 py-1.5", {
              "bg-dark border-t-2 border-purple": active === 3,
              "bg-darkGray ": active !== 3,
            })}
          >
            Offers Received
          </button>
          <button
            onClick={() => handleMenuChange(5)}
            className={cs("text-white text-lg font-medium px-5 py-1.5", {
              "bg-dark border-t-2 border-purple": active === 5,
              "bg-darkGray ": active !== 5,
            })}
          >
            Create Bundle
          </button>
        </>
      )}
    </div>
  );
}
