import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

function addCamelcasePrefix(prefix, s) {
  return prefix + s[0].toUpperCase() + s.substring(1);
}

// interface StateVarConfig<T> {
//     default: T,
//     serialize(val: T): string,
//     deserialize(s: string): T,
// }

export function JSONURLState(_default) {
  return {
    default: _default,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  };
}

export function NumberURLState(_default) {
  return {
    default: _default,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  };
}

export function StringURLState(_default) {
  return {
    default: _default,
    serialize: (x) => x,
    deserialize: (x) => x,
  };
}

/**
 * If this makes your component render on repeat or constantly trigger useEffect,
 * its because useEffect is using a shallow comparison and this function will
 * return a new object, parsed from URL params everytime.
 *
 * To fix this, use useEffectWithDeepEquality.
 */
export function useURLState(_stateObjectWithDefaults) {
  const [stateObjectWithDefaults] = useState(_stateObjectWithDefaults);
  const history = useHistory();
  const searchParams = useLocation().search;
  const queryParams = new URLSearchParams(searchParams);

  const state = {};
  for (const [stateVarName, stateVarConfig] of Object.entries(
    stateObjectWithDefaults
  )) {
    const {
      default: _default,
      serialize,
      deserialize,
    } = {
      default: null,
      serialize: (x) => x,
      deserialize: (x) => x,
      ...stateVarConfig,
    };

    // TODO if we can implement some state storage mechanism we can make sure we don't load
    // stateVal from queryParams everytime, which means we can pass back the same object by
    // reference and won't trigger useEffect unnecessarily.
    const stateVal = queryParams.has(stateVarName)
      ? deserialize(queryParams.get(stateVarName))
      : _default;
    state[stateVarName] = stateVal;
    state[addCamelcasePrefix("set", stateVarName)] = (val) => {
      const serialized = serialize(val);
      queryParams.set(stateVarName, serialized);
      history.replace(history.location.pathname + "?" + queryParams.toString());
    };
    state[addCamelcasePrefix("push", stateVarName)] = (val) => {
      const serialized = serialize(val);
      queryParams.set(stateVarName, serialized);
      history.push(history.location.pathname + "?" + queryParams.toString());
    };
  }

  return state;
}
