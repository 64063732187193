export const fetchData = async (url, retries) => {
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (res.ok) {
      return await res.json();
    }
    if (retries > 0) {
      fetchData(url, retries - 1);
    }
    throw new Error(res.status);
  } catch (err) {
    console.log(err);
  }
};
