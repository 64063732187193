import { useTokenData } from "helpers/tokens";
import cs from "classnames";
import { ethers } from "ethers";
import moment from "moment";
import Address from "components/Address";
import { useEVM } from "hooks/EVMhook";
import { isSameAddress } from "helpers/address";
import { Link } from "react-router-dom";

export default function AuctionRow({ data }) {
  const tokenData = useTokenData(
    data.tokenId,
    data?.nftAddress,
    data?.isERC1155
  );

  const { address } = useEVM();

  return (
    <div className="flex items-center font-semibold pl-8 rounded-t-lg text-white py-3 border-b px-4 bg-dark border-darkGray">
      <Link
        to={`/nft/${data?.nftAddress}/${data.tokenId}`}
        className="w-3/12 flex items-center gap-2 "
      >
        <img
          className={cs("rounded-xl w-16 h-16")}
          alt="market-card"
          src={tokenData?.image}
        />
        <span className="text-purple">#{data.tokenId}</span>
      </Link>
      <span className="w-3/12">
        {ethers.utils.formatEther(data.reservePrice)}
      </span>
      <span className="w-3/12">
        {ethers.utils.formatEther(data.maximumBid)}
      </span>
      <div className="w-4/12 ">
        <Link
          to={`/profile/${data.currentBidder}`}
          className="w-2/12 text-purple mr-1.5"
        >
          <Address address={data.currentBidder} length={8} />
        </Link>
        {isSameAddress(address, data.currentBidder) ? "(yours)" : ""}
      </div>
      <span className="w-4/12">
        {`${moment(
          new Date(parseInt(data?.endTime + "000")).toISOString()
        ).format("MMMM Do YYYY, h:mm a")}`}
      </span>
    </div>
  );
}
