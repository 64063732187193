import Menu from "./Menu";
import { useEffect, useState } from "react";
import { collectionMeta } from "../../helpers/collectionInfo";
import MyNFTS from "./MyNFTS";
import { useParams } from "react-router-dom";
import { ActiveCollections, UserTransactionActivity } from "../../graphql/api";
import ActivityList from "components/activity/ActivityList";
import MyOffers from "./MyOffers/MyOffers";
import Offers from "./Offers/Offers";
import { useEVM } from "hooks/EVMhook";
import Identicon from "./Identicon";
import Copy from "../../assets/icons/copy.svg";
import Address from "components/Address";
import { useCallWith404OnFailure } from "helpers/Hooks";
import { useURLState } from "helpers/URLState";
import { NumberURLState } from "helpers/URLState";
import CreateBundle from "pages/Profile/CreateBundle/CreateBundle";
import MyAuctions from "./MyAuctions";

export default function Profile() {
  const { userAddress } = useParams();
  const [collections, setCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(true);
  const { address } = useEVM();
  const { activeMenu, setActiveMenu } = useURLState({
    activeMenu: NumberURLState(0),
  });

  const fetchUserTransactionActivityPage = useCallWith404OnFailure(
    async (pageNum) => {
      return await UserTransactionActivity({
        address: userAddress,
        pageNum,
      });
    }
  );

  useEffect(() => {
    ActiveCollections().then((ac) => {
      setLoadingCollections(false);
      setCollections(ac?.map((_ac) => collectionMeta[_ac.id]));
    });
  }, [userAddress]);

  return (
    <div style={{ minHeight: "100vh" }} className="flex flex-col py-14 w-full">
      <div className="flex flex-col gap-10">
        <div className="items-center flex flex-col gap-4 w-full justify-center">
          <div className="  ">
            <Identicon account={userAddress} size={100} />
          </div>
          <div className=" flex gap-1.5 font-medium  text-white ">
            <span className="px-2.5 bg-darkGray py-1">
              <Address address={userAddress} length={5} ellipsis={true} />
            </span>
            <img
              onClick={() => {
                navigator.clipboard.writeText(userAddress);
              }}
              className=" cursor-pointer w-8 bg-darkGray p-2"
              src={Copy}
              alt="copy"
            />
          </div>
        </div>
        <div className="flex justify-center border-b border-darkGray">
          <Menu
            userAddress={userAddress}
            address={address}
            active={activeMenu}
            onChange={setActiveMenu}
          />
        </div>
      </div>
      {activeMenu === 0 && (
        <MyNFTS
          pageType="user"
          userAddress={userAddress}
          loadingCollections={loadingCollections}
          collections={collections}
        />
      )}

      {activeMenu === 4 && <MyAuctions userAddress={userAddress} />}

      {activeMenu === 1 && (
        <div className="flex pt-10 justify-center w-full">
          <ActivityList
            collectionMeta={collectionMeta}
            fetchPage={fetchUserTransactionActivityPage}
          />
        </div>
      )}
      {activeMenu === 2 && (
        <div className="flex pt-10 justify-center w-full">
          <MyOffers address={userAddress} />
        </div>
      )}
      {activeMenu === 3 && (
        <div className="flex pt-10 justify-center w-full">
          <Offers address={userAddress} />
        </div>
      )}
      {activeMenu === 5 && (
        <div className="flex justify-center w-full">
          <CreateBundle collections={collections} userAddress={userAddress} />
        </div>
      )}
    </div>
  );
}
