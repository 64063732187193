import Attributes from "./Attributes";
import Details from "./Details";
import Info from "./Info";
import { useEVM } from "hooks/EVMhook";

export default function Container({ data, collectionData, collection }) {
  const { orbitBundlesContract } = useEVM();
  const isBundle = collection === orbitBundlesContract?.instance?.address;
  return (
    <div className="flex flex-col rounded-lg  border border-darkGray ">
      <Details
        data={data}
        collection={collection}
        collectionData={collectionData}
      />
      {!isBundle && <Attributes collectionData={collectionData} data={data} />}
      <Info
        data={data}
        collection={collection}
        collectionData={collectionData}
      />
    </div>
  );
}
