import Hamburger from "../components/Hamburger";
import Logo2 from "../assets/branding/Logo.svg";
import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import SearchBar from "./SearchBar";
import WrapEVMOS from "components/WrapEVMOS";

function DesktopHeader({
  setShow,
  show,
  thisDoesNot,
  setShowWrapModal,
  showWrapModal,
}) {
  return (
    <header className="flex px-5 mdd:px-8 w-full  z-40  shadow-sm bg-dark justify-center ">
      <nav className="flex   items-center justify-between w-full">
        {!show && <Hamburger onClick={() => setShow(true)} />}

        <div className="flex items-center justify-between gap-3 py-3  w-full ">
          <div className="flex flex-1 gap-1">
            <Link
              to="/"
              className="text-gray  md:flex hidden  items-center px-4 h-10 gap-2 hover:bg-opacity-10 font-semibold"
            >
              <img
                style={{ minWidth: "36px" }}
                alt="logo"
                className="h-9 "
                src={Logo2}
              />
              {/* <img alt="logo" className="h-7 hidden mdd:flex" src={Logo} /> */}
            </Link>
            {!show && <SearchBar />}
          </div>
          <div className="flex items-center gap-3 justify-center ">
            <div className=" md:flex hidden  items-center gap-1 justify-center ">
              <Link
                to="/listings"
                className="text-white flex items-center h-10 mdd:px-3 px-2 hover:bg-gray hover:bg-opacity-10 font-semibold"
              >
                Explore
              </Link>
              <Link
                to="/collections"
                className="text-white flex items-center h-10 px-3 hover:bg-gray  hover:bg-opacity-10 font-semibold"
              >
                Collections
              </Link>
              <Link
                to="/rewards"
                className="text-white flex items-center h-10 px-3 hover:bg-gray hover:bg-opacity-10 font-semibold"
              >
                Rewards
              </Link>
              <button
                className="text-white flex items-center h-10 px-3 hover:bg-gray hover:bg-opacity-10 font-semibold"
                onClick={() => setShowWrapModal(true)}
              >
                Wrapping Station
              </button>
              <a
                href="https://docs.orbitalapes.com/orbit-marketplace/about"
                className="text-white flex items-center h-10 px-3 hover:bg-gray hover:bg-opacity-10 font-semibold"
              >
                Docs
              </a>
            </div>
            {!show && <ProfileDropdown />}
            {showWrapModal && (
              <WrapEVMOS closeModal={() => setShowWrapModal(false)} />
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default DesktopHeader;
