import User from "../assets/icons/contact.svg";
import { useEVM } from "../hooks/EVMhook";
import cs from "classnames";
import Address from "components/Address";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function ProfileDropdown() {
  const { address, isMetamask, isConnected, isNetwork, addNetwork } = useEVM();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  function getButton() {
    if (!isMetamask) return "Install Metamask";
    if (isConnected && isNetwork)
      return <Address address={address} length={11} />;
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to EVMOS";
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef} className="flex flex-col">
      {isConnected && isNetwork && (
        <div
          onClick={() => setOpen(!open)}
          className={cs(
            " text-white flex cursor-pointer items-center gap-2 p-1 mdd:pr-2.5 rounded-full border border-white"
          )}
        >
          <img
            style={{ minWidth: "1.75rem" }}
            className="w-7  h-7 p-1 rounded-full border border-white"
            alt="User"
            src={User}
          />
          <span className="hidden mdd:flex">{getButton()}</span>
        </div>
      )}
      {(!isConnected || !isNetwork) && (
        <div
          onClick={() => {
            return isMetamask
              ? addNetwork()
              : window.open(
                  "https://evmos.dev/guides/keys-wallets/metamask.html",
                  "_blank"
                );
          }}
          className={cs(
            "flex cursor-pointer items-center gap-2 p-1 mdd:pr-2.5 rounded-full border border-white"
          )}
        >
          <img
            className="w-7 h-7 p-1 rounded-full border border-white"
            alt="User"
            src={User}
          />
          <span className="text-white hidden mdd:flex text-sm">
            {getButton()}
          </span>
        </div>
      )}
      {open && (
        <div className="flex absolute top-14 bg-dark text-white w-36 border border-darkGray rounded flex-col">
          <Link
            onClick={(e) => {
              setOpen(false);
            }}
            to={`/profile/${address}`}
            className={cs(
              "py-2 px-2 border-b border-darkGray hover:bg-darkGray"
            )}
          >
            Profile
          </Link>
          <Link
            onClick={(e) => {
              setOpen(false);
            }}
            to={`/settings`}
            className={cs("py-2 px-2 hover:bg-darkGray")}
          >
            Settings
          </Link>
        </div>
      )}
    </div>
  );
}
