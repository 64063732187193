import { ethers } from "ethers";
import Orb from "../../assets/icons/Orb.svg";
import { Link } from "react-router-dom";

export default function CollectionCard({ data }) {
  return (
    <div style={{ maxHeight: 390 }} className="flex text-white w-96 ">
      <Link
        to={`/collection/${data?.contract?.toLowerCase()}`}
        className="flex-col gap-6 flex shadow pb-7 bg-darkGray hover:shadow-lg rounded-2xl"
      >
        <img
          alt="collection"
          className="h-40 object-cover rounded-t-2xl"
          src={data?.bannerURL}
        />
        <div className="flex items-center px-4 gap-2 h-24 mb-2 flex-col">
          <span className="font-bold text-xl">{data?.name}</span>
          <span className="text-white text-center">
            {data?.description?.length > 150
              ? `${data?.description?.substring(0, 140)}...`
              : data?.description}
          </span>
        </div>
        <div className="flex justify-center justify-self-end gap-14 px-7">
          <div className="flex items-center flex-col">
            <span className=" font-bold text-lg">
              {data?.collectionSummary?.listingsCount || 0}
            </span>
            <span className="text-white">Listings</span>
          </div>
          <div className="flex items-center flex-col">
            <div className=" flex font-bold text-xl items-center gap-1.5">
              <img className="h-3 mt-0.5" alt="eth" src={Orb} />
              {data?.collectionSummary?.sellsVolume
                ? parseFloat(
                    ethers?.utils?.formatEther(
                      data?.collectionSummary?.sellsVolume
                    )
                  ).toFixed(0)
                : "0"}
            </div>
            <span className="text-white">Volume</span>
          </div>
          <div className="flex items-center flex-col">
            <div className=" flex font-bold text-xl items-center gap-1.5">
              <img className="h-3 mt-0.5" alt="eth" src={Orb} />
              {data?.collectionSummary?.nftCount || 0}
            </div>
            <span className="text-white">Items</span>
          </div>
        </div>
      </Link>
    </div>
  );
}
