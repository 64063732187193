import TokenCard from "components/TokenCard";
import TokenWidthMeasuringContainer from "components/TokenWidthMeasuringContainer";
import { CollectionBundles } from "graphql/api";
import { useEffect, useState } from "react";

export default function Bundles({ collectionAddress, address }) {
  const [bundles, setBundles] = useState([]);
  useEffect(() => {
    CollectionBundles(collectionAddress).then((_bundles) =>
      setBundles(_bundles)
    );
  }, [collectionAddress]);

  return (
    <>
      <TokenWidthMeasuringContainer>
        {({ cardWidth }) =>
          bundles?.map((data, index) => (
            <TokenCard
              width={cardWidth}
              address={address}
              profile={true}
              key={`${data.nftAddress}:${data.tokenId}`}
              data={data}
            />
          ))
        }
      </TokenWidthMeasuringContainer>
    </>
  );
}
