import { useState } from "react";
import { useTokenData, getTokenName } from "helpers/tokens";
import cs from "classnames";
import { formatEther, payTokenMeta } from "helpers/payTokens";
import { nFormatter } from "helpers/formatNumber";
import Countdown from "react-countdown";
import { useBundleTokensData } from "../helpers/tokens";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { collectionMeta } from "helpers/collectionInfo";
import { Link } from "react-router-dom";

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  return (
    <div className="text-white self-center   ">
      {days > 0 ? `${days}Days |` : ""} {hours}Hrs | {minutes}Min
    </div>
  );
};

function useTokenNameForCard(data) {
  return getTokenName(data);
}

function handleCarouselContainerClicked(event) {
  const isCarouselCardClicked =
    event.target.querySelector(".token-carousel-card") != null;
  if (!isCarouselCardClicked) {
    // We clicked the carousel but not the image
    event.preventDefault(); // Stop the href from taking us to the bundle NFTs page
    event.stopPropagation(); // Don't fire other events
  }
}

function CarouselMedia({ bundle }) {
  const tokensData = useBundleTokensData(bundle?.tokenIds, bundle?.nftAddress);

  return (
    <div onClick={handleCarouselContainerClicked}>
      <Carousel showThumbs={false}>
        {tokensData.map((token, index) => (
          <img
            key={index}
            className={cs("rounded-xl h-full w-auto token-carousel-card")}
            alt="market-card"
            src={token.image}
          />
        ))}
      </Carousel>
    </div>
  );
}

function MediaSection({
  token,
  tokenData,
  mediaComponentOverride,
  setMediaLoaded,
}) {
  if (mediaComponentOverride != null) {
    return mediaComponentOverride;
  }
  if (token?.bundle != null) {
    return <CarouselMedia bundle={token.bundle} />;
  }
  if (tokenData?.image?.includes(".mp4")) {
    return (
      <video
        className={cs("rounded-xl")}
        autoPlay={true}
        onLoadedData={() => {
          setMediaLoaded(true);
        }}
        src={tokenData?.image}
        muted={true}
        loop={true}
        playsInline={true}
      />
    );
  }
  return (
    <img
      className={cs("rounded-xl h-full w-auto")}
      onLoad={() => setMediaLoaded(true)}
      alt="market-card"
      src={tokenData?.image}
    />
  );
}

function InfoSection({ data }) {
  if (data?.auction) {
    return <AuctionInfoSection auction={data.auction} />;
  }
  return <DefaultInfoSection data={data} />;
}

function AuctionInfoCountdown({ auction }) {
  const startTime = auction?.startTime;
  const endTime = auction?.endTime;
  const currentTime = parseInt(new Date().getTime() / 1000);
  if (currentTime < startTime) {
    return (
      <div className=" text-green flex gap-1 ">
        Starts:{" "}
        <Countdown
          renderer={CountdownRenderer}
          date={new Date(parseInt(startTime + "000"))}
        />
      </div>
    );
  }
  if (currentTime < endTime) {
    return (
      <div className=" text-green flex gap-1">
        Ends:{" "}
        <Countdown
          renderer={CountdownRenderer}
          date={new Date(parseInt(endTime + "000"))}
        />
      </div>
    );
  }
  return <span className="text-lg text-purple font-medium">Ended</span>;
}

function AuctionInfoSection({ auction }) {
  return (
    <div className="flex flex-col gap-1 w-full">
      <span className="text-gray">Auction</span>
      <div className="flex">
        <div className="flex self-start h-8 items-center  flex-1 rounded ">
          <AuctionInfoCountdown auction={auction} />
        </div>
      </div>
    </div>
  );
}

function DefaultInfoSection({ data }) {
  const id = data?.tokenId;
  if (data?.listed) {
    return (
      <div className="flex self-end h-11 w-full  pr-4 ">
        <div className="flex flex-col" key={id}>
          <span className=" text-gray">price</span>
          <div className="flex text-xl gap-1.5 font-bold items-center">
            <img
              className="h-4"
              alt="eth"
              src={payTokenMeta[data?.payToken]?.image}
            />
            <span className=" text-white text-lg">
              {`${nFormatter(
                formatEther(data?.pricePerItem, data?.payToken),
                2
              )}`}
              <span className="text-sm font-light">
                {` (${payTokenMeta[data?.payToken]?.name})`}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (data?.lastSalePrice > 0) {
    return (
      <div className="flex self-end h-11 w-full  pr-4 ">
        <div className="flex  flex-col">
          <span className=" text-gray">last sale</span>
          <div className="flex text-xl gap-1.5 font-bold items-center">
            <img
              className="h-3"
              alt="eth"
              src={payTokenMeta[data?.lastSalePayToken]?.image}
            />
            <span className=" text-white text-lg">
              {`${formatEther(data?.lastSalePrice, data?.lastSalePayToken)}`}
              <span className="text-sm font-light">
                {` (${payTokenMeta[data?.lastSalePayToken]?.name})`}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex self-end h-11 w-full  pr-4 ">
      <span className="self-end text-gray">Not Listed</span>
    </div>
  );
}

function preventNavigation(event) {
  event.preventDefault();
}

export default function TokenCard({
  width,
  data,
  isSelected,
  onClick,
  mediaComponentOverride = null,
}) {
  const collectionAddress =
    data?.bundle != null ? data?.bundle?.nftAddress : data?.nftAddress;
  const collection = collectionMeta[collectionAddress];
  const id = data?.tokenId;
  const tokenData = useTokenData(
    id,
    data?.nftAddress,
    data?.isERC1155,
    data?.bundle
  );
  const [mediaLoaded, setMediaLoaded] = useState(
    mediaComponentOverride != null || data?.bundle != null
  );
  const tokenName = useTokenNameForCard({ ...data, ...tokenData });

  function getCollectionBaseAddress() {
    return `${data?.nftAddress}`;
  }

  return (
    <div style={{ width }} className="flex" onClick={onClick}>
      <Link
        to={`/nft/${getCollectionBaseAddress()}/${id}`}
        onClick={onClick != null ? preventNavigation : null}
        className={cs(
          "flex gap-4 bg-darkGray bg-opacity-50 flex-col shadow hover:shadow-lg rounded-xl w-full pt-5 pb-5 p-7",
          {
            "border-4 border-purple": isSelected,
            "border border-purple": data?.auction != null,
          }
        )}
      >
        <div className="">
          <div>
            <Link
              to={`/collection/${getCollectionBaseAddress()}`}
              onClick={onClick != null ? preventNavigation : null}
              className="font-medium text-purple"
            >
              {collection?.name}
            </Link>
          </div>
          <div>
            <span className="text-white font-semibold">{tokenName}</span>
          </div>
        </div>
        <div
          className={cs({
            hidden: mediaLoaded,
            "animate-pulse w-full aspect-w-1 aspect-h-1 rounded-xl bg-dark":
              !mediaLoaded,
          })}
        />
        <div className="relative">
          <div
            className={cs("", {
              hidden: !mediaLoaded,
              block: mediaLoaded,
            })}
          >
            <div className="w-full aspect-w-1 aspect-h-1">
              <div className="flex justify-center items-center w-full h-full">
                <MediaSection
                  token={data}
                  tokenData={tokenData}
                  mediaComponentOverride={mediaComponentOverride}
                  setMediaLoaded={setMediaLoaded}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center absolute -right-1 -bottom-12">
            <div className="w-16 h-16 border-4 border-purple bg-dark rounded-full">
              <img
                className="w-full rounded-full"
                alt="collection preview"
                src={collection?.imageURL}
              />
            </div>
          </div>
        </div>
        <div className="flex self-end h-11 w-full  pr-4 ">
          <InfoSection data={data} />
        </div>
      </Link>
    </div>
  );
}
