import { ethers } from "ethers";
import { useState } from "react";
import { isSameAddress } from "../../../helpers/address";
import moment from "moment";
import AcceptOfferModal from "../../../components/AcceptOfferModal";
import OfferModal from "components/Offer/OfferModal";
import { nFormatter } from "helpers/formatNumber";
import Address from "components/Address";

export default function OfferRow({
  offer,
  owner,
  address,
  tokenId,
  collectionMeta,
  meta,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex py-4 text-white border-b px-4 border-darkGray">
        <span className="w-3/12">
          {nFormatter(ethers?.utils?.formatEther(offer?.pricePerItem), 2)}
          <br className=" mdd:hidden" /> EVMOS
        </span>
        <span className="w-5/12">
          {`${moment(new Date(offer?.deadline * 1000).toISOString()).format(
            "MMMM Do YYYY, h:mm a"
          )}`}
        </span>
        <span className="w-3/12 text-purple">
          <Address address={offer?.creator} length={8} />
        </span>
        {isSameAddress(owner, address) && (
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="h-8 font-bold text-lightbg rounded-md w-28 text-sm mdd:w-36 bg-purple"
          >
            {"Accept offer"}
          </button>
        )}
        {isSameAddress(address, offer?.creator) && (
          <button
            onClick={() => setShowCancelModal(true)}
            className=" h-8 font-bold text-lightbg rounded-md w-28 text-sm mdd:w-36  bg-purple"
          >
            {"Cancel offer"}
          </button>
        )}
        {!isSameAddress(address, offer?.creator) &&
          !isSameAddress(owner, address) && (
            <div className="flex w-28 mdd:w-36" />
          )}
      </div>
      {showCancelModal && (
        <OfferModal
          tokenData={{ ...offer, ...meta, tokenId }}
          collectionData={collectionMeta}
          viewerHasOffer={offer}
          closeModal={() => setShowCancelModal(false)}
        />
      )}
      {showModal && (
        <AcceptOfferModal
          collectionMeta={collectionMeta}
          data={{ ...meta, ...offer, tokenId }}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
