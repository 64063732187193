import Checkbox from "components/basics/Checkbox";
import Web3 from "web3";
import { useState } from "react";
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "components/Toasts";
import { fetchData } from "lambda/lambda";

const SET_SETTING_URL = "http://localhost:8080/setProfileSettings?";
// const SET_SETTING_URL = "https://api.orbitalapes.com/setProfileSettings?";

window?.ethereum?.request({ method: "eth_requestAccounts" });
const web3 = new Web3(window.ethereum);

export default function Notifications() {
  const [notificationsActive, setNotificationsActive] = useState(false);
  const [email, setEmail] = useState("");

  async function onSave() {
    const addresses = await web3.eth.getAccounts();
    const currentAddress = addresses[0];
    const dataToSign = {
      email,
      active: notificationsActive ? "true" : "false",
    };
    const signature = await web3.eth.personal.sign(
      web3.utils.toHex(JSON.stringify(dataToSign)),
      currentAddress,
      (err, res) => console.log(err, res)
    );
    const toastId = loadingToast("Processing your request");
    const query = `email=${encodeURIComponent(
      email
    )}&signature=${signature}&publicAddress=${encodeURIComponent(
      currentAddress
    )}&active=${notificationsActive}`;
    try {
      await fetchData(SET_SETTING_URL + query);
      dismissToast(toastId);
      successToast("Added delegator role to you user!");
    } catch (error) {
      dismissToast(toastId);
      errorToast("Error, please try again or contact us in discord");
    }
  }

  return (
    <div className="flex gap-8 flex-col">
      <span className="text-2xl font-medium">Notifications</span>
      <div className="flex gap-4 flex-col">
        <div className="flex gap-4">
          <Checkbox
            value={notificationsActive}
            onChange={() => setNotificationsActive(!notificationsActive)}
          />
          <span className="text-lg">Activity Notifications</span>
        </div>
        <div className="flex gap-2 flex-col">
          <span className="text-lg">Email Address</span>

          <div className="flex flex-1 px-3 max-w-xl items-center py-2 gap-2 bg-darkGray border border-dark rounded-xl">
            <input
              onClick={() => {}}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-2 flex-1 text-white placeholder-gray bg-transparent outline-none"
            />
          </div>
        </div>
        <div className="flex max-w-xl mt-4 justify-end w-full">
          <button
            disabled={true}
            onClick={() => onSave()}
            className="bg-purple opacity-30 shadow self-end font-medium text-lg px-5 py-2 w-44 rounded-lg text-white"
          >
            Coming soon
          </button>
        </div>
      </div>
    </div>
  );
}
