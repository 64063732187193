import TokenCard from "components/TokenCard";

const NFT_PREVIEW_WIDTH = 200;
const NFT_PREVIEW_HEIGHT = NFT_PREVIEW_WIDTH * 1.5;
const NFT_PREVIEW_SCALE = 0.4;

export default function ShrunkenTokenCard({ token, onClick }) {
  const scaleTransform = `scale(${NFT_PREVIEW_SCALE})`;
  return (
    <div
      style={{
        width: NFT_PREVIEW_WIDTH * NFT_PREVIEW_SCALE,
        height: NFT_PREVIEW_HEIGHT * NFT_PREVIEW_SCALE,
      }}
    >
      <div
        style={{
          transform: `${scaleTransform}`,
          transformOrigin: "top left",
        }}
      >
        <TokenCard
          onClick={() => onClick(token)}
          width={NFT_PREVIEW_WIDTH}
          profile={true}
          key={`${token.nftAddress}:${token.tokenId}`}
          data={token}
        />
      </div>
    </div>
  );
}
