import { nFormatter } from "helpers/formatNumber";
import Orb from "assets/icons/Orb.svg";
import {
  formatEther,
  payTokenMeta,
  ZERO_ADDRESS,
} from "../../../helpers/payTokens";
import CancelAuction from "components/Auction/CancelAuction";
import Countdown from "react-countdown";
import { isSameAddress } from "helpers/address";
import { getHighestListing } from "../helpers";

const { ethers } = require("ethers");

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  return (
    <div className="text-white self-center  text-lg md:text-xl ">
      {days > 0 ? `${days}Days |` : ""} {hours}Hrs | {minutes}Min | {seconds}
      Secs
    </div>
  );
};

export default function ListingDetails({
  handleBuyItem,
  handleList,
  handleOffer,
  handleCancel,
  handleAuction,
  handleBid,
  handleUnbundle,
  data,
  viewerListing,
  listings,
  owner,
  address, // TODO rename to indicate this is the address of the browser's logged in wallet userWalletAddress
  highestOffer,
  viewerHasOffer,
  handleSettle,
}) {
  let LowestListing = getHighestListing(listings);

  let time = parseInt(new Date().getTime() / 1000);

  let validAuction = () => {
    if (time > data?.auction?.endTime && time < data?.auction?.startTime) {
      return null;
    } else {
      return data.auction;
    }
  };

  function getTitle() {
    if (data?.auction) {
      return "Auction";
    } else if (LowestListing) {
      return "Item listed";
    }
    return "Item not for sale";
  }

  function renderAuctionInfo() {
    if (time > data?.auction?.startTime && time < data?.auction?.endTime) {
      return (
        <div className="flex">
          <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
            <span className="text-white text-lg font-medium">
              <span className="mr-1">Highest bid </span>
              {isSameAddress(data?.auction?.currentBidder, address)
                ? "(yours)"
                : ""}
            </span>
            <div className="flex text-2xl gap-1.5 font-bold items-center">
              <img className="h-5" alt="eth" src={Orb} />
              {data?.auction?.maximumBid
                ? nFormatter(
                    ethers.utils.formatEther(data?.auction?.maximumBid),
                    2
                  )
                : 0}
            </div>
          </div>
          <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
            <span className="text-white text-lg font-medium">
              <span className="mr-1">Reserved price </span>
            </span>
            <div className="flex text-2xl gap-1.5 font-bold items-center">
              <img className="h-5" alt="eth" src={Orb} />
              {data?.auction?.reservePrice
                ? nFormatter(
                    ethers.utils.formatEther(data?.auction?.reservePrice),
                    2
                  )
                : 0}
            </div>
          </div>
          <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
            <span className="text-white text-lg font-medium">
              <span className="mr-1">Ends in </span>
            </span>
            <div className="flex  gap-1.5 font-bold items-center">
              <Countdown
                renderer={renderer}
                date={
                  new Date(
                    parseInt(
                      time < data?.auction?.startTime
                        ? data?.auction?.startTime + "000"
                        : data?.auction?.endTime + "000"
                    )
                  )
                }
              />
            </div>
          </div>
        </div>
      );
    } else if (time > data?.auction?.endTime) {
      return (
        <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
          <span className="text-white text-lg font-medium">
            {parseInt(data?.auction?.maximumBid) <
            parseInt(data?.auction?.reservePrice)
              ? "Reserved price not met"
              : `Sold for `}
          </span>
          <div className="flex text-2xl gap-1.5 font-bold items-center">
            <img className="h-5" alt="eth" src={Orb} />
            {data?.auction?.maximumBid
              ? nFormatter(
                  ethers.utils.formatEther(data?.auction?.maximumBid),
                  2
                )
              : 0}
          </div>
        </div>
      );
    } else if (time < data?.auction?.startTime) {
      return (
        <div className="flex">
          <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
            <span className="text-white text-lg font-medium">
              <span className="mr-1">Reserved price </span>
            </span>
            <div className="flex text-2xl gap-1.5 font-bold items-center">
              <img className="h-5" alt="eth" src={Orb} />
              {data?.auction?.reservePrice
                ? nFormatter(
                    ethers.utils.formatEther(data?.auction?.reservePrice),
                    2
                  )
                : 0}
            </div>
          </div>
          <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
            <span className="text-white text-lg font-medium">
              <span className="mr-1">Starts in </span>
            </span>
            <div className="flex  gap-1.5 font-bold items-center">
              <Countdown
                renderer={renderer}
                date={
                  new Date(
                    parseInt(
                      time < data?.auction?.startTime
                        ? data?.auction?.startTime + "000"
                        : data?.auction?.endTime + "000"
                    )
                  )
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex w-full bg-darkGray bg-opacity-20 rounded-lg gap-6 border border-darkGray  flex-col">
      <div className="flex flex-col">
        <div className="flex border-b px-4 py-2.5 justify-between items-center border-darkGray rounded-t-lg bg-dark text-white">
          <span className="self-start">{`${getTitle()}`}</span>
        </div>

        {data?.auction && (
          <div className="flex md:flex-row flex-col gap-5">
            {renderAuctionInfo()}
          </div>
        )}

        {LowestListing?.payToken && (
          <div className="flex gap-5">
            <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
              <span className="text-white text-lg font-medium">Price</span>
              <div className="flex text-3xl gap-3 font-bold items-center">
                <div className="flex text-3xl gap-1.5 font-bold items-center">
                  <img
                    className="h-5"
                    alt="eth"
                    src={payTokenMeta[LowestListing?.payToken]?.image}
                  />
                </div>
                {formatEther(
                  LowestListing?.pricePerItem,
                  LowestListing?.payToken
                )}
              </div>
            </div>
          </div>
        )}
        {!LowestListing && highestOffer && !data?.auction && (
          <div className="flex gap-5">
            <div className="flex text-white px-4 pt-2.5 gap-1 flex-col">
              <span className="text-white text-lg font-medium">
                Highest offer
              </span>
              <div className="flex text-3xl gap-1.5 font-bold items-center">
                <img className="h-5" alt="eth" src={Orb} />
                {nFormatter(
                  formatEther(
                    highestOffer?.pricePerItem,
                    highestOffer?.payToken
                  ),
                  2
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {data?.token?.owner !== ZERO_ADDRESS && (
        <div className="flex flex-wrap l px-4 pb-2.5 gap-3">
          {LowestListing && address?.toLowerCase() !== owner?.toLowerCase() && (
            <button
              onClick={handleBuyItem}
              className=" h-8 font-bold border-purple border bg-purple rounded-md w-36 text-white"
            >
              {"Buy item"}
            </button>
          )}

          {validAuction() &&
            (isSameAddress(data?.auction?.owner, address) ||
              isSameAddress(data?.auction?.currentBidder, address)) &&
            time > data?.auction?.endTime && (
              <button
                onClick={handleSettle}
                className=" h-8 font-bold border-purple border bg-purple rounded-md w-36 text-white"
              >
                {"Resolve auction"}
              </button>
            )}

          {validAuction() &&
            !isSameAddress(data?.auction?.owner, address) &&
            time > data?.auction?.startTime &&
            time < data?.auction?.endTime && (
              <button
                onClick={handleBid}
                className=" h-8 font-bold border-purple border bg-purple rounded-md w-36 text-white"
              >
                {"Place bid"}
              </button>
            )}

          {address?.toLowerCase() === owner?.toLowerCase() && (
            <button
              onClick={handleList}
              className=" h-8 font-bold text-white rounded-md w-36  bg-purple"
            >
              {viewerListing ? "Edit listing" : "List"}
            </button>
          )}
          {viewerListing && owner?.toLowerCase() === address?.toLowerCase() && (
            <button
              onClick={() => handleCancel()}
              className="px-2 h-8 font-bold border-purple border bg-dark rounded-md w-32 text-white"
            >
              Cancel listing
            </button>
          )}
          {(address?.toLowerCase() === data?.token?.owner?.toLowerCase() ||
            (data?.auction &&
              address?.toLowerCase() === owner?.toLowerCase())) &&
            !validAuction() && (
              <button
                onClick={handleAuction}
                className="h-8 font-bold text-white rounded-md w-36 bg-purple"
              >
                {"Start Auction"}
              </button>
            )}
          {!data?.auction &&
            (address?.toLowerCase() === data?.token?.owner?.toLowerCase() ||
              address?.toLowerCase() === owner?.toLowerCase()) &&
            data?.token?.bundle != null && (
              <button
                onClick={handleUnbundle}
                className="h-8 font-bold text-white rounded-md w-36 bg-purple"
              >
                Unpack Bundle
              </button>
            )}
          {data?.auction &&
            address?.toLowerCase() === owner?.toLowerCase() &&
            data?.auction?.maximumBid.toString() === "0" && (
              <CancelAuction
                nftAddress={data?.token?.nftAddress}
                tokenId={data?.token?.tokenId}
              />
            )}
          {(data?.token?.isERC1155 ||
            address?.toLowerCase() !== owner?.toLowerCase()) &&
            !validAuction() && (
              <button
                onClick={handleOffer}
                className=" h-8 font-bold border-purple border bg-transparent rounded-md w-36 text-white"
              >
                {viewerHasOffer ? "Update offer" : "Make offer"}
              </button>
            )}
        </div>
      )}
    </div>
  );
}
