import _ from "lodash";
import { useEffect, Component } from "react";
import PropTypes from "prop-types";
import ScrollListener from "./ScrollListener";

function UseEffect({ effectCallback, sensitivityList }) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(effectCallback, [JSON.stringify(sensitivityList)]);
  return null;
}

function defaultState() {
  return {
    isLoading: true,
    hasNextPage: true,
    data: [],
  };
}

const STATE_MACHINE_STATES = {
  /**
   * Temporary state until component is mounted with props.queryData which triggers
   * transfer to INITIALIZE state
   */
  UNMOUNTED: "UNMOUNTED",
  /**
   * Every time props.queryData is updated we need to reset the component to page 0.
   * Any state can transfer to the INITIALIZE state and the INITIALIZE state's only
   * job is to reset state variables and then fetch the first page
   */
  INITIALIZE: "INITIALIZE",
  /**
   * When we start fetching a page we go to the FETCHING stage, once the page is
   * fetched we transfer to the RENDERING_NEW_PAGE state
   */
  FETCHING: "FETCHING",
  /**
   * The component will be in the RENDERING_NEW_PAGE state after fetching until it has
   * rendered with the newly fetched data. Once it has rendered with the new data,
   * we transfer into the LISTENING state and call onScroll if we missed any scroll
   * events while in FETCHING or RENDERING_NEW_PAGE state.
   */
  RENDERING_NEW_PAGE: "RENDERING_NEW_PAGE",
  /**
   * The component is actively listening for scroll events and will enter the FETCHING
   * state if we scroll below props.threshold
   */
  LISTENING: "LISTENING",
};

export default class InfiniteScroll extends Component {
  static propTypes = {
    renderData: PropTypes.func.isRequired,
    renderEmptyState: PropTypes.func.isRequired,
    renderLoader: PropTypes.func.isRequired,
    threshold: PropTypes.number,
    queryData: PropTypes.object.isRequired,
    fetchPage: PropTypes.func.isRequired,
    onDataChanged: PropTypes.func,
  };

  static defaultProps = {
    threshold: 800,
    onDataChanged: (data) => null,
  };

  constructor(props) {
    super(props);
    this.stateMachineState = STATE_MACHINE_STATES.UNMOUNTED;
    this.state = defaultState();
    this.pageToFetch = 0;
    this.lastReadOffset = null;
    this.mostUpToDateData = [];
  }

  fetchPageInternal = async () => {
    const queryData = this.props.queryData;
    const pageNum = this.pageToFetch;

    // Pre-fetch state updates
    this.pageToFetch = this.pageToFetch + 1;
    this.lastReadOffset = null;
    this.stateMachineState = STATE_MACHINE_STATES.FETCHING;
    this.setState({ isLoading: true });

    // Fetch page
    const { pageData, hasNextPage } = await this.props.fetchPage({
      pageNum,
      queryData,
    });

    if (!_.isEqual(queryData, this.props.queryData)) {
      // props.queryData updated while rendering, abort state updates
      return;
    }

    // Post fetch state updates
    this.mostUpToDateData = this.state.data.concat(pageData);
    this.props.onDataChanged(this.mostUpToDateData);
    this.setState(
      {
        data: this.mostUpToDateData,
        hasNextPage,
        isLoading: false,
      },
      () => {
        this.stateMachineState = STATE_MACHINE_STATES.RENDERING_NEW_PAGE;
      }
    );
  };

  onQueryDataChanged = () => {
    this.pageToFetch = 0;
    this.stateMachineState = STATE_MACHINE_STATES.INITIALIZE;
    this.mostUpToDateData = [];
    this.setState(defaultState(), () => this.fetchPageInternal());
    this.props.onDataChanged([]);
  };

  onScroll = ({ offset }) => {
    this.lastReadOffset = offset;
    if (this.stateMachineState !== STATE_MACHINE_STATES.LISTENING) {
      return;
    }
    if (offset < this.props.threshold) {
      // Sets state to STATE_MACHINE_STATES.FETCHING
      this.fetchPageInternal();
    }
  };

  onRenderDataChanged = () => {
    if (this.stateMachineState !== STATE_MACHINE_STATES.RENDERING_NEW_PAGE) {
      return;
    }
    if (!_.isEqual(this.mostUpToDateData, this.state.data)) {
      return;
    }
    this.stateMachineState = STATE_MACHINE_STATES.LISTENING;
    if (this.lastReadOffset != null) {
      // Call onScroll after we re-enter a LISTENING state with the last read scroll value
      // if this.lastReadOffset != null we know that onScroll was called after fetchPageInternal
      // since we set this.lastReadOffset to null at the start of this function.
      this.onScroll({ offset: this.lastReadOffset });
    }
  };

  renderContents = () => {
    const { renderData, renderEmptyState, renderLoader } = this.props;
    if (!this.state.isLoading && this.state.data.length === 0) {
      return renderEmptyState();
    }
    return (
      <>
        {renderData(this.state.data)}
        {this.state.isLoading && renderLoader()}
      </>
    );
  };

  render() {
    const { queryData } = this.props;
    return (
      <ScrollListener onScroll={this.onScroll}>
        <UseEffect
          effectCallback={this.onQueryDataChanged}
          sensitivityList={[queryData]}
        />
        <UseEffect
          effectCallback={this.onRenderDataChanged}
          sensitivityList={[this.state.data]}
        />
        {this.renderContents()}
      </ScrollListener>
    );
  }
}
