import { useEffect, useState } from "react";
import bg from "../../assets/backgrounds/15.JPG";
import CollectionCard from "./CollectionCard";
import cs from "classnames";
import Loading from "../../components/Loading";
import { ActiveCollections } from "../../graphql/api";
import { collectionMeta } from "../../helpers/collectionInfo";

export default function Collections() {
  const [selected, setSelected] = useState("All");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ActiveCollections(selected).then((ac) => {
      setLoading(false);
      setData(ac);
    });
  }, [selected]);

  return (
    <div
      style={{
        minHeight: "100vh",
      }}
      className="flex gap-10 w-full bg-dark pb-10 flex-col"
    >
      <div style={{ backgroundImage: `url(${bg})` }} className="flex  bg-cover">
        <div className="gap-2 py-14 flex items-center flex-col bg-dark bg-opacity-90 w-full">
          <div className="flex gap-10 w-full self-center flex-col px-8  max-w-7xl">
            <span className="font-bold text-start text-white text-6xl">
              Collections
            </span>
            <span className="text-purple text-lg">
              Browse all the available NFT collections!
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-10 self-center w-full px-8 flex-col max-w-7xl">
        <div className="flex w-full justify-center flex-wrap gap-2">
          {["All", "PFP", "Pixel Art", "Utility", "Domains", "3D"].map((s) => {
            return (
              <button
                onClick={() => setSelected(s)}
                className={cs(
                  "rounded-full w-28 px-2 py-1 border border-purple font-semibold",
                  {
                    "bg-purple text-white": selected === s,
                    "text-purple": selected !== s,
                  }
                )}
              >
                {s}
              </button>
            );
          })}
        </div>
        <div className="flex gap-7 flex-wrap flex-1 ">
          {!loading &&
            data?.length > 0 &&
            data?.map((collection, index) => {
              return (
                <CollectionCard
                  data={{ ...collectionMeta[collection.id], ...collection }}
                  key={index}
                />
              );
            })}
          {!loading && data?.length <= 0 && (
            <span className="flex-1 font-semibold text-2xl text-center">
              There are currently no whitelisted collections
            </span>
          )}
          {loading && <Loading hidePadding={true} />}
        </div>
      </div>
    </div>
  );
}
