import { configureStore } from "@reduxjs/toolkit";
import dataRefreshReducer from "redux/slices/dataRefreshSlice";
import fourOhFourReducer from "redux/slices/fourOhFourSlice";
import stickyHeaderHeightReducer from "redux/slices/stickyHeaderHeightSlice";

export default configureStore({
  reducer: {
    dataRefresher: dataRefreshReducer,
    fourOhFour: fourOhFourReducer,
    stickyHeaderHeight: stickyHeaderHeightReducer,
  },
});
