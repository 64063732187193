import cs from "classnames";
import x from "../assets/components/x.svg";
import { Link } from "react-router-dom";

function MobileHeader({ setShow, setShowWrapModal }) {
  return (
    <div
      className={cs(
        "z-4o0 bg-dark fixed top-0 bottom-0 w-full flex flex-col left-0 right-0"
      )}
    >
      <button
        onClick={() => setShow(false)}
        className="outline-none z-40 self-end justify-self-end pr-8 pt-8 text-xl"
      >
        <img alt="x" className="w-6 cursor-pointer " src={x} />
      </button>
      <div className="flex pt-20 -mt-10 flex-col justify-start h-full items-center">
        <div className="gap-3 pt-8 flex items-center flex-col">
          <Link
            to="/listings"
            className="text-gray text-xl flex items-center h-10 mdd:px-4 px-2 hover:bg-gray hover:bg-opacity-10 font-semibold"
          >
            Explore
          </Link>
          <Link
            to="/collections"
            className="text-gray text-xl flex items-center h-10 px-4 hover:bg-gray  hover:bg-opacity-10 font-semibold"
          >
            Collections
          </Link>
          <Link
            to="/rewards"
            className="text-gray text-xl flex items-center h-10 px-4 hover:bg-gray hover:bg-opacity-10 font-semibold"
          >
            Rewards
          </Link>
          <button
            className="text-gray text-xl flex items-center h-10 px-4 hover:bg-gray hover:bg-opacity-10 font-semibold"
            onClick={() => setShowWrapModal(true)}
          >
            Wrapping Station
          </button>
          <a
            href="https://docs.orbitalapes.com/orbit-marketplace/about"
            className="text-gray text-xl flex items-center h-10 px-4 hover:bg-gray hover:bg-opacity-10 font-semibold"
          >
            Docs
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
