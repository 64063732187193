import { useState } from "react";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import Approve from "../Approve";
import { useApproveERC721 } from "../../hooks/marketTransactions";
import ListingCoinDropdown, {
  options as paytokenDropdownOptions,
} from "../CoinDropdowns/ListingCoinDropdown";
import { useDispatch } from "react-redux";
import { WaitForTransaction } from "graphql/api";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { getTokenName } from "helpers/tokens";
import { parseEther } from "helpers/payTokens";

export default function ListItem({
  data,
  collectionData,
  closeModal,
  erc1155Balance,
  tokensData,
}) {
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState();
  const { erc721Contract, orbitContract, address, erc1155Contract } = useEVM();
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [step, setStep] = useState(0);
  const [payToken, setPayToken] = useState(paytokenDropdownOptions[0]);
  const [erc1155Quantity, setErc1155Quantity] = useState(1);

  const { loading: loadingApprove, approve } = useApproveERC721(data, () => {
    setStep(2);
  });

  async function list() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.listItem(
        data?.nftAddress,
        data?.tokenId,
        data?.isERC1155 ? erc1155Quantity : 1,
        payToken.value,
        parseEther(price, payToken.value)
      );
      const toastId = loadingToast("Listing NFT...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          setStep(3);
          successToast("NFT listed!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function handleApprove() {
    approve();
  }

  async function handleList() {
    const ercNFTContract = data?.isERC1155 ? erc1155Contract : erc721Contract;
    const isApproved = await ercNFTContract?.instance
      ?.attach(data?.nftAddress)
      ?.isApprovedForAll(address, orbitContract?.instance?.address);
    if (!isApproved) {
      setStep(1);
      setTransactionStarted(true);
    } else {
      setStep(2);
      setTransactionStarted(true);
    }
  }

  return (
    <>
      {transactionStarted ? (
        <Approve
          loading={loading || loadingApprove}
          step={step}
          success={"NFT listed!"}
          approve={"Approve"}
          closeModal={closeModal}
          action={"List NFT"}
          applyStep1={handleApprove}
          applyStep2={list}
        />
      ) : (
        <>
          <div className="flex border-gray gap-6 flex-col border-b border-t py-8 justify-between px-10 ">
            <div className="flex gap-1 flex-col">
              <span className="font-bold w-24">Price</span>

              <div className="flex items-center bg-darkGray border justify-center border-darkGray rounded">
                <div className="flex items-center z-50 rounded-l gap-1 w-26 ">
                  <ListingCoinDropdown
                    onChange={setPayToken}
                    value={payToken}
                  />
                </div>
                <input
                  onChange={(e) => setPrice(e.target.value)}
                  min="0"
                  type="number"
                  className="flex-1 outline-none rounded-r bg-darkGray border-gray border-l py-1 pl-2"
                  placeholder="Amount"
                />
              </div>
            </div>
            {data?.isERC1155 && (
              <div className="flex gap-1 flex-col">
                <span className="font-bold">
                  Quantity
                  <span className="text-sm font-light ml-2">
                    (amount of NFTS to list)
                  </span>
                </span>

                <div className="flex items-center bg-darkGray border justify-center border-darkGray rounded">
                  <input
                    value={erc1155Quantity}
                    onChange={(e) => setErc1155Quantity(e.target.value)}
                    min="0"
                    type="number"
                    className="flex-1 outline-none rounded-r bg-darkGray  py-1 pl-2"
                    placeholder="Quantity"
                  />
                </div>
                <span className="self-end text-sm">
                  Balance: {erc1155Balance?.amount}
                </span>
              </div>
            )}

            <div className="flex justify-between">
              <div className="flex items-start gap-3">
                {!data?.bundle && (
                  <div className="w-16 self-center flex items-center flex-col justify-center">
                    <img
                      className="rounded-xl"
                      alt="marketCard"
                      src={data?.image}
                    />
                  </div>
                )}
                <div className="flex flex-col">
                  <span className="font-semibold text-purple">
                    {collectionData?.name}
                  </span>
                  <span className="text-lg font-bold">
                    {getTokenName(data)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <button
              onClick={handleList}
              className="font-bold h-8 z-0 text-white rounded-md w-40 bg-purple"
            >
              {loading || loadingApprove ? "Loading..." : "List"}
            </button>
          </div>
        </>
      )}
    </>
  );
}
