import Step1 from "../assets/icons/Step1.svg";
import Step2 from "../assets/icons/Step2.svg";
import Step2G from "../assets/icons/Step2G.svg";
import cs from "classnames";

export default function Approve({
  approve,
  action,
  success,
  applyStep1,
  applyStep2,
  step,
  loading,
  closeModal,
}) {
  function renderButtonLabel() {
    if (loading) return "Loading..";
    if (step === 1) return approve;
    else if (step === 2) return action;
    else return success;
  }

  return (
    <>
      <div className="flex border-gray gap-6 flex-col border-t py-6 justify-between  ">
        {step === 1 && (
          <span className="text-gray px-10 text-center">
            Your tokens have to be approved first, before submitting your order.
          </span>
        )}
        <div className="flex px-20 justify-between items-center">
          <div
            className={cs("flex items-center flex-col", {
              "animate-pulse": step === 1,
            })}
          >
            <img className="w-10" alt="Step1" src={Step1} />
            <span className={cs("text-purple")}>{approve}</span>
          </div>

          <div
            className={cs("flex items-center flex-col", {
              "animate-pulse": step === 2,
            })}
          >
            <img
              className="w-10"
              alt="Step1"
              src={step >= 2 ? Step2 : Step2G}
            />
            <span
              className={cs({
                "text-darkGray": step === 1,
                "text-purple": step >= 2,
              })}
            >
              {action}
            </span>
          </div>
        </div>
        <div className="flex border-t pt-6 border-gray flex-1 justify-center">
          <button
            disabled={loading}
            className={cs("font-bold h-8 text-white rounded-md w-40", {
              "bg-purple": !loading,
              "bg-purple opacity-40": loading && step <= 2,
            })}
            onClick={() =>
              step > 2 ? closeModal() : step === 1 ? applyStep1() : applyStep2()
            }
          >
            {renderButtonLabel()}
          </button>
        </div>
      </div>
    </>
  );
}
