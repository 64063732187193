import { useState } from "react";
import { handleError } from "../helpers/errors";
import { useEVM } from "./EVMhook";
import { loadingToast, dismissToast, successToast } from "../components/Toasts";
import { ethers } from "ethers";
import { DEFAULT_ERC20 } from "helpers/payTokens";
import { useDispatch } from "react-redux";
import { WaitForTransaction } from "graphql/api";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";

export function useAcceptOffer(data) {
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [transactionStarted, setTransactionStarted] = useState(false);

  const { orbitContract, address, erc721Contract } = useEVM();

  async function acceptOffer() {
    setLoading(true);
    try {
      let transaction;
      if (data?.__typename === "CollectionOffer") {
        transaction = await orbitContract?.instance?.acceptCollectionOffer(
          data.nftAddress,
          data?.tokenId,
          data?.creator
        );
      } else {
        transaction = await orbitContract?.instance?.acceptOffer(
          data.nftAddress,
          data?.tokenId,
          data?.creator
        );
      }

      const toastId = loadingToast("Accepting offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("Offer accepted!");
          setStep(3);
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    setLoading(true);
    try {
      const transaction = await erc721Contract?.instance
        ?.attach(data?.nftAddress)
        ?.setApprovalForAll(orbitContract?.instance?.address, true);
      setLoading(true);
      const toastId = loadingToast("Approving NFTs..");
      erc721Contract?.instance
        ?.attach(data?.nftAddress)
        .provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("NFTS approved!");
          setStep(2);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function handleAcceptOffer() {
    setLoading(true);
    try {
      const isApproved = await erc721Contract?.instance
        ?.attach(data?.nftAddress)
        ?.isApprovedForAll(address, orbitContract?.instance?.address);
      if (!isApproved) {
        setLoading(false);
        setStep(1);
        setTransactionStarted(true);
      } else {
        setLoading(false);
        setStep(2);
        setTransactionStarted(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  return {
    step,
    loading,
    transactionStarted,
    handleAcceptOffer,
    handleApprove,
    acceptOffer,
  };
}

export function useApproveERC20(onTransactionSuccess) {
  const [loading, setLoading] = useState(false);
  const [transactionStarted, setTransactionStarted] = useState(false);
  const { orbitContract, erc20Contract } = useEVM();

  async function approve(tokenAddress = DEFAULT_ERC20, contractAddress) {
    setLoading(true);

    try {
      const _contractAddress = contractAddress
        ? contractAddress
        : orbitContract?.instance?.address;
      const transaction = await erc20Contract?.instance
        ?.attach(tokenAddress)
        .approve(_contractAddress, ethers.utils.parseEther("10000000"));
      const toastId = loadingToast("Approving wevmos...");
      erc20Contract?.instance
        ?.attach(tokenAddress)
        ?.provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("WEVMOS approved!", {
            duration: 4000,
          });
          onTransactionSuccess(2);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function checkApproval(
    address,
    tokenAddress = DEFAULT_ERC20,
    contractAddress
  ) {
    setLoading(true);
    try {
      const _contractAddress = contractAddress
        ? contractAddress
        : orbitContract?.instance?.address;
      const _approvedAmount = await erc20Contract?.instance
        ?.attach(tokenAddress)
        ?.allowance(address, _contractAddress);

      const approvedAmount = ethers.utils.formatEther(_approvedAmount);
      if (approvedAmount <= 10000000 / 2) {
        onTransactionSuccess(1);
      } else {
        onTransactionSuccess(2);
      }
      setLoading(false);
      setTransactionStarted(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return {
    approve,
    checkApproval,
    loading,
    transactionStarted,
  };
}

export function useApproveERC721(data, onApprove) {
  const [loading, setLoading] = useState(false);

  const { orbitContract, erc721Contract, erc1155Contract } = useEVM();

  const ercNFTContract = data?.isERC1155 ? erc1155Contract : erc721Contract;

  async function approve(address) {
    const _nftAddress = address ? address : orbitContract?.instance?.address;
    setLoading(true);

    try {
      const transaction = await ercNFTContract?.instance
        ?.attach(data?.nftAddress)
        ?.setApprovalForAll(_nftAddress, true);
      const toastId = loadingToast("Approving NFTs...");
      ercNFTContract?.instance
        ?.attach(data?.nftAddress)
        .provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("NFTs approved!");
          onApprove();
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  return {
    approve,
    loading,
  };
}

export function useSendItem() {
  const dispatch = useDispatch();
  const { erc721Contract, erc1155Contract } = useEVM();
  const [loading, setLoading] = useState(false);

  async function send(
    destinationAddress,
    tokenAddress,
    tokenId,
    owner,
    onSent
  ) {
    setLoading(true);

    try {
      const transaction = await erc721Contract?.instance
        ?.attach(tokenAddress)
        ?.transferFrom(owner, destinationAddress, tokenId);
      const toastId = loadingToast("Sending NFT...");
      erc721Contract?.instance
        ?.attach(tokenAddress)
        .provider?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          successToast("NFT sent!");
          onSent();
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function send1155(
    destinationAddress,
    tokenAddress,
    tokenId,
    owner,
    quantity,
    onSent
  ) {
    setLoading(true);

    try {
      const transaction = await erc1155Contract?.instance
        ?.attach(tokenAddress)
        ?.safeTransferFrom(owner, destinationAddress, tokenId, quantity, []);
      const toastId = loadingToast("Sending NFT...");
      erc1155Contract?.instance
        ?.attach(tokenAddress)
        .provider?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          successToast("NFT sent!");
          onSent();
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  return { send, loading, send1155 };
}
