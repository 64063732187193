import { createSlice } from "@reduxjs/toolkit";

export const stickyHeaderHeightSlice = createSlice({
  name: "stickyHeaderHeight",
  initialState: {
    stickyHeaderHeight: 0,
  },
  reducers: {
    setStickyHeaderHeight: (state, action) => {
      state.stickyHeaderHeight = action.payload.stickyHeaderHeight;
    },
  },
});

export const { setStickyHeaderHeight } = stickyHeaderHeightSlice.actions;

export default stickyHeaderHeightSlice.reducer;
