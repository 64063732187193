import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Symfoni } from "helpers/env";

import { Toaster } from "react-hot-toast";
import Listing from "./pages/Listing/Listing";
import Market from "./pages/Market/Market";
import Collections from "./pages/Collections/Collections";
import Profile from "./pages/Profile/Profile";
import CollectionDetail from "./pages/CollectionDetail/CollectionDetail";
import Landing from "./pages/Landing/Landing";
import Rewards from "./pages/Rewards/Rewards";
import AOS from "aos";
import { useSelector } from "react-redux";
import FourOhFour from "pages/FourOhFour/FourOhFour";
import Settings from "pages/Settings/Settings";

AOS.init({
  duration: 600,
});

function App() {
  const fourOhFourURL = useSelector((state) => state.fourOhFour.path);
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        document.location.reload();
      });
    }
  }, []);

  function renderApp() {
    return (
      <Symfoni autoInit={false} showLoading={false}>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              background: "#121619",
              border: "1px solid #21262a",
              padding: "16px",
              color: "#fff",
            },
          }}
          position="top-right"
        />

        <Switch>
          {fourOhFourURL != null && (
            <Route exact path={fourOhFourURL}>
              <FourOhFour />
            </Route>
          )}
          <Route exact path="/settings">
            <BaseLayout>
              <Settings />
            </BaseLayout>
          </Route>
          <Route exact path="/listings">
            <BaseLayout>
              <Market />
            </BaseLayout>
          </Route>
          <Route exact path="/rewards">
            <BaseLayout>
              <Rewards />
            </BaseLayout>
          </Route>
          <Route exact path="/collection/:nftaddress">
            <BaseLayout>
              <CollectionDetail />
            </BaseLayout>
          </Route>
          <Route exact path="/profile/:userAddress">
            <BaseLayout>
              <Profile />
            </BaseLayout>
          </Route>
          <Route exact path="/collections">
            <BaseLayout>
              <Collections />
            </BaseLayout>
          </Route>
          <Route exact path="/nft/:collection/:nftId">
            <BaseLayout>
              <Listing />
            </BaseLayout>
          </Route>
          <Route exact path="/">
            <BaseLayout>
              <Landing />
            </BaseLayout>
          </Route>
          <Route exact path="*">
            <FourOhFour />
          </Route>
        </Switch>
      </Symfoni>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
