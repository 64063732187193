import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import { useState } from "react";
import cs from "classnames";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Line,
} from "recharts";
import ReactResizeDetector from "react-resize-detector";
import moment from "moment";
import _ from "lodash";
import { formatEther } from "helpers/payTokens";

export default function PriceHistory({
  data = [],
  openOnMount = false,
  owner,
  address,
}) {
  const [open, setOpen] = useState(openOnMount);

  const sorted = [...data].map((d) => {
    return {
      ...d,
      formattedSellTime: moment(
        new Date(parseInt(d?.sellTime) * 1000).toISOString()
      ).format("D/M"),
      pricePerItem: formatEther(d.pricePerItem, d.payToken),
    };
  });

  const _groupedByDate = _.groupBy(sorted, "formattedSellTime");

  const averageByDate = Object.keys(_groupedByDate).map((key) => {
    let count = 0;
    let avg = 0;
    let sellTime;
    for (let sellPrice of _groupedByDate[key]) {
      avg += parseFloat(sellPrice.pricePerItem);
      count++;
      sellTime = sellPrice?.sellTime;
    }
    return { formattedSellTime: key, sellTime, pricePerItem: avg / count };
  });

  const formatted = averageByDate
    ?.sort((a, b) => parseInt(a?.sellTime) - parseInt(b?.sellTime))
    .map((d) => {
      return {
        price: d.pricePerItem,
        date: d.formattedSellTime,
      };
    });

  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        onClick={() => setOpen(!open)}
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark  cursor-pointer bg-transparent  py-3  px-4",
          {
            "border-b border-darkGray": open,
            "rounded-b-lg": !open,
          }
        )}
      >
        <span className="font-semibold text-white text-lg">Price History</span>
        <img alt="arrow indicator" src={open ? UpArrow : DownArrow} />
      </div>
      {open && (
        <div className="bg-grayDark ">
          <ReactResizeDetector>
            {({ width }) =>
              width > 0 ? (
                <div className="py-3 h-64 overflow-hidden relative">
                  <div className="absolute text-white overflow-hidden">
                    <LineChart
                      width={width}
                      height={250}
                      data={formatted}
                      margin={{
                        top: 5,
                        right: 20,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="date" />
                      <YAxis />
                      <ChartTooltip />
                      <CartesianGrid stroke="#343444" />
                      <Line type="monotone" dataKey="price" stroke="#8454EB" />
                    </LineChart>
                  </div>
                </div>
              ) : (
                <div>{width}</div>
              )
            }
          </ReactResizeDetector>
        </div>
      )}
    </div>
  );
}
