import cs from "classnames";
import Loading from "components/Loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useBundleTokensData } from "helpers/tokens";
import { Carousel } from "react-responsive-carousel";

export default function BundleContents({ token, collectionData }) {
  const tokensData = useBundleTokensData(
    token?.bundle?.tokenIds,
    token?.bundle?.nftAddress
  );
  const isLoading = tokensData.length === 0;

  return (
    <div
      className="rounded-xl border-darkGray border w-full relative"
      style={{ paddingBottom: "100%" }}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0">
        <div className="flex justify-center items-center w-full h-full">
          {isLoading && <Loading hidePadding hideTopPadding />}
          {!isLoading && (
            <div className="flex gap-5  flex-col  h-full">
              <Carousel showThumbs={false}>
                {tokensData.map((nft, index) => (
                  <img
                    key={index}
                    className={cs("rounded-xl h-full w-auto")}
                    alt="market-card"
                    src={nft?.image}
                  />
                ))}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
