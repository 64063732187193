import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { set404Path } from "redux/slices/fourOhFourSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export function useEffectWithDeepEquality(func, sensitivityList) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(func, [JSON.stringify(sensitivityList)]);
}

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  return width;
}

export function useIsMobile() {
  const width = useWindowWidth();
  return width <= 768;
}

export function useMark404() {
  const dispatch = useDispatch();
  const history = useHistory();
  return () => dispatch(set404Path({ path: history.location.pathname }));
}

export function useCallWith404OnFailure(func) {
  const mark404 = useMark404();
  return async (...args) => {
    try {
      return await func(...args);
    } catch (exception) {
      console.error("caught exception from", func, exception);
      mark404();
      throw exception;
    }
  };
}
