import cs from "classnames";

export default function Button({ text, onClick, disabled, noFill }) {
  const classes = {
    fill: "bg-purple text-white",
    noFill: "border text-white hover:text-white border-purple hover:bg-purple",
  };

  return (
    <button
      disabled={disabled}
      className={cs(
        "px-3 font-medium rounded-lg w-full py-1",
        noFill ? classes.noFill : classes.fill,
        {
          "opacity-40": disabled,
          "opacity-100": !disabled,
        }
      )}
      onClick={(event) => {
        onClick(event);
      }}
    >
      {text}
    </button>
  );
}
