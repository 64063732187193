import Filter from "components/filters/Filter";
import Checkbox from "components/basics/Checkbox";

export default function CheckboxFilter({
  filterLabel,
  checkboxLabel,
  defaultOpen,
  value,
  onChange,
}) {
  return (
    <Filter defaultOpen={defaultOpen} label={filterLabel}>
      <div className="flex px-4 gap-4 py-6 flex-col">
        <div class="flex items-center justify-between w-full pr-4">
          <span>{checkboxLabel}</span>
          <Checkbox value={value} onChange={onChange} />
        </div>
      </div>
    </Filter>
  );
}
