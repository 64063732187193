import Trading from "./Trading";
import bg from "../../assets/backgrounds/1.jpg";

export default function Rewards() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="w-full flex flex-col items-center bg-cover"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-dark to-dark via-transparent w-full">
          <div className="gap-2 py-16 flex items-center flex-col bg-dark bg-opacity-60 w-full">
            <div className="flex px-8 flex-col max-w-7xl py-16 pb-20 gap-10 w-full">
              <div className="flex gap-10 flex-col">
                <div className="flex flex-col gap-3">
                  <span className="text-6xl uppercase text-purple font-bold">
                    20% Of Trading Fees
                  </span>
                  <span className="font-semibold text-white text-3xl">
                    Earned by Orbital Ape owners
                  </span>
                </div>
                {/* <span className="text-gray">20% of Orbit platfrom fees are redistributed among all orbital apes holders</span> */}
                <div className="flex gap-6">
                  <a
                    target={"_blank"}
                    href="https://www.orbitalapes.com/mint"
                    rel="noreferrer"
                    className="border-2 text-white border-purple hover:bg-darkGray bg-dark bg-opacity-60 hover:bg-opacity-60 rounded-lg px-3 py-2 font-bold"
                  >
                    Buy Orbital Apes
                  </a>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://docs.orbitalapes.com/orbit-marketplace/about"
                    className=" text-white   bg-purple bg-opacity-60 hover:bg-opacity-60 rounded-lg px-3 py-2 font-bold"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full px-8 bg-dark py-32 items-center flex-col">
          <div className="flex px-8 flex-col max-w-7xl items-center  gap-10 w-full">
            <Trading />
          </div>
        </div>
      </div>
    </>
  );
}
