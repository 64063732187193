import StakeBox from "./StakeBox";
import Orb from "../../assets/icons/WOrb.svg";
import { useEffect, useState } from "react";
import { useEVM } from "../../hooks/EVMhook";
import { DEFAULT_ERC20 } from "../../helpers/payTokens";
import { ethers } from "ethers";
import {
  loadingToast,
  dismissToast,
  successToast,
} from "../../components/Toasts";
import { handleError } from "../../helpers/errors";
import { nFormatter } from "helpers/formatNumber";

export default function Trading() {
  const [totalRewards, setTotalRewards] = useState(0);
  const [userRewards, setUserRewards] = useState(0);
  const [orbitalBalance, setOrbitalBalance] = useState(0);
  const [loading, setLoading] = useState();

  const { rewardsContract, erc721Contract, address } = useEVM();

  useEffect(() => {
    function getInfo() {
      rewardsContract?.instance
        ?.totalRewardsPerERC20(DEFAULT_ERC20)
        .then((res) => {
          if (res) {
            setTotalRewards(
              parseFloat(ethers?.utils?.formatEther(res)) * 10000
            );
          }
        });
      rewardsContract?.instance
        ?.getRewardsBalances(DEFAULT_ERC20)
        .then((res) => {
          if (res) {
            setUserRewards(ethers?.utils?.formatEther(res));
          }
        });
      erc721Contract?.instance
        ?.attach("0x4c275ade386af17276834579b1a68146cef3c770")
        .balanceOf(address)
        .then((res) => {
          if (res) {
            setOrbitalBalance(res.toString());
          }
        });
    }

    if (address) {
      getInfo();
    }
  }, [address, erc721Contract?.instance, rewardsContract?.instance]);

  async function handleClaim() {
    setLoading(true);
    try {
      const transaction = await rewardsContract?.instance?.claimRewards(
        DEFAULT_ERC20
      );
      const toastId = loadingToast("Claiming rewards...");
      rewardsContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("Rewards Claimed!");
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <div className="flex text-white flex-col items-center gap-10 justify-between">
      <div className="flex gap-7">
        <span className="">
          <span className="flex text-3xl flex-wrap gap-1.5 font-bold items-center">
            <img className="h-6  mt-0.5" alt="eth" src={Orb} />
            {nFormatter(totalRewards, 3)} WEVMOS
            <span className="font-medium text-3xl">
              In Platform Fees Earned By Orbital Apes
            </span>
          </span>
        </span>
      </div>
      <StakeBox
        loading={loading}
        onClaim={handleClaim}
        orbitalBalance={orbitalBalance}
        userRewards={userRewards}
      />
    </div>
  );
}
