import Orb from "../../assets/icons/Orb.svg";
import cs from "classnames";
import { useEffect, useState } from "react";
import {
  CollectionStats,
  FloorPrice,
  UserCollectionOffer,
} from "../../graphql/api";
import { ethers } from "ethers";
import ReactTooltip from "react-tooltip";
import CollectionOfferModal from "../../components/CollectionOffer/CollectionOfferModal";
import Socials from "./Socials";
import { nFormatter } from "helpers/formatNumber";

function CollectionStatRow({ label, data, showOrb }) {
  return (
    <div className="flex flex-row md:flex-col justify-between md:items-start items-center md:w-32">
      <span className="text-white">{label}</span>
      <div className="flex text-3xl gap-1.5 font-bold items-center">
        {showOrb && <img className="h-5 mt-0.5" alt="eth" src={Orb} />}
        {data}
      </div>
    </div>
  );
}

export default function CollectionInfo({ data, collection, userAddress }) {
  const [floor, setFloor] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [showCollectionOfferModal, setShowCollectionOfferModal] =
    useState(false);
  const [existingCollectionOffer, setExistingCollectionOffer] = useState(null);

  useEffect(() => {
    if (collection) {
      CollectionStats(collection).then((s) => {
        setLoading(false);
        setStats(s);
      });
      FloorPrice(collection).then((floorPrice) => {
        if (floorPrice) {
          setFloor(ethers?.utils?.formatEther(floorPrice));
        }
      });
      UserCollectionOffer(collection, userAddress).then((collectionOffer) => {
        setExistingCollectionOffer(collectionOffer);
      });
    }
  }, [collection, userAddress]);

  return (
    <div className="flex text-white mdd:flex-row flex-col mt-10 gap-6 md:justify-between px-2 md:px-16 relative z-0">
      {showCollectionOfferModal && (
        <CollectionOfferModal
          offerData={existingCollectionOffer}
          existingCollectionOffer={existingCollectionOffer}
          closeModal={() => setShowCollectionOfferModal(false)}
          floor={floor}
          stats={stats}
          data={data}
        />
      )}

      <div className="flex gap-4 flex-col max-w-md">
        <span className={cs("font-bold  rounded-xl w-96 text-3xl")}>
          {data?.name}
        </span>
        <span className={cs("text-white  rounded-xl w-full text-start")}>
          {data?.description?.length > 150
            ? `${data?.description?.substring(0, 167)}...`
            : data?.description}
        </span>
        <div className="y rounded-xl">
          {data?.socials?.length > 0 && <Socials collectionInfo={data} />}
        </div>
      </div>
      <div className="flex gap-6 md:max-w-2xl flex-1 flex-col">
        {loading && (
          <span
            className="overflow-hidden absolute rounded-xl max-w-lg w-full h-20 bg-darkGray"
            data-placeholder
          />
        )}
        {!loading && (
          <div className="flex flex-col justify-between bg-darkGray border border-gray px-6 py-3 rounded-xl shadow md:gap-6 gap-3">
            <div className="flex flex-col md:flex-row md:gap-6 gap-3 justify-between">
              <CollectionStatRow
                label="Items"
                data={stats?.nftCount || 0}
                showOrb={false}
              />
              <CollectionStatRow
                label="Owners"
                data={nFormatter(stats?.numOwners, 1)}
                showOrb={false}
              />
              <CollectionStatRow
                label="Listings"
                data={nFormatter(stats?.listingsCount, 1)}
                showOrb={false}
              />
            </div>
            <div className="flex flex-col md:flex-row md:gap-6 gap-3 justify-between">
              <CollectionStatRow
                label="Floor Price"
                data={nFormatter(floor, 1)}
                showOrb={true}
              />
              <CollectionStatRow
                label="Volume Traded"
                data={
                  stats?.sellsVolume
                    ? nFormatter(
                        parseFloat(
                          ethers?.utils?.formatEther(stats?.sellsVolume)
                        ),
                        1
                      )
                    : "0"
                }
                showOrb={true}
              />
              <CollectionStatRow
                label="Marketcap"
                data={nFormatter(floor * stats?.nftCount, 2)}
                showOrb={true}
              />
            </div>
          </div>
        )}
        {!loading && (
          <div className="flex items-center self-start md:self-end gap-3">
            <button
              onClick={() => setShowCollectionOfferModal(true)}
              className="px-4  py-2 font-bold text-white rounded-full w-56 bg-purple"
            >
              {existingCollectionOffer
                ? "Update Collection Offer"
                : "Create Collection Offer"}
            </button>
            <ReactTooltip
              aria-haspopup="true"
              place="bottom"
              id="collection-offer"
              effect="solid"
            >
              <div className="flex flex-col">
                <p>
                  Creates the same offer on every item within this collection.
                </p>
                <p>
                  Anyone holding an item of the collection can accept your
                  offer.
                </p>
                <p>Your collection offer can only be accepted by one seller.</p>
              </div>
            </ReactTooltip>
            <button
              data-tip
              data-for="collection-offer"
              className="w-6 leading-3 font-bold h-6 text-lg text-purple rounded-full border-2 border-purple"
            >
              ?
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
