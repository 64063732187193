import { useEffect, useState } from "react";
import { useEVM } from "hooks/EVMhook";
import Modal from "react-modal";
import Button from "components/Button";
import { loadingToast, dismissToast, successToast } from "components/Toasts";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import TokenCard from "components/TokenCard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function getButtonText(isLoading, finishedFlow) {
  if (isLoading) {
    return "Loading...";
  }
  if (finishedFlow) {
    return "Close";
  }
  return "Unpack Bundle";
}

export default function UnbundleModal({
  closeModal,
  onActionClick,
  tokenData,
}) {
  const { orbitBundlesContract } = useEVM();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [finishedFlow, setFinishedFlow] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      setLoading(false);
    }
    fetchData();
  }, []);

  async function unpackBundle(bundleId) {
    try {
      const transaction = await orbitBundlesContract?.instance?.unpackBundle(
        bundleId
      );
      const toastId = loadingToast("Unpacking bundle");
      await orbitBundlesContract?.instance?.provider?.waitForTransaction(
        transaction.hash
      );
      WaitForTransaction(transaction.hash);
      dismissToast(toastId);
      successToast("Bundle unpacked!", {
        duration: 4000,
      });
      setLoading(false);
      setFinishedFlow(true);
      dispatch(incrementTransactionCounter());
    } catch (ex) {
      console.log("caught while creating bundle", ex);
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Create Bundle"
      >
        <div className="bg-dark text-white w-120 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Unpack NFT Bundle
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>

          <>
            <div className="flex border-gray border-t gap-5 py-4 flex-col px-10 ">
              <p className="text-white">
                {!finishedFlow && (
                  <>
                    Clicking "Unpack Bundle" will unpack this bundle and
                    transfer the {tokenData.bundle.tokenIds.length} NFTs it
                    contains to your wallet.
                  </>
                )}
                {finishedFlow && (
                  <>
                    Bundle has been unpacked. The NFTs inside of this bundle
                    have been added to your wallet.
                  </>
                )}
              </p>
              <TokenCard width={400} data={tokenData} profile={true} />
            </div>

            <div className="w-48 text-white py-6 self-center">
              <Button
                disabled={loading}
                onClick={async () => {
                  if (finishedFlow) {
                    closeModal();
                    return;
                  }
                  unpackBundle(tokenData.bundle.bundleId);
                }}
                text={getButtonText(loading, finishedFlow)}
              />
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
}
