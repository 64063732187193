import Elder from "../../assets/landing/ELDER.jpg";
import Orb from "../../assets/icons/Orb.svg";

export default function Powered() {
  return (
    <div className="bg-gradient-to-b py-40 gap-32 from-dark to-dark via-darkGray items-center flex justify-center flex-wrap px-7 w-full">
      <div className="flex gap-3 md:gap-6">
        <img
          data-aos="fade-up"
          alt="elder"
          className="md:w-56 w-44 md:h-56 h-44 rounded-3xl"
          src={Elder}
        />
        <img
          data-aos="fade-down"
          alt="orb"
          className="md:w-56 w-44 md:h-56 h-44 bg-dark p-4 rounded-3xl -mt-12"
          src={Orb}
        />
      </div>
      <div data-aos="fade-left-up" className="flex max-w-md gap-10 flex-col">
        <div className="flex max-w-md gap-5 flex-col">
          <span className="text-white text-4xl font-bold">
            Powered by EVMOS
          </span>
          <span className="text-white">
            Evmos is a scalable and interoperable Ethereum, built on
            Proof-of-Stake with fast-finality.
            <br />
            <br />
            Evmos leverages the Cosmos SDK serves as the first IBC-compatible
            EVM-based chain, bringing composability, interoperability, and fast
            finality to Ethereum.
          </span>
        </div>
        <div className="flex gap-4">
          <a
            href="https://diffusion.fi/"
            className="shadow  w-40 bg-purple font-medium text-center text-lg px-5 py-2 rounded-full text-white"
          >
            Buy $EVMOS
          </a>
          <a
            href="https://evmos.dev/"
            className="shadow w-40  border-2 border-purple font-medium text-center text-lg px-5 py-2 rounded-full text-white"
          >
            Docs
          </a>
        </div>
      </div>
    </div>
  );
}
