import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { UserOffersMade } from "../../../graphql/api";
import MyCollectionOfferRow from "./MyCollectionOfferRow";
import MyOfferRow from "./MyOfferRow";
import { useCallWith404OnFailure } from "helpers/Hooks";

export default function MyOffers({ address }) {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserOffersMade = useCallWith404OnFailure(async (address) => {
    if (address) {
      const fetchedOffers = await UserOffersMade(address);
      setOffers(fetchedOffers);
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchUserOffersMade(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  function renderRows() {
    if (offers.length === 0) {
      return (
        <span className="font-semibold py-8 rounded-t-lg text-lg text-white text-center">
          No offers yet
        </span>
      );
    }
    return offers.map((offer, index) => {
      if (offer?.type === "collection") {
        return <MyCollectionOfferRow data={offer} key={index} />;
      } else {
        return <MyOfferRow data={offer} key={index} />;
      }
    });
  }

  return (
    <>
      {loading && <Loading hidePadding={true} />}
      {!loading && (
        <div className="flex border max-w-4xl rounded-t-lg flex-1 border-darkGray flex-col">
          <div className="flex font-semibold pl-8 rounded-t-lg text-lg text-white py-3 border-b px-4 bg-dark border-darkGray">
            <span className="w-5/12">Item</span>
            <span className="w-3/12">Price</span>
            <span className="w-4/12">Expiration</span>

            <div className="flex w-36" />
          </div>
          <div className="bg-darkGray bg-opacity-20 flex flex-col align-center justify-center">
            {renderRows()}
          </div>
        </div>
      )}
    </>
  );
}
