import Search from "../assets/icons/search.svg";
import Close from "../assets/icons/close.svg";
import { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import Loading from "../components/Loading";
import { search } from "../helpers/search";
import { Link } from "react-router-dom";

export function SearchBarWithoutDropdown({
  value,
  onClick,
  onChange,
  onClose,
}) {
  return (
    <div className="flex flex-1 px-3 items-center py-2 gap-2 bg-darkGray border border-dark rounded-xl">
      <img src={Search} className="w-5 h-5" alt="search" />
      <input
        onClick={onClick}
        value={value}
        onChange={(e) => onChange(e.target.value ?? "")}
        className="px-2 flex-1 text-white placeholder-gray bg-transparent outline-none"
      />
      <button onClick={onClose}>
        <img src={Close} className="w-4 h-4" alt="close" />
      </button>
    </div>
  );
}

export default function SearchBar() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const node = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      // outside click
      if (open) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  const handleSearch = debounce((value) => {
    let result = search(value);
    setData(result);
    setLoading(false);
  }, 750);

  function handleType(value) {
    setSearchValue(value);
    if (value === "") {
      setOpen(false);
      return;
    }
    setOpen(true);
    setLoading(true);
    handleSearch(value);
  }

  function handleClose() {
    setSearchValue("");
    setOpen(false);
  }

  return (
    <div
      ref={node}
      style={{ maxWidth: 770 }}
      className="flex mdd:mr-9 relative flex-1"
    >
      <SearchBarWithoutDropdown
        value={searchValue}
        onClick={() =>
          searchValue !== null && searchValue !== "" ? setOpen(true) : null
        }
        onChange={handleType}
        onClose={() => handleClose}
      />
      {open && (
        <div
          style={{ maxWidth: 770 }}
          className="flex w-full bg-darkGray flex-col absolute mt-12 shadow rounded-xl"
        >
          <div className="text-gray px-3 text-sm py-1.5 font-semibold">
            Collections
          </div>
          {loading && (
            <div className="flex flex-col w-full items-center py-6 justify-start h-48 flex-1">
              <Loading hideTopPadding={true} size="sm" hidePadding={true} />
            </div>
          )}

          {!loading && (
            <div className="flex flex-col pb-2 h-72 overflow-scroll">
              {data.map((collection, index) => (
                <Link
                  to={`/collection/${collection.contract}`}
                  key={index}
                  className="border-t gap-2 font-medium flex items-center py-2.5 px-3 border-dark text-white shadow-sm hover:shadow-md"
                >
                  <img
                    alt="collection"
                    src={collection.imageURL}
                    className="w-6 h-6 border rounded-full"
                  />
                  {collection?.name}
                </Link>
              ))}
            </div>
          )}

          <div className="text-gray px-3 text-sm pb-1.5 pt-0.5 font-semibold">
            Results: {data?.length || 0}
          </div>
        </div>
      )}
    </div>
  );
}
