import moment from "moment";
import { useState } from "react";
import cs from "classnames";
import { useTokenData, getTokenName } from "helpers/tokens";
import { nFormatter } from "helpers/formatNumber";
import Address from "components/Address";
import { BUNDLE_ADDRESS, formatEther } from "helpers/payTokens";
import { Link } from "react-router-dom";

export default function ActivityRow({ data, collectionMeta }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const from = data.__typename === "Sell" ? data?.seller : data?.from;
  const to = data.__typename === "Sell" ? data?.buyer : data?.to;
  const eventTime = data.__typename === "Sell" ? data.sellTime : data.time;
  const meta = useTokenData(
    data?.tokenId,
    data?.nftAddress,
    data?.token?.isERC1155
  );

  function getName() {
    if (data?.isBundle) {
      return `Bundle x${data?.quantity}`;
    } else {
      return getTokenName({ ...meta, ...data, name: data?.token?.name });
    }
  }

  function getImage() {
    if (data?.isBundle) {
      return collectionMeta.imageURL;
    } else {
      return meta?.image;
    }
  }

  function getTokenUrl() {
    if (data?.isBundle) {
      return `/nft/${BUNDLE_ADDRESS}/${data?.tokenId}`;
    } else {
      return `/nft/${data?.nftAddress}/${data?.tokenId}`;
    }
  }

  return (
    <div className="flex border-t border-darkGray flex-col">
      <div className="flex py-3 pl-4 items-center text-white border-b px-4 border-darkGray">
        <div className="w-2/12">{data.type}</div>
        <Link
          to={getTokenUrl()}
          className="w-2/12 flex text-purple items-center gap-1.5"
        >
          {" "}
          {!imageLoaded && (
            <div className="w-9 h-9 rounded-full bg-darkGray animate-pulse" />
          )}
          <img
            onLoad={() => setImageLoaded(true)}
            className={cs("w-9 h-9 rounded-full", {
              hidden: !imageLoaded,
              flex: imageLoaded,
            })}
            src={getImage()}
            alt="item"
          />{" "}
          <span className="">{getName()}</span>{" "}
        </Link>
        <div className="w-2/12">
          {data?.pricePerItem != null &&
            nFormatter(formatEther(data?.pricePerItem, data?.payToken), 2) +
              ` `}
        </div>
        <Link to={`/profile/${from}`} className="w-2/12 text-purple">
          <Address address={from} length={8} />
        </Link>
        <Link to={`/profile/${to}`} className="w-2/12 text-purple">
          <Address address={to} length={8} />
        </Link>
        <div className="w-2/12 text-sm">
          {" "}
          {`${moment(new Date(eventTime * 1000).toISOString()).format(
            "MMMM Do YYYY, h:mm a"
          )}`}
        </div>
      </div>
    </div>
  );
}
