import { SizeMe } from "react-sizeme";

const MAX_CARD_WIDTH = 500;
function getNumberOfCardsForWidth(width) {
  if (width < 484) {
    return 1;
  }
  if (width < 768) {
    return 2;
  }
  if (width < 1200) {
    return 3;
  }
  if (width < 2000) {
    return 4;
  }
  return Math.ceil(width / MAX_CARD_WIDTH);
}

const TAILWIND_SPACING = 5; // padding/gap in tailwind units
export default function TokenWidthMeasuringContainer({ children }) {
  return (
    <div className="w-full">
      <SizeMe monitorWidth refreshRate={32}>
        {({ size: { width } }) => {
          const GAP_WIDTH = TAILWIND_SPACING * 4; // gap-5 === 20px gap
          const PADDING_WIDTH = TAILWIND_SPACING * 4; // p-5 === 20px padding
          const numCardsPerRow = getNumberOfCardsForWidth(width);
          const numGapsPerRow = numCardsPerRow - 1;
          const totalGapWidth = GAP_WIDTH * numGapsPerRow;
          const totalPaddingWidth = PADDING_WIDTH * 2;
          const cardWidth =
            (width - totalGapWidth - totalPaddingWidth) / numCardsPerRow;
          return (
            <div className={`p-${TAILWIND_SPACING}`}>
              <div
                className={`flex flex-wrap overflow-auto gap-${TAILWIND_SPACING}`}
              >
                {children({ cardWidth })}
              </div>
            </div>
          );
        }}
      </SizeMe>
    </div>
  );
}
