import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import { useState } from "react";
import cs from "classnames";
import ERC1155ListingsRow from "./ERC1155ListingsRow";

export default function ERC1155Listings({
  data,
  owner,
  address,
  listings,
  collectionMeta,
  tokenId,
  viewerListing,
  erc1155Balance,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        onClick={() => setOpen(!open)}
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark  cursor-pointer bg-transparent  py-3  px-4",
          {
            "border-b border-darkGray": open,
            "rounded-b-lg": !open,
          }
        )}
      >
        <span className="font-semibold text-white text-lg">Listings</span>
        <img alt="arrow indicator" src={open ? UpArrow : DownArrow} />
      </div>
      {open && (
        <div className="bg-grayDark flex flex-col">
          <div className="flex text-white py-1 border-b px-4 bg-dark border-darkGray">
            <span className="w-3/12">Total price</span>
            <span className="w-3/12">Unit price</span>
            <span className="w-2/12">Quantity</span>
            <span className="w-4/12">From</span>

            <div className="flex w-36" />
          </div>

          <div
            style={{ maxHeight: 190 }}
            className="flex overflow-y-scroll flex-col"
          >
            {listings?.length <= 0 && (
              <span className="text-center text-white py-4">
                No listings yet
              </span>
            )}
            {listings?.length > 0 &&
              listings?.map((listing, index) => {
                return (
                  <ERC1155ListingsRow
                    collectionMeta={collectionMeta}
                    data={data}
                    viewerListing={viewerListing}
                    key={index}
                    listing={listing}
                    erc1155Balance={erc1155Balance}
                    address={address}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
