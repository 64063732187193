import { isProd } from "helpers/env";

const TEST_GRAPH_URL_DEFAULT =
  "https://dev.orbitmarket.io/subgraphs/name/CryptLabsUAE/ApesMarketTestAidan";

const PROD_GRAPH_URL =
  "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitMarket/api";

const TEST_GRAPH_URL = TEST_GRAPH_URL_DEFAULT;

export const GRAPH_URL = isProd ? PROD_GRAPH_URL : TEST_GRAPH_URL;
