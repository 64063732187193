import Place from "../../assets/landing/CamoResident.jpg";
import Elder from "../../assets/landing/BananaHeadphones.jpg";
import EVMOS from "../../assets/placeholders/evmoselder.jpg";

export default function NFTPreview() {
  return (
    <div className="bg-gradient-to-b py-28 bg-dark items-center flex flex-col w-full">
      <div className="flex flex-col gap-14 w-full max-w-7xl">
        <span className="text-center text-white text-3xl font-semibold self-center">
          Evmos NFT Collections
        </span>
        <div className="flex gap-3 mdd:gap-5 items-center justify-center">
          <div
            data-aos="fade-down-right"
            className="flex items-center bg-dark justify-center"
          >
            <img
              className="rounded-xl bg-dark border border-dark flex-1 max-w-mobilexs md:max-w-mxs"
              alt="market-card"
              src={Elder}
            />
            <div className=" h-64 bg-gradient-to-r from-dark via-transparent to-transparent rounded-xl w-full max-w-mobilexs md:max-w-mxs absolute" />
          </div>
          <div data-aos="fade-down" className="">
            <img
              className="rounded-xl max-w-mobilesm md:max-w-lxs flex-1"
              alt="market-card"
              src={EVMOS}
            />
          </div>
          <div
            data-aos="fade-down-left"
            className="flex items-center justify-center"
          >
            <img
              className="rounded-xl border border-dark flex-1 max-w-mobilexs md:max-w-mxs"
              alt="market-card"
              src={Place}
            />
            <div className=" h-64 bg-gradient-to-r from-transparent via-transparent to-dark rounded-xl w-full max-w-mobilexs md:max-w-mxs absolute" />
          </div>
        </div>
        <span
          data-aos="fade-up"
          className="text-center self-center text-white max-w-4xl px-2 text-xl"
        >
          Find the top NFT collections in Evmos! On Orbit every collection is
          verified by our team so you can always be sure you are purchasing the
          correct NFTs!
        </span>
      </div>
    </div>
  );
}
