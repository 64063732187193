import TokenCard from "../../components/TokenCard";
import { ethers } from "ethers";
import { UserTokens, RecentTokens } from "../../graphql/api";
import CheckboxFilter from "components/filters/CheckboxFilter";
import PriceFilter from "components/filters/PriceFilter";
import CollectionFilter from "components/filters/CollectionFilter";
import { CREATED_AT } from "components/sorting/SortingDropdownMenu";
import { useURLState, JSONURLState } from "helpers/URLState";
import SortableFilterableInfiniteScrollingNFTList from "components/SortableFilterableInfiniteScrollingNFTList";
import TokenWidthMeasuringContainer from "components/TokenWidthMeasuringContainer";
import { useState, useEffect } from "react";
import { useCallWith404OnFailure } from "helpers/Hooks";
import AuctionStatusFilter from "components/filters/AuctionStatusFilter";
import { evmosTokenMeta } from "helpers/payTokens";

export default function MyNFTS({
  collections,
  userAddress,
  address,
  pageType,
}) {
  const {
    selectedCollection,
    setSelectedCollection,
    filters,
    setFilters,
    sortingOption,
    setSortingOption,
  } = useURLState({
    selectedCollection: JSONURLState(null),
    filters: JSONURLState({}),
    sortingOption: JSONURLState(CREATED_AT),
  });
  const [searchValue, setSearchValue] = useState("");
  const [priceFilterPayToken, setPriceFilterPayToken] =
    useState(evmosTokenMeta);
  const fetchPage = useCallWith404OnFailure(async (pageNum) => {
    if (userAddress && pageType === "user") {
      return await UserTokens(
        userAddress,
        filters,
        sortingOption?.value,
        searchValue,
        pageNum
      );
    } else if (pageType === "market") {
      return await RecentTokens(
        filters,
        sortingOption?.value,
        searchValue,
        pageNum
      );
    }
  });

  function handleFilterRemove(filter) {
    if (filter.filter === "nftAddress") {
      setSelectedCollection(null);
    }
    const updatedFilters = { ...filters };
    delete updatedFilters[filter.filter];
    if (
      updatedFilters.pricePerItem_gt == null &&
      updatedFilters.pricePerItem_lt == null &&
      updatedFilters.payToken != null
    ) {
      delete updatedFilters["payToken"];
    }
    setFilters(updatedFilters);
  }

  function applyStatusFilter(onlyShowListed) {
    const { listed, ...otherFilters } = filters;
    if (onlyShowListed) {
      setFilters({ ...otherFilters, listed: true });
    } else {
      setFilters(otherFilters);
    }
  }

  function applyPriceFilters({ min, max, payToken }) {
    const updatedFilters = { ...filters, payToken: payToken.address };
    if (min !== 0 && min) {
      updatedFilters["pricePerItem_gt"] = ethers.utils
        .parseEther(min)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_gt"];
    }
    if (max !== 0 && max) {
      updatedFilters["pricePerItem_lt"] = ethers.utils
        .parseEther(max)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_lt"];
    }
    setFilters(updatedFilters);
  }

  function applyCollectionFilter(value) {
    setFilters({ ...filters, ...{ nftAddress: value } });
    setSelectedCollection(value);
  }

  function applyAuctionFilter(state) {
    const { ...otherFilters } = filters;
    const auction_ = state?.filter?.auction_;

    if (state === null) {
      setFilters(otherFilters);
    } else {
      setFilters({ ...otherFilters, auction_ });
    }
  }

  const isPriceSortApplied = sortingOption.value.orderBy === "pricePerItem";
  const isPriceFilterApplied =
    filters.pricePerItem_gt != null || filters.pricePerItem_lt != null;
  useEffect(() => {
    if (isPriceSortApplied) {
      if (filters.payToken !== priceFilterPayToken.address) {
        setFilters({ ...filters, payToken: priceFilterPayToken.address });
      }
    } else if (!isPriceFilterApplied && filters.payToken != null) {
      const updatedFilters = { ...filters };
      delete updatedFilters["payToken"];
      setFilters(updatedFilters);
    }
  }, [
    isPriceSortApplied,
    isPriceFilterApplied,
    filters.payToken,
    priceFilterPayToken.address,
    filters,
    setFilters,
  ]);

  const allFilters = [
    <CheckboxFilter
      filterLabel="Status"
      checkboxLabel="Listed"
      value={filters.listed === true}
      onChange={applyStatusFilter}
      defaultOpen
    />,
    <AuctionStatusFilter
      filterLabel="Auction"
      onChange={applyAuctionFilter}
      defaultOpen
      filter={
        filters?.auction_ ? (filters?.auction_?.startTime_lt ? 1 : 0) : null
      }
    />,
    <PriceFilter
      onApply={applyPriceFilters}
      defaultOpen
      onPayTokenChanged={setPriceFilterPayToken}
    />,
    <CollectionFilter
      onCollectionSelected={applyCollectionFilter}
      collections={collections}
      selectedCollection={selectedCollection}
      userAddress={userAddress}
    />,
  ];

  return (
    <SortableFilterableInfiniteScrollingNFTList
      filters={filters}
      filterComponentList={allFilters}
      handleFilterRemove={handleFilterRemove}
      onSortingOptionChange={setSortingOption}
      sortingOption={sortingOption}
      onChangeSearchValue={setSearchValue}
      renderEmptyState={() => (
        <div className="flex-1 font-semibold text-white text-2xl text-center py-12 px-8">
          There are currently no NFTS listed
        </div>
      )}
      fetchPage={fetchPage}
      sensitivityListForPaginationReset={[
        filters,
        sortingOption,
        pageType,
        userAddress,
        searchValue,
      ]}
      renderData={(data) => (
        <TokenWidthMeasuringContainer>
          {({ cardWidth }) =>
            data?.map((d, index) => (
              <TokenCard
                width={cardWidth}
                address={address}
                profile={true}
                key={`${d.nftAddress}:${d.tokenId}-${index}`}
                data={d}
              />
            ))
          }
        </TokenWidthMeasuringContainer>
      )}
    />
  );
}
