import { createSlice } from "@reduxjs/toolkit";

export const fourOhFourSlice = createSlice({
  name: "404",
  initialState: {
    path: null,
  },
  reducers: {
    set404Path: (state, action) => {
      state.path = action.payload.path;
    },
  },
});

export const { set404Path } = fourOhFourSlice.actions;

export default fourOhFourSlice.reducer;
