import { ethers } from "ethers";
import { useState } from "react";
import Orb from "../../assets/icons/WOrb.svg";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import cs from "classnames";
import { nFormatter } from "helpers/formatNumber";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { getTokenName } from "helpers/tokens";
import { Link } from "react-router-dom";

export default function CancelOffer({
  offerData,
  data,
  collectionData,
  closeModal,
}) {
  const dispatch = useDispatch();
  const { orbitContract } = useEVM();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleCancel() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.cancelOffer(
        offerData.nftAddress,
        offerData?.tokenId
      );
      const toastId = loadingToast("Canceling offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          successToast("Offer canceled!");
          setSuccess(true);
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <>
      {offerData?.__typename !== "CollectionOffer" && (
        <>
          <div className="flex border-gray gap-5 flex-col border-b border-t py-4 justify-between px-10 ">
            <span className="text-gray text-center text-sm">
              It appears you already have an offer. Cancel the offer below, then
              submit a new one!
            </span>
            <div className="flex flex-col">
              <div className="flex items-start justify-between">
                <div className="flex gap-3">
                  {!data?.bundle && (
                    <div className="w-16 self-center flex items-center flex-col justify-center">
                      <img
                        className="rounded-xl"
                        alt="marketCard"
                        src={data?.image}
                      />
                    </div>
                  )}

                  <div className="flex flex-col">
                    <span className="font-semibold text-sm text-purple">
                      {collectionData?.name}
                    </span>
                    <span className="text-lg font-bold">
                      {getTokenName(data)}
                    </span>
                  </div>
                </div>
                <div className="flex mr-1 flex-col">
                  <span className="text-white">Offer</span>
                  <div className="flex text-xl gap-1.5 font-bold items-center">
                    <img className="h-3 mt-0.5" alt="eth" src={Orb} />
                    {nFormatter(
                      ethers?.utils?.formatEther(offerData?.pricePerItem),
                      2
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <button
              disabled={loading}
              onClick={() => (success ? closeModal() : handleCancel())}
              className={cs(
                "font-bold h-8 text-white rounded-md w-36 bg-purple",
                {
                  "opacity-40": loading,
                }
              )}
            >
              {success
                ? "Offer canceled"
                : loading
                ? "Loading..."
                : "Cancel offer"}
            </button>
          </div>
        </>
      )}

      {offerData?.__typename === "CollectionOffer" && (
        <>
          <div className="flex border-gray gap-5 flex-col border-b border-t py-4 justify-between px-10 ">
            <span className="text-white py-8 text-center font-medium">
              This is a collection offer. To update or cancel it, please visit
              this collection page!
            </span>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <Link
              to={`/collection/${offerData?.nftAddress}`}
              className="font-bold h-8 px-3 py-1 text-white rounded-md w-36 bg-purple"
            >
              {"Visit collection"}
            </Link>
          </div>
        </>
      )}
    </>
  );
}
