/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import { SymfoniContext } from "helpers/env";

import { useEffect, useContext, useRef, useState } from "react";
import { chain } from "../hooks/chains";
import { SizeMe } from "react-sizeme";
import { setStickyHeaderHeight } from "redux/slices/stickyHeaderHeightSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function HeaderContent({ isNetwork }) {
  return (
    <div className="flex z-40 flex-col sticky top-0">
      <Header />
      {!isNetwork && (
        <div
          onClick={() => {
            return window.open(
              "https://evmos.dev/guides/keys-wallets/metamask.html",
              "_blank"
            );
          }}
          className="flex-1 px-1 bg-purple flex justify-center text-white h-6 font-semibold text-center text-sm cursor-pointer"
        >
          <span className="hidden text-center  mdd:flex">
            The data shown is from Evmos mainnet network. You are currently not
            connected to Evmos mainnet. To use Orbit install Metamask and
            connect to Evmos mainnet.
          </span>
          <span className="flex text-center mdd:hidden">
            To use Orbit install Metamask and connect to Evmos mainnet.
          </span>
        </div>
      )}
    </div>
  );
}

const CHAINID = chain.chainId;

const _getChainID = (resolve) => {
  if (window.ethereum.chainId != null) {
    resolve(window.ethereum.chainId);
  } else {
    setTimeout(() => _getChainID(resolve), 100);
  }
};
const getChainID = () => new Promise(_getChainID);

function BaseLayout({ children }) {
  const symphony = useContext(SymfoniContext);
  const init = useRef();
  const [isNetwork, setIsNetwork] = useState(true);
  const dispatch = useDispatch();
  const stickyHeaderHeight = useSelector(
    (state) => state.stickyHeaderHeight.stickyHeaderHeight
  );

  useEffect(() => {
    if (window.ethereum && !init.current) {
      setTimeout(async () => {
        const chainId = await getChainID();
        const numberChainId = "9001";

        if (chainId === CHAINID.toString() || chainId === numberChainId) {
          init.current = true;
          symphony.init("web3modal");
        } else {
          setIsNetwork(false);
          init.current = true;
          symphony.init("hardhat");
        }
      }, 250);
    } else if (!init.current) {
      setIsNetwork(false);
      init.current = true;
      symphony.init("hardhat");
    }
  }, []);

  return (
    <div>
      <SizeMe monitorHeight refreshRate={32}>
        {({ size: { height } }) => {
          if (height != null && stickyHeaderHeight !== height) {
            dispatch(setStickyHeaderHeight({ stickyHeaderHeight: height }));
          }
          return <HeaderContent isNetwork={isNetwork} />;
        }}
      </SizeMe>
      <main className="flex bg-dark flex-col items-center">{children}</main>
    </div>
  );
}

export default BaseLayout;
