import { ethers } from "ethers";
import Orb from "../../assets/icons/WOrb.svg";
import WOrb from "../../assets/icons/Orb.svg";
import Datetime from "react-datetime";
import { useEffect, useState } from "react";
import Approve from "../Approve";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { useApproveERC20 } from "../../hooks/marketTransactions";
import { DEFAULT_ERC20 } from "../../helpers/payTokens";
import cs from "classnames";
import { nFormatter } from "helpers/formatNumber";

export default function CreateCollectionOffer({
  collectionData,
  floor,
  closeModal,
  stats,
}) {
  const [listingPrice, setListingPrice] = useState(0);
  const [startTime] = useState(new Date(new Date().getTime() + 2 * 60 * 1000));
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10)
  );
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  const { erc721Contract, orbitContract, address, erc20Contract } = useEVM();

  const isInvalidAmount =
    listingPrice > balance ||
    !listingPrice ||
    listingPrice === "" ||
    listingPrice === undefined;

  const {
    loading: loadingApprove,
    approve,
    checkApproval,
    transactionStarted,
  } = useApproveERC20((step) => {
    setStep(step);
  });

  useEffect(() => {
    if (address) {
      erc20Contract?.instance
        ?.attach(DEFAULT_ERC20)
        .balanceOf(address)
        .then((_balance) => {
          setBalance(parseFloat(ethers.utils.formatEther(_balance)));
        });
    }
  }, [address, erc20Contract?.instance]);

  async function createOffer() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.createCollectionOffer(
        erc721Contract?.instance?.attach(collectionData?.contract).address,
        DEFAULT_ERC20,
        1,
        ethers.utils.parseEther(listingPrice.toString()),
        parseInt(endTime.getTime() / 1000)
      );
      const toastId = loadingToast("Creating offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          setStep(3);
          successToast("Offer created!");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function handleApprove() {
    approve(DEFAULT_ERC20);
  }

  async function handleOffer() {
    if (listingPrice > balance) {
      return;
    }
    checkApproval(address, DEFAULT_ERC20);
  }

  return (
    <>
      {transactionStarted ? (
        <Approve
          step={step}
          success={"Offer created"}
          loading={loading || loadingApprove}
          closeModal={closeModal}
          approve={"Approve"}
          action={"Create offer"}
          applyStep1={handleApprove}
          applyStep2={createOffer}
        />
      ) : (
        <div className="flex border-gray gap-3 flex-col border-t py-6 justify-between  ">
          <span className="text-gray px-10 text-center">
            Creates an offer that can be accepted by any NFT in this collection
          </span>
          <div className="flex px-10 gap-2 flex-col">
            <span className="font-semibold text-lg text-purple">
              {collectionData?.name}
            </span>
            <div className="flex  items-center justify-between flex-1 ">
              <img
                className="rounded-xl w-18 h-18"
                alt="marketCard"
                src={collectionData?.imageURL}
              />
              <div className="flex mr-1 flex-col">
                <span className="text-white font-medium text-lg">Floor</span>
                <div className="flex text-xl gap-1.5 font-bold items-center">
                  <img className="h-3 mt-0.5" alt="eth" src={WOrb} />
                  {floor}
                </div>
              </div>
              <div className="flex mr-1 flex-col">
                <span className="text-white font-medium text-lg">
                  Volume traded
                </span>
                <div className="flex text-xl gap-1.5 font-bold items-center">
                  <img className="h-3 mt-0.5" alt="eth" src={WOrb} />
                  {nFormatter(
                    ethers?.utils?.formatEther(stats?.sellsVolume ?? 0),
                    2
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-6 px-10 flex-col gap-3">
            <div className="flex gap-1 flex-col">
              <span className="font-bold w-24">Amount</span>

              <div className="flex items-center border  border-darkGray rounded">
                <div className="flex items-center bg-darkGray rounded-l gap-1 py-1 w-26 px-2.5">
                  <img className="h-3 mt-0.5" alt="eth" src={Orb} />
                  <span className="font-semibold">WEVMOS</span>
                </div>
                <input
                  value={listingPrice}
                  onChange={(e) =>
                    parseInt(e.target.value) >= 0 || !e.target.value
                      ? setListingPrice(parseFloat(e.target.value))
                      : null
                  }
                  type="number"
                  className="flex-1 outline-none rounded-r bg-transparent border-darkGray border-l py-1 pl-2"
                  placeholder="Amount"
                />
              </div>
            </div>

            <div
              className={cs("text-xs font-bold self-end text-gray", {
                "text-red": listingPrice > balance,
              })}
            >
              Balance: {balance} WEVMOS
            </div>

            <div className="flex justify-between">
              <div className="flex gap-1 text-white flex-col">
                <span className="font-bold w-24">Expiration</span>
                <Datetime
                  value={endTime}
                  className={
                    "calendarAboveInput outline-none border-darkGray bg-dark border rounded-md pl-2 p-0.5 py-1.5 self-end"
                  }
                  onChange={(val) => setEndTime(val.toDate())}
                  inputProps={{
                    onKeyDown: (e) => e.preventDefault(),
                  }}
                  closeOnSelect
                  isValidDate={(cur) =>
                    cur.valueOf() > startTime.getTime() - 1000 * 60 * 60 * 23 &&
                    cur.valueOf() <
                      startTime.getTime() + 31 * 1000 * 60 * 60 * 23
                  }
                />
              </div>
            </div>
          </div>
          <div className="border-t flex justify-center border-gray pt-5 w-full pb-1  self-center">
            <button
              disabled={loading || loadingApprove || isInvalidAmount}
              onClick={handleOffer}
              className={cs(
                "font-bold h-8 text-white rounded-md w-44 bg-purple",
                {
                  "opacity-40": loading || loadingApprove || isInvalidAmount,
                }
              )}
            >
              {loading || loadingApprove
                ? "Loading..."
                : "Create collection offer"}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
