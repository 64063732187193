import { useEffect, useState } from "react";
import { useEVM } from "../hooks/EVMhook";
import Modal from "react-modal";
import Button from "./Button";
import { loadingToast, dismissToast, successToast } from "./Toasts";
import { handleError } from "../helpers/errors";
import { ethers } from "ethers";
import { useApproveERC20 } from "../hooks/marketTransactions";
import Approve from "./Approve";
import { formatEther, payTokenMeta, ZERO_ADDRESS } from "../helpers/payTokens";
import { nFormatter } from "helpers/formatNumber";
import cs from "classnames";
import { WaitForTransaction } from "../graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { getTokenName } from "helpers/tokens";
import { getHighestListing } from "pages/Listing/helpers";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function BuyModal({ closeModal, data, collectionMeta, listings }) {
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [balance, setBalance] = useState(0);
  const dispatch = useDispatch();
  const { orbitContract, address, erc20Contract } = useEVM();

  const listingPrice = parseFloat(
    formatEther(data?.pricePerItem, data?.payToken)
  );
  const quantity =
    data.isERC1155 && listings
      ? getHighestListing(listings).quantity
      : data.isERC1155 && !listings
      ? data?.quantity
      : 1;

  const {
    loading: loadingApprove,
    approve,
    checkApproval,
    transactionStarted,
  } = useApproveERC20((step) => {
    setStep(step);
  });

  useEffect(() => {
    if (address && data) {
      if (data.payToken === ZERO_ADDRESS) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.getBalance(address).then((evmosBal) => {
          setBalance(parseFloat(evmosBal.toString()) / 10 ** 18);
        });
      } else {
        try {
          erc20Contract?.instance
            ?.attach(data?.payToken)
            .balanceOf(address)
            .then((_balance) => {
              setBalance(parseFloat(formatEther(_balance, data?.payToken)));
              console.log("balance buy modal", _balance);
            });
        } catch (error) {
          console.log("crazy error", error);
        }
      }
    }
  }, [address, data, erc20Contract?.instance]);

  async function buy() {
    let owner;
    if (data.isERC1155 && listings) {
      owner = getHighestListing(listings).owner;
    } else {
      owner = data?.owner;
    }
    setLoading(true);
    try {
      let transaction;
      if (data.payToken === ZERO_ADDRESS) {
        transaction = await orbitContract?.instance?.buyItem(
          data?.nftAddress,
          data?.tokenId,
          data.payToken,
          owner,
          {
            value: ethers.utils
              .parseEther(formatEther(data?.pricePerItem, data?.payToken))
              .mul(quantity),
          }
        );
      } else {
        transaction = await orbitContract?.instance?.buyItem(
          data?.nftAddress,
          data?.tokenId,
          data.payToken,
          owner
        );
      }

      const toastId = loadingToast(`Purchasing NFT #${data.tokenId}...`);
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          dismissToast(toastId);
          successToast("Purchase complete!", {
            duration: 4000,
          });
          setLoading(false);
          setStep(3);
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    approve(data?.payToken);
  }

  async function handleBuy() {
    if (data.payToken === ZERO_ADDRESS) {
      buy();
    } else {
      checkApproval(address, data?.payToken);
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              {step > 1 ? "Complete Checkout" : "Confirm Checkout"}
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>

          {transactionStarted && (
            <Approve
              step={step}
              success="Item Purchased!"
              loading={loading || loadingApprove}
              closeModal={closeModal}
              approve={"Approve"}
              action={"Complete Checkout"}
              applyStep1={handleApprove}
              applyStep2={buy}
            />
          )}

          {!transactionStarted && (
            <>
              <div className="flex border-gray border-t py-4 justify-between px-10 ">
                <div className="flex items-center gap-6">
                  {!data?.bundle && (
                    <div className="w-24 self-center flex items-center justify-center">
                      <img
                        alt="marketCard"
                        className="rounded-xl"
                        src={data?.image}
                      />
                    </div>
                  )}

                  <div className="flex gap-1 self-start flex-col">
                    <span className="text-lg text-purple font-semibold">
                      {collectionMeta?.name}
                    </span>
                    <span className=" font-medium">{getTokenName(data)}</span>
                    {data?.isERC1155 && (
                      <span className="text-gray text-sm">
                        Quantity: {quantity}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex px-10 flex-col">
                <div className="flex justify-between flex-1">
                  <span className="text-gray text-sm font-semibold">
                    Platform fee
                  </span>
                  <div className="border-b mb-1 border-gray flex-1 border-dotted mx-1.5" />
                  <span className="text-gray self-end text-sm font-semibold">
                    2.5%
                  </span>
                </div>
                <div className="flex justify-between flex-1">
                  <span className="text-gray text-sm font-semibold">
                    Creator fee
                  </span>
                  <div className="border-b mb-1 border-gray flex-1 border-dotted mx-1.5" />

                  <span className="text-gray self-end text-sm font-semibold">
                    {collectionMeta.royalty
                      ? collectionMeta.royalty / 100
                      : "0"}
                    %
                  </span>
                </div>
              </div>
              <div className="flex  mt-4  px-10  justify-between">
                <span className="font-semibold text-lg">Total</span>
                <div className="flex items-center gap-2">
                  <span className="font-semibold text-lg">
                    {data?.pricePerItem ? nFormatter(listingPrice, 2) : ""}
                  </span>
                  <span className="font-semibold">
                    {payTokenMeta[data.payToken]?.name}
                  </span>
                </div>
              </div>
              <div
                className={cs(
                  "text-xs border-gray mt-2 pb-4 w-full flex px-10 justify-end font-bold border-b self-end text-gray",
                  {
                    "text-red": listingPrice > balance,
                  }
                )}
              >
                Balance: {balance?.toFixed(4)}{" "}
                {payTokenMeta[data?.payToken]?.name}
              </div>
              <div className="w-48 text-white py-6 self-center">
                <Button
                  onClick={handleBuy}
                  disabled={loading || loadingApprove || balance < listingPrice}
                  text={
                    loading || loadingApprove
                      ? "loading..."
                      : "Confirm checkout"
                  }
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default BuyModal;
