import Filter from "components/filters/Filter";
import HorizontalSelector from "components/basics/HorizontalSelector";

export default function AuctionStatusFilter({
  filterLabel,
  checkboxLabel,
  defaultOpen,
  filter,
  onChange,
}) {
  function getFilter(_filter) {
    let currentTime = parseInt(new Date().getTime() / 1000).toString();

    if (_filter === 0) {
      return {
        auction_: { startTime_gt: currentTime },
      };
    } else if (_filter === 1) {
      return {
        auction_: { startTime_lt: currentTime },
      };
    } else {
      return null;
    }
  }

  function handleStateChange(state) {
    let newState = state;
    if (state === filter) newState = null;
    onChange({ filter: getFilter(newState), state: newState });
  }

  return (
    <Filter defaultOpen={defaultOpen} label={filterLabel}>
      <div className="flex   px-3 gap-4 py-6 ">
        <HorizontalSelector
          selectedValue={filter}
          onValueChanged={handleStateChange}
          options={[
            { value: 1, label: "In Progress" },
            { value: 0, label: "Upcoming" },
          ]}
        />
      </div>
    </Filter>
  );
}
