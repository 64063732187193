import Banner from "./Banner";
import CollectionInfo from "./CollectionInfo";
import { useEffect, useState } from "react";
import TokenCard from "components/TokenCard";
import Menu from "./Menu";
import { useParams } from "react-router-dom";
import { CollectionSells, CollectionDailyStats } from "graphql/api";
import cs from "classnames";
import { CollectionTokens, CollectionTraits } from "graphql/api";
import Activity from "components/activity/Activity";
import {
  CREATED_AT,
  RECENTLY_LISTED,
} from "components/sorting/SortingDropdownMenu";
import { collectionMeta } from "helpers/collectionInfo";
import { useEVM } from "hooks/EVMhook";
import { ethers } from "ethers";
import CheckboxFilter from "components/filters/CheckboxFilter";
import PriceFilter from "components/filters/PriceFilter";
import { getListOfTraitFilterComponents } from "components/filters/filterUtils";
import { useURLState, JSONURLState, NumberURLState } from "helpers/URLState";
import SortableFilterableInfiniteScrollingNFTList from "components/SortableFilterableInfiniteScrollingNFTList";
import TokenWidthMeasuringContainer from "components/TokenWidthMeasuringContainer";
import { useCallWith404OnFailure } from "helpers/Hooks";
import AuctionStatusFilter from "components/filters/AuctionStatusFilter";
import Bundles from "./Bundles";
import { DEFAULT_ERC20, evmosTokenMeta, ZERO_ADDRESS } from "helpers/payTokens";
import { isSameAddress } from "helpers/address";

export default function CollectionDetail() {
  const { nftaddress } = useParams();
  const [loadingTraits, setLoadingTraits] = useState(true);
  const [traits, setTraits] = useState([]);
  const { address } = useEVM();
  const {
    activeMenu,
    setActiveMenu,
    filters,
    setFilters,
    sortingOption,
    setSortingOption,
  } = useURLState({
    activeMenu: NumberURLState(0),
    filters: JSONURLState({}),
    sortingOption: JSONURLState(RECENTLY_LISTED),
  });
  const [searchValue, setSearchValue] = useState("");
  const [priceFilterPayToken, setPriceFilterPayToken] =
    useState(evmosTokenMeta);

  const fetchPage = useCallWith404OnFailure(async (pageNum) => {
    return await CollectionTokens(
      nftaddress,
      sortingOption.value,
      searchValue,
      pageNum,
      filters
    );
  });

  function handleMenuChange(id) {
    setActiveMenu(id);
  }

  function applyTraitFilter(traitToApply) {
    let traitFilters = filters?.traits_contains || [];
    let filteredTraitsFilter = traitFilters.filter((f) => {
      let _activeType = f.split(":")[1];
      return _activeType !== traitToApply.type;
    });
    filteredTraitsFilter.push(traitToApply.id);
    traitFilters.traits_contains = filteredTraitsFilter;
    const newFilters = { ...filters, traits_contains: filteredTraitsFilter };
    setFilters(newFilters);
  }

  function updatePriceFilters({ min, max, payToken }) {
    const updatedFilters = { ...filters, payToken: payToken.address };
    if (min !== 0 && min) {
      updatedFilters["pricePerItem_gt"] = ethers.utils
        .parseEther(min)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_gt"];
    }
    if (max !== 0 && max) {
      updatedFilters["pricePerItem_lt"] = ethers.utils
        .parseEther(max)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_lt"];
    }
    setFilters(updatedFilters);
  }

  function handleFilterRemove(filter) {
    const updatedFilters = { ...filters };
    if (filter.filter === "traits_contains") {
      const filteredTraits = updatedFilters?.traits_contains.filter(
        (f) => f !== filter?.value
      );
      updatedFilters.traits_contains = filteredTraits;
    } else {
      delete updatedFilters[filter.filter];
    }
    if (
      updatedFilters.pricePerItem_gt == null &&
      updatedFilters.pricePerItem_lt == null &&
      updatedFilters.payToken != null
    ) {
      delete updatedFilters["payToken"];
    }
    setFilters(updatedFilters);
  }

  function applyAuctionFilter(state) {
    const { ...otherFilters } = filters;
    const auction_ = state?.filter?.auction_;

    if (state === null) {
      setFilters(otherFilters);
    } else {
      setFilters({ ...otherFilters, auction_ });
    }

    setSortingOption(CREATED_AT);
  }

  function handleShowListedChanged(newValue) {
    const updatedFilters = { ...filters };
    if (newValue) {
      updatedFilters.listed = true;
    } else {
      delete updatedFilters["listed"];
    }
    setFilters(updatedFilters);
  }

  const isPriceSortApplied = sortingOption.value.orderBy === "pricePerItem";
  const isPriceFilterApplied =
    filters.pricePerItem_gt != null || filters.pricePerItem_lt != null;

  useEffect(() => {
    if (isPriceSortApplied) {
      const _paytoken = filters?.payToken_in ? filters?.payToken_in[0] : null;
      if (_paytoken !== priceFilterPayToken.address) {
        if (
          isSameAddress(priceFilterPayToken.address, DEFAULT_ERC20) ||
          isSameAddress(ZERO_ADDRESS, priceFilterPayToken.address)
        ) {
          setFilters({
            ...filters,
            payToken_in: [ZERO_ADDRESS, DEFAULT_ERC20],
          });
        } else {
          setFilters({
            ...filters,
            payToken_in: [priceFilterPayToken.address],
          });
        }
      }
    } else if (!isPriceFilterApplied && filters.payToken != null) {
      const updatedFilters = { ...filters };
      delete updatedFilters["payToken"];
      setFilters(updatedFilters);
    }
  }, [
    isPriceSortApplied,
    isPriceFilterApplied,
    filters.payToken,
    priceFilterPayToken.address,
    filters,
    setFilters,
  ]);

  useEffect(() => {
    (async () => {
      setLoadingTraits(true);
      const fetchedTraits = await CollectionTraits(nftaddress);
      setTraits(fetchedTraits);
      setLoadingTraits(false);
    })();
  }, [nftaddress]);
  const checkboxFilter = (
    <CheckboxFilter
      defaultOpen={true}
      filterLabel="Status"
      checkboxLabel="Listed"
      value={filters.listed === true}
      onChange={handleShowListedChanged}
    />
  );
  const auctionFilter = (
    <AuctionStatusFilter
      filterLabel="Auction"
      onChange={applyAuctionFilter}
      defaultOpen
      filter={
        filters?.auction_ ? (filters?.auction_?.startTime_lt ? 1 : 0) : null
      }
    />
  );
  const priceFilter = (
    <PriceFilter
      defaultOpen={true}
      onApply={updatePriceFilters}
      onPayTokenChanged={setPriceFilterPayToken}
    />
  );
  const traitFilters = getListOfTraitFilterComponents({
    traits,
    applyTraitFilter,
    selectedTraits: new Set(filters.traits_contains),
  });
  const allFilters = [
    checkboxFilter,
    auctionFilter,
    priceFilter,
    ...traitFilters,
  ];

  function renderCard(data, index, cardWidth) {
    return (
      <TokenCard
        width={cardWidth}
        address={address}
        profile={true}
        key={`${data.nftAddress}:${data.tokenId}`}
        data={data}
      />
    );
  }

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="flex gap-10 w-full text-white flex-col"
    >
      <Banner data={collectionMeta[nftaddress]} loading={false} />
      <CollectionInfo
        userAddress={address}
        data={collectionMeta[nftaddress]}
        collection={nftaddress}
        loadingDetails={loadingTraits}
      />
      <div className={cs("flex flex-col flex-1 w-full")}>
        <div className="flex justify-center border-b border-darkGray">
          <Menu active={activeMenu} onChange={handleMenuChange} />
        </div>
        {activeMenu === 0 && (
          <SortableFilterableInfiniteScrollingNFTList
            filters={filters}
            filterComponentList={allFilters}
            handleFilterRemove={handleFilterRemove}
            onSortingOptionChange={setSortingOption}
            sortingOption={sortingOption}
            onChangeSearchValue={setSearchValue}
            renderEmptyState={() => (
              <div className="flex-1 font-semibold text-2xl text-center py-20 px-8">
                There are currently no NFTS listed in this collection
              </div>
            )}
            fetchPage={fetchPage}
            sensitivityListForPaginationReset={[
              filters,
              nftaddress,
              sortingOption.value,
              searchValue,
            ]}
            renderData={(data) => (
              <TokenWidthMeasuringContainer>
                {({ cardWidth }) =>
                  data?.map((l, index) => renderCard(l, index, cardWidth))
                }
              </TokenWidthMeasuringContainer>
            )}
          />
        )}
        {activeMenu === 1 && (
          <div className="flex px-2 w-full pt-10 justify-center">
            <Activity
              nftAddress={nftaddress}
              collectionMeta={collectionMeta}
              fetchCollectionDailyStats={async (nftAddress, paytoken) => {
                const collectionDailyStats = await CollectionDailyStats(
                  nftAddress,
                  paytoken
                );
                return collectionDailyStats;
              }}
              fetchActivityPage={async (pageNum) => {
                const activityData = await CollectionSells({
                  address: nftaddress,
                  pageNum,
                });
                return activityData;
              }}
            />
          </div>
        )}
        {activeMenu === 2 && (
          <div className="flex  px-2 w-full pt-10">
            <Bundles
              collection={collectionMeta[nftaddress]}
              collectionAddress={nftaddress}
              address={address}
            />
          </div>
        )}
      </div>
    </div>
  );
}
