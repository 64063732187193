export default function Staking() {
  return (
    <div className="bg-gradient-to-b py-32 gap-16 from-dark to-dark via-dark max-w-7xl  flex items-center flex-wrap flex-col w-full">
      <div
        data-aos="fade-down"
        className="flex px-8 items-center flex-col gap-8 "
      >
        <div className="flex gap-2">
          <span className="font-bold text-white text-3xl">
            A Marketplace For The
          </span>
          <span className="text-purple font-bold text-3xl">Community</span>
        </div>
      </div>
      <div className="flex flex-wrap max-w-5xl flex-1 justify-center gap-6 px-6">
        <div
          data-aos="fade-down"
          className="border max-w-md w-full border-purple text-white flex flex-col rounded-lg gap-4 p-6 bg-darkGray bg-opacity-50"
        >
          <span className="text-white font-semibold text-2xl">
            Platform fees
          </span>
          <span>
            20% of Orbit Platform Fees are redistributed to Orbital Apes owners.
            <br />
            Rewards can be claimed at anytime in your dashboard.
          </span>
        </div>
        <div
          data-aos="fade-down"
          data-aos-delay="300"
          className="border max-w-md w-full border-purple text-white flex flex-col rounded-lg gap-4 p-6 bg-darkGray bg-opacity-50"
        >
          <span className="text-white font-semibold text-2xl">
            Creators royalties
          </span>
          <span>
            NFT Creators earn royalties instantly on their sales!
            <br />
            Creators can choose their royalty fees and receive them directly to
            their wallet!
          </span>
        </div>
        <div
          data-aos="fade-down"
          data-aos-delay="600"
          className="border max-w-md w-full border-purple text-white flex flex-col rounded-lg gap-4 p-6 bg-darkGray bg-opacity-50"
        >
          <span className="text-white font-semibold text-2xl">
            Stay in control
          </span>
          <span>
            On Orbit you always stay in control of your NFTs.
            <br />
            NFTs stay in your wallet until sales are completed!
          </span>
        </div>
        <div
          data-aos="fade-down"
          data-aos-delay="600"
          className="border max-w-md w-full border-purple text-white flex flex-col rounded-lg gap-4 p-6 bg-darkGray bg-opacity-50"
        >
          <span className="text-white font-semibold text-2xl">
            Collection offers
          </span>
          <span>
            Bid on any NFT within a Collection!
            <br />
            Want an Orbital Ape and don't care which one you get? Create a
            collection offer to bid on all of them!
          </span>
        </div>
      </div>
    </div>
  );
}
