import { useEffect, Component } from "react";
import InfiniteScroll from "components/basics/InfiniteScroll";
import { LISTED_ITEMS_PER_PAGE } from "graphql/api";

function deduplicate(dataList) {
  const dataSet = new Set();
  return dataList.filter((d) => {
    const stringifiedData = JSON.stringify(d);
    const isInDataSet = dataSet.has(stringifiedData);
    dataSet.add(stringifiedData);
    return !isInDataSet;
  });
}

function SensitivityListForClassComponent({ effectCallback, sensitivityList }) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(effectCallback, [JSON.stringify(sensitivityList)]);
  return null;
}

function defaultState() {
  return {
    loading: true,
    hasNextPage: true,
    data: [],
    isInitialLoad: true,
  };
}

export default class EasyInfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState();
    this.lastPageFetchPromise = new Promise((resolve) => resolve());
    this.loadMore = this.loadMore.bind(this);
    this.resetCounter = 0;
    this.resetPaginationVariables = this.resetPaginationVariables.bind(this);
    this.hasNextPage = this.hasNextPage.bind(this);
    this.debugLog = this.debugLog.bind(this);
  }

  debugLog(...args) {
    if (this.props.debugName != null) {
      console.log("EasyInfiniteScroll", this.props.debugName + ":", ...args);
    }
  }

  resetPaginationVariables() {
    this.resetCounter = this.resetCounter + 1;
    this.lastPageFetchPromise = new Promise((resolve) => resolve());
    this.setState(defaultState());
    this?.forceLoadPage();
  }

  hasNextPage(fetchedData) {
    if (this.props.hasNextPage) {
      return this.props.hasNextPage(fetchedData);
    }
    return fetchedData.length >= LISTED_ITEMS_PER_PAGE;
  }

  loadMore = async (pageNum) => {
    const toAwait = this.lastPageFetchPromise;
    const thisLoadResetCounter = this.resetCounter;
    this.lastPageFetchPromise = new Promise(async (resolve) => {
      await toAwait;
      if (!this.state.hasNextPage) {
        this.setState({ loading: false });
        return;
      }
      this.setState({ loading: true, isInitialLoad: false });
      // We want to run this in a timeout because this can get called before the
      // useEffect which resets query variables gets triggered. This happens when
      // the GraphQL query is stored in the cache. Adding a timeout ensures the
      // useEffect hook will run first.
      setTimeout(async () => {
        const pageData = await this.props.fetchPage(pageNum);
        if (this.resetCounter !== thisLoadResetCounter) {
          // Reset happened while loading, cancel any state updates
          return resolve();
        }
        this.debugLog("setting data for pageNum", pageNum, {
          data: this.state.data,
          pageData,
        });
        const updatedData = deduplicate([...this.state.data, ...pageData]);
        this.setState(
          {
            data: updatedData,
            loading: false,
            hasNextPage: this.hasNextPage(pageData),
          },
          () => {
            if (this.props.onDataChanged) {
              this.props.onDataChanged(updatedData);
            }
            resolve();
          }
        );
      }, 0);
    });
  };

  render() {
    const {
      // fetchPage,
      renderData,
      renderEmptyState,
      pageStart,
      loader,
      threshold,
      sensitivityListForPaginationReset,
    } = this.props;
    const { loading, hasNextPage, data } = this.state;
    return (
      <>
        <SensitivityListForClassComponent
          effectCallback={this.resetPaginationVariables}
          sensitivityList={sensitivityListForPaginationReset}
        />
        {!loading && data.length <= 0 ? (
          renderEmptyState()
        ) : (
          <InfiniteScroll
            pageStart={pageStart}
            loadMore={this.loadMore}
            initialLoad={this.state.isInitialLoad}
            hasMore={hasNextPage}
            loader={loading ? loader : null}
            threshold={threshold}
            className="w-full"
            forceLoadPageRef={(forceLoadPage) => {
              this.forceLoadPage = forceLoadPage;
            }}
          >
            {renderData(data)}
          </InfiniteScroll>
        )}
      </>
    );
  }
}
