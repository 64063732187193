import { ethers } from "ethers";
import Evmos from "../assets/tokens/EVMOS.svg";
import WEvmos from "../assets/tokens/WEVMOS.svg";
import Atom from "../assets/tokens/atom.png";
import Osmo from "../assets/tokens/osmo.svg";

import { isProd } from "./env";

const TEST_DEFAULT_ERC20 = "0xf75e76e7ff4dd5fe6b43a82c76f84fbfaca77040";

const PROD_DEFAULT_ERC20 = "0xd4949664cd82660aae99bedc034a0dea8a0bd517";

const TEST_BUNDLE_ADDRESS = "0x00526278200ae52428b43B542b3D43A55Fcf3aF8";

const PROD_BUNDLE_ADDRESS = "0x314A761B2992cA891D2974784D10E861D91d7e64";

export const DEFAULT_ERC20 = isProd ? PROD_DEFAULT_ERC20 : TEST_DEFAULT_ERC20;

export const ZERO_ADDRESS = ethers.constants.AddressZero;

const PROD_ATOM_ADDRESS = "0xc5e00d3b04563950941f7137b5afa3a534f0d6d6";

export const ATOM_ADDRESS = PROD_ATOM_ADDRESS;

const PROD_OSMO_ADDRESS = "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864";

export const OSMO_ADDRESS = PROD_OSMO_ADDRESS;

export const BUNDLE_ADDRESS = isProd
  ? PROD_BUNDLE_ADDRESS
  : TEST_BUNDLE_ADDRESS;

function makeTokenMetaAddressToObjectMap(...tokens) {
  let metaAddressToObjectMap = {};
  tokens.forEach((token) => {
    metaAddressToObjectMap[token.address] = token;
  });
  return metaAddressToObjectMap;
}

const testEvmosMeta = {
  address: "0x0000000000000000000000000000000000000000",
  image: Evmos,
  name: "EVMOS",
  class: "h-3",
  erc: false,
  wrapped: false,
};
const testWevmosMeta = {
  address: "0xf75e76e7ff4dd5fe6b43a82c76f84fbfaca77040",
  image: WEvmos,
  name: "WEVMOS",
  class: "h-3",
  erc: true,
  wrapped: true,
};
const testAtom = {
  address: "0xe1c9526b39f2a4e8ee600b77edca83815ad2e5c4",
  image: Atom,
  name: "Atom",
  class: "h-4",
  erc: true,
  wrapped: false,
};
const testPayTokenMeta = makeTokenMetaAddressToObjectMap(
  testEvmosMeta,
  testWevmosMeta,
  testAtom
);

const prodEvmosMeta = {
  address: "0x0000000000000000000000000000000000000000",
  image: Evmos,
  name: "EVMOS",
  class: "h-3",
  erc: false,
  wrapped: false,
};
const prodWevmosMeta = {
  address: "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
  image: WEvmos,
  name: "WEVMOS",
  class: "h-3",
  erc: true,
  wrapped: true,
};
const prodAtomMeta = {
  address: "0xc5e00d3b04563950941f7137b5afa3a534f0d6d6",
  image: Atom,
  name: "ATOM",
  class: "h-4",
  erc: true,
  wrapped: false,
};
const prodOsmoMeta = {
  address: "0xfa3c22c069b9556a4b2f7ece1ee3b467909f4864",
  image: Osmo,
  name: "OSMO",
  class: "h-4",
  erc: true,
  wrapped: false,
};

const prodTokenMeta = makeTokenMetaAddressToObjectMap(
  prodEvmosMeta,
  prodWevmosMeta,
  prodAtomMeta,
  prodOsmoMeta
);

export function parseEther(amount, payToken) {
  if (payToken === ATOM_ADDRESS || payToken === OSMO_ADDRESS) {
    return ethers.utils.parseUnits(amount, 6);
  } else {
    return ethers.utils.parseEther(amount);
  }
}

export function formatEther(amount, payToken) {
  if (payToken === ATOM_ADDRESS || payToken === OSMO_ADDRESS) {
    return ethers.utils.formatUnits(amount, 6);
  } else {
    return ethers.utils.formatEther(amount);
  }
}

export const payTokenMeta = isProd ? prodTokenMeta : testPayTokenMeta;
export const evmosTokenMeta = isProd ? prodEvmosMeta : testEvmosMeta;
