import { UserParticipatedAuctions } from "../../graphql/api";
import { useCallWith404OnFailure } from "helpers/Hooks";
import EasyInfiniteScroll from "components/basics/EasyInfiniteScroll";
import Loading from "components/Loading";
import AuctionRow from "components/Auction/AuctionRow";

export default function MyAuctions({ userAddress }) {
  const fetchUserOffers = useCallWith404OnFailure(
    async (_pageNum) => await UserParticipatedAuctions(userAddress, _pageNum)
  );

  return (
    <div className="flex w-full pt-10 justify-center">
      <div className="flex overflow-x-scroll border max-w-4xl rounded-t-lg flex-1 border-darkGray flex-col">
        <div
          style={{ minWidth: 775 }}
          className="flex font-semibold pl-8 rounded-t-lg text-lg text-white py-3 border-b px-4 bg-dark border-darkGray"
        >
          <span className="w-3/12">Item</span>
          <span className="w-3/12">Reserved Price</span>
          <span className="w-3/12">Highest Bid</span>
          <span className="w-4/12">Highest Bidder</span>
          <span className="w-4/12">Expiration</span>
        </div>
        <div
          style={{ minWidth: 775 }}
          className=" border-2xl flex bg-opacity-20 align-center justify-center"
        >
          <EasyInfiniteScroll
            pageStart={1}
            loader={
              <div className="flex w-full justify-center my-24">
                <Loading hidePadding hideTopPadding />
              </div>
            }
            threshold={800}
            hasNextPage={(fetchedData) => fetchedData.length !== 0}
            renderData={(auctions) => {
              return (
                <div className="flex flex-col w-full">
                  {auctions.map((auction, index) => (
                    <AuctionRow data={auction} key={index} />
                  ))}
                </div>
              );
            }}
            renderEmptyState={() => (
              <div className="font-semibold py-8 rounded-t-lg text-lg text-white">
                You are not participating in any auction
              </div>
            )}
            fetchPage={fetchUserOffers}
            sensitivityListForPaginationReset={[userAddress]}
          />
        </div>
      </div>
    </div>
  );
}
