import { isProd } from "helpers/env";

const chains = {
  EvmosTest: {
    chainId: "0x2328",
    chainName: "Evmos Testnet",
    nativeCurrency: {
      name: "TEvmos",
      symbol: "TEVMOS",
      decimals: 18,
    },
    rpcUrls: ["https://eth.bd.evmos.dev:8545/"],
    blockExplorerUrls: ["https://evm.evmos.dev/"],
  },
  Evmos: {
    chainId: "0x2329",
    chainName: "Evmos",
    nativeCurrency: {
      name: "Evmos",
      symbol: "Evmos",
      decimals: 18,
    },
    rpcUrls: ["https://evmos-mainnet.public.blastapi.io"],
    blockExplorerUrls: ["https://evm.evmos.org/"],
  },
};

export const chain = isProd ? chains.Evmos : chains.EvmosTest;
