import ActivityRow from "./ActivityRow";
import cs from "classnames";
import Loading from "components/Loading";
import EasyInfiniteScroll from "components/basics/EasyInfiniteScroll";

export default function ActivityList({ fetchPage, collectionMeta }) {
  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark border-b border-darkGray  bg-transparent  py-3  px-4"
        )}
      >
        <span className="font-semibold text-white text-lg">Activity</span>
      </div>
      <div
        style={{ maxWidth: "100%" }}
        className="bg-darkGray overflow-x-scroll bg-opacity-0 flex flex-col"
      >
        <div
          style={{ minWidth: 575 }}
          className="flex pl-4 text-white py-1 border-b px-4 bg-dark border-darkGray"
        >
          <span className="w-2/12">Event</span>
          <span className="w-2/12">Item</span>
          <span className="w-2/12">Price</span>
          <span className="w-2/12">From</span>
          <span className="w-2/12">To</span>
          <span className="w-2/12">Date</span>
        </div>
        <EasyInfiniteScroll
          renderData={(data) => (
            <div style={{ minWidth: 575 }} className="flex flex-col">
              {data?.map((ac, key) => (
                <ActivityRow
                  collectionMeta={
                    collectionMeta ? collectionMeta[ac?.nftAddress] : {}
                  }
                  key={key}
                  data={ac}
                />
              ))}
            </div>
          )}
          renderEmptyState={() => (
            <span className="text-center text-white py-8">No activity yet</span>
          )}
          fetchPage={fetchPage}
          sensitivityListForPaginationReset={[]}
          pageStart={1}
          loader={<Loading hidePadding={true} />}
          threshold={400}
        />
      </div>
    </div>
  );
}
