import Dropdown from "react-dropdown";

import RightArrow from "assets/icons/arrow-right-svgrepo-com.svg";

const BG_COLOUR = "bg-darkGray";

const createOption = (option) => ({
  ...option,
  className: `border-t hover:bg-dark cursor-pointer border-dark border-solid mx-2 p-3 ${BG_COLOUR} font-bold`,
  key: option.label,
});

export const PRICE_HIGH_TO_LOW = createOption({
  value: { orderBy: "pricePerItem", orderDirection: "desc" },
  label: "Price: High to Low",
});
export const PRICE_LOW_TO_HIGH = createOption({
  value: { orderBy: "pricePerItem", orderDirection: "asc" },
  label: "Price: Low to High",
});
export const RECENTLY_LISTED = createOption({
  value: { orderBy: "listedTime", orderDirection: "desc" },
  label: "Recently Listed",
});
export const RECENTLY_SOLD = createOption({
  value: { orderBy: "lastSaleTime", orderDirection: "desc" },
  label: "Recently Sold",
});
export const LAST_SALE_PRICE = createOption({
  value: { orderBy: "lastSalePrice", orderDirection: "desc" },
  label: "Highest Last Sale",
});
export const CREATED_AT = createOption({
  value: { orderBy: "createdAt", orderDirection: "desc" },
  label: "Recently Added",
});

export const options = [
  PRICE_HIGH_TO_LOW,
  PRICE_LOW_TO_HIGH,
  RECENTLY_LISTED,
  RECENTLY_SOLD,
  LAST_SALE_PRICE,
  CREATED_AT,
];

export default function SortingDropdownMenu({ value, onChange }) {
  return (
    <div style={{ width: 226, height: 50 }}>
      <div className="inline absolute">
        <Dropdown
          arrowClosed={
            <img
              className="transform origin-center rotate-90"
              alt="arrow"
              src={RightArrow}
            />
          }
          arrowOpen={
            <img
              alt="arrow"
              className="transform origin-center -rotate-90"
              src={RightArrow}
            />
          }
          options={options}
          onChange={onChange}
          value={value}
          className={`border cursor-pointer border-darkGray border-solid rounded ${BG_COLOUR} shadow-lg shadow-black`}
          controlClassName="px-4 py-3 w-56 flex flex-row justify-between"
        />
      </div>
    </div>
  );
}
