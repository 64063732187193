import { ethers } from "ethers";
import Orb from "../../assets/icons/Orb.svg";
import WOrb from "../../assets/icons/WOrb.svg";

import Modal from "react-modal";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { useState } from "react";
import cs from "classnames";
import { nFormatter } from "helpers/formatNumber";
import { ZERO_ADDRESS } from "helpers/payTokens";
import { WaitForTransaction } from "../../graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import { getTokenName } from "helpers/tokens";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#000",
  },
  overlay: {
    backgroundColor: "rgba(0, 0,0, 0.8)",
  },
};

function CancelListing({ closeModal, data, collectionData }) {
  const dispatch = useDispatch();
  const { orbitContract } = useEVM();

  const [loadingCancel, setLoadingCancel] = useState(false);

  async function handleCancel() {
    setLoadingCancel(true);
    try {
      const transaction = await orbitContract?.instance?.cancelListing(
        data?.nftAddress,
        data?.tokenId
      );
      const toastId = loadingToast("Cancelling listing...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoadingCancel(false);
          dismissToast(toastId);
          successToast("Listing cancelled!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoadingCancel(false);
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Cancel Listing
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          <>
            <div className="flex border-gray gap-5 flex-col border-b border-t py-4 justify-between px-10 ">
              <div className="flex justify-between">
                <div className="flex items-start gap-3">
                  {!data?.bundle && (
                    <div className="w-16 self-center flex items-center flex-col justify-center">
                      <img
                        className="rounded-xl"
                        alt="marketCard"
                        src={data?.image}
                      />
                    </div>
                  )}

                  <div className="flex flex-col">
                    <span className="font-semibold text-sm text-purple">
                      {collectionData?.name}
                    </span>
                    <span className="text-lg font-bold">
                      {getTokenName(data)}
                    </span>
                  </div>
                </div>
                <div className="flex mr-1 flex-col">
                  <span className="text-white">Current price</span>
                  <div className="flex text-xl gap-1.5 font-bold items-center">
                    <img
                      className="h-3 mt-0.5"
                      alt="eth"
                      src={data?.payToken === ZERO_ADDRESS ? Orb : WOrb}
                    />
                    {nFormatter(
                      ethers.utils.formatEther(data?.pricePerItem),
                      2
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" pt-3 pb-4 self-center">
              <button
                disabled={loadingCancel}
                onClick={handleCancel}
                className={cs(
                  "font-bold h-8 text-white rounded-md w-40 bg-purple",
                  {
                    "opacity-40": loadingCancel,
                  }
                )}
              >
                Cancel listing
              </button>
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
}

export default CancelListing;
