import { useState } from "react";
import cs from "classnames";
import Place from "../../assets/placeholders/LoadingSolid.jpg";

export default function NFTImage({ data }) {
  const [nftImageLoading, setNFTImageLoading] = useState(true);

  const videoOptions = {
    src: data?.image,
    autoPlay: true,
    muted: true,
    loop: true,
    playsInline: true,
  };

  return (
    <div
      style={{ maxHeight: "30rem" }}
      className=" flex flex-col items-center h-full justify-center w-full text-2xl"
    >
      {nftImageLoading && (
        <img
          className={cs("rounded-xl animate-pulse", {
            hidden: !nftImageLoading,
          })}
          alt="market-card"
          src={Place}
        />
      )}
      <div className="relative">
        {!data?.image?.includes(".mp4") && (
          <img
            className={cs("rounded-xl", {
              hidden: nftImageLoading,
              flex: !nftImageLoading,
            })}
            alt="market-card"
            onLoad={() => setNFTImageLoading(false)}
            src={data?.image}
          />
        )}
        {data?.image?.includes(".mp4") && (
          <video
            type="video/mp4"
            controls
            className={cs("rounded-xl", {
              hidden: nftImageLoading,
              flex: !nftImageLoading,
            })}
            autoPlay={true}
            onLoadedData={() => {
              setNFTImageLoading(false);
            }}
            {...videoOptions}
          />
        )}
      </div>
    </div>
  );
}
