import cs from "classnames";
import { titleCase } from "helpers/strings";
import Filter from "components/filters/Filter";
import { useState } from "react";

export default function TraitFilter({ traits, type, selectedTraits, onApply }) {
  const [search, setSearch] = useState("");

  const _traits = () => {
    if (search !== "" && search !== null) {
      return traits.filter((f) => f.value.toLowerCase().includes(search));
    } else return traits;
  };
  return (
    <Filter label={titleCase(type)}>
      <input
        onChange={(e) => setSearch(e.target.value)}
        className="flex px-4 py-3 m-2 mb-0 rounded bg-darkGray"
        placeholder="Search"
      />
      <div
        style={{ maxHeight: 200 }}
        className="flex overflow-y-scroll py-3 flex-col"
      >
        {_traits()
          .sort((a, b) => a.count - b.count)
          .map((trait, index) => {
            return (
              <div
                onClick={() => onApply(trait)}
                key={trait.id || index}
                className={cs(
                  "flex px-4 py-3 cursor-pointer hover:bg-darkGray justify-between items-center",
                  {
                    "bg-darkGray": selectedTraits.has(trait?.id),
                  }
                )}
              >
                <div className="flex items-center flex-1 justify-between">
                  <span className="text-white">{titleCase(trait.value)}</span>
                  <span>{trait.count}</span>
                </div>
              </div>
            );
          })}
      </div>
    </Filter>
  );
}
