import Place from "../../assets/placeholders/GoldenBoy.jpg";
import Logo from "../../assets/placeholders/logo.png";
import { Link } from "react-router-dom";

export default function Card() {
  return (
    <div className="flex border-2 border-purple rounded-xl max-w-sm ">
      <Link
        to="/collection/0x4c275ade386af17276834579b1a68146cef3c770"
        className="flex gap-4 bg-darkGray flex-col shadow hover:shadow-lg rounded-xl pt-7 pb-5 p-7"
      >
        <img className="rounded-xl" alt="market-card" src={Place} />
        <div className=" flex  items-center justify-between ">
          <span className="font-medium text-2xl text-white ml-2">
            Orbital Apes
          </span>
          <div
            style={{ backgroundColor: "rgb(15,14,15)" }}
            className="w-20 mr-5 p-0.5 h-20 -mt-10 border-4 border-purple  rounded-full"
          >
            <img
              className=" w-full rounded-full"
              alt="collection preview"
              src={Logo}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}
