import { useEffect, useState } from "react";
import { useEVM } from "hooks/EVMhook";
import Modal from "react-modal";
import Button from "components/Button";
import { loadingToast, dismissToast, successToast } from "components/Toasts";
import ShrunkenTokenCard from "./ShrunkenTokenCard";
import { BundleFromCreationTransaction, WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import TokenCard from "components/TokenCard";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function getButtonText(isLoading, isApproved) {
  if (isLoading) {
    return "Loading...";
  }
  if (isApproved) {
    return "Create Bundle";
  }
  return "Approve";
}

export default function BundleCreationModal({
  closeModal,
  onActionClick,
  selectedNFTs,
}) {
  const { address, orbitBundlesContract, erc721Contract } = useEVM();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(null);
  const [createdBundle, setCreatedBundle] = useState(null);
  const [bundleName, setBundleName] = useState("");
  const history = useHistory();
  const nftAddress = selectedNFTs[0].nftAddress;
  const orbitBundlesContractAddress = orbitBundlesContract?.instance?.address;

  useEffect(() => {
    async function fetchData() {
      setIsApproved(null);
      setLoading(true);
      const owner = address;
      const operator = orbitBundlesContractAddress;
      if (owner == null || operator == null || erc721Contract == null) {
        return;
      }
      const isApprovedForAll = await erc721Contract.instance
        .attach(nftAddress)
        .isApprovedForAll(owner, operator);
      setIsApproved(isApprovedForAll);
      setLoading(false);
    }
    fetchData();
  }, [erc721Contract, nftAddress, address, orbitBundlesContractAddress]);

  async function createBundle(tokenIds) {
    setLoading(true);
    let toastId;
    try {
      const transaction = await orbitBundlesContract?.instance?.createBundle(
        nftAddress,
        tokenIds,
        bundleName
      );
      toastId = loadingToast("Creating bundle");
      await orbitBundlesContract?.instance?.provider?.waitForTransaction(
        transaction.hash
      );
      WaitForTransaction(transaction.hash);
      const bundle = await BundleFromCreationTransaction(transaction.hash);
      setCreatedBundle(bundle);
      dismissToast(toastId);
      successToast("Bundle created!", {
        duration: 4000,
      });
      dispatch(incrementTransactionCounter());
    } catch (ex) {
      console.log("caught while creating bundle", ex);
      throw ex;
    } finally {
      setLoading(false);
      if (toastId) {
        dismissToast(toastId);
      }
    }
  }

  async function approveBundleContract() {
    setLoading(true);
    const operator = orbitBundlesContractAddress;
    const transaction = await erc721Contract.instance
      .attach(nftAddress)
      .setApprovalForAll(operator, true);
    const toastId = loadingToast("Approving bundler to create bundle");
    await erc721Contract.instance
      ?.attach(nftAddress)
      .provider?.waitForTransaction(transaction.hash);
    dismissToast(toastId);
    successToast("Bundler approved!", {
      duration: 4000,
    });
    setIsApproved(true);
    setLoading(false);
    dispatch(incrementTransactionCounter());
  }

  const modalContent =
    createdBundle == null ? (
      <>
        <div className="flex border-gray border-t gap-5 py-4 flex-col px-10 ">
          <p className="text-white">
            Clicking "Create Bundle" will create a bundle NFT containing the
            following {selectedNFTs.length} NFTs.
          </p>
          <p className="text-white">
            This bundle NFT can be listed on the marketplace or unpacked at any
            time to get the original NFTs back.
          </p>
          <div className="h-80 overflow-y-scroll">
            <div className="flex flex-wrap gap-5 justify-center">
              {selectedNFTs.map((nft) => (
                <ShrunkenTokenCard token={nft} onClick={() => null} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex px-10 gap-2 font-semibold flex-col">
          <span>
            Enter a name for your bundle
            <span className="text-sm font-light">{" ( optional ):"}</span>
          </span>
          <div className="flex items-center bg-darkGray border justify-center border-darkGray rounded">
            <input
              value={bundleName}
              onChange={(e) => setBundleName(e.target.value)}
              className="flex-1 outline-none rounded-r bg-darkGray  py-1 pl-2"
              placeholder="Bundle name"
            />
          </div>
        </div>
        <div className="w-48 text-white py-6 self-center">
          <Button
            disabled={loading}
            onClick={async () => {
              const tokenIds = selectedNFTs.map((token) => token.tokenId);
              if (!isApproved) {
                await approveBundleContract(tokenIds);
              } else {
                await createBundle(tokenIds);
              }
            }}
            text={getButtonText(loading, isApproved)}
          />
        </div>
      </>
    ) : (
      <>
        <div className="flex border-gray border-t gap-5 py-4 flex-col px-10 ">
          <TokenCard width={400} data={createdBundle?.token} />
        </div>
        <div className="w-48 text-white py-6 self-center">
          <Button
            disabled={loading}
            onClick={async () => {
              history.push(
                `/nft/${createdBundle.token.nftAddress}/${createdBundle.bundleId}`
              );
            }}
            text="Go To Bundle"
          />
        </div>
      </>
    );

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Create Bundle"
      >
        <div className="bg-dark text-white w-120 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Create NFT Bundle
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          {modalContent}
        </div>
      </Modal>
    </div>
  );
}
