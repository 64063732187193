import { useEffect, useState } from "react";
import { ActiveCollections } from "../../graphql/api";
import { collectionMeta } from "../../helpers/collectionInfo";
import MyNFTS from "../Profile/MyNFTS";

export default function Market() {
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    ActiveCollections().then((ac) => {
      setLoading(false);
      setCollections(ac?.map((_ac) => collectionMeta[_ac.id]));
    });
  }, []);

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="flex pb-14 w-full bg-dark bg-opacity-30 flex-col"
    >
      <div className="flex border-t border-darkGray flex-1 gap-5">
        <MyNFTS
          userAddress={null}
          pageType="market"
          loadingCollections={loading}
          collections={collections}
        />
      </div>
    </div>
  );
}
