import {
  Symfoni as MainSymfoni,
  SymfoniContext as MainSymfoniContext,
} from "hardhat/MainSymfoniContext";
import {
  Symfoni as TestSymfoni,
  SymfoniContext as TestSymfoniContext,
} from "hardhat/SymfoniContext";

export const isProd = process.env.REACT_APP_ENV === "prod";

export const Symfoni = isProd ? MainSymfoni : TestSymfoni;
export const SymfoniContext = isProd ? MainSymfoniContext : TestSymfoniContext;
