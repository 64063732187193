import { GRAPH_URL } from "constant/urls";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import mergeOffersAndCollectionOffers from "./helpers";
import { isSameAddress } from "helpers/address";
import { collectionMeta } from "helpers/collectionInfo";
import { BUNDLE_ADDRESS, DEFAULT_ERC20, ZERO_ADDRESS } from "helpers/payTokens";
import { options as paytokenDropdownOptions } from "../components/CoinDropdowns/ListingCoinDropdown";

const _ = require("lodash");

export const LISTED_ITEMS_PER_PAGE = 10;

const apolloClient = new ApolloClient({
  uri: GRAPH_URL,
  cache: new InMemoryCache(),
});

async function executeQuery(query, queryVariables) {
  const result = await apolloClient.query({
    query: gql(query),
    variables: queryVariables,
  });
  return result;
}

function getPaginationVariables(pageNum) {
  const first = LISTED_ITEMS_PER_PAGE;
  const skip = LISTED_ITEMS_PER_PAGE * (pageNum - 1);
  return {
    first,
    skip,
  };
}

const UserAuctionsQuery = `
  query userAuctions($auctionFilter: Auction_filter) {
    auctions(first: 1000, where: $auctionFilter) {
      payToken
      currentBidder
      endTime
      id
      maximumBid
      nftAddress
      owner
      startTime
      reservePrice
      tokenId
    }
  }
`;

const UserTokensInAuctionQuery = `
  query userAuctions($tokenFilter: Token_filter) {
    
    tokens(first: 1000, where: $tokenFilter) {
      tokenId
      nftAddress
      auction {
        payToken
        currentBidder
        endTime
        id
        maximumBid
        nftAddress
        owner
        startTime
        reservePrice
        tokenId
      }
      bundle {
        id
        bundleId
        nftAddress
        tokenIds
        name
      }
    }
    
  }
`;

export const UserTokensInAuction = async (address) => {
  let auctionsRes = await executeQuery(UserTokensInAuctionQuery, {
    tokenFilter: {
      auction_: { owner: address },
    },
  });

  return auctionsRes.data.tokens;
};

const CollectionBundlesQuery = `
  query collectionBundles($tokenFilter: Token_filter) {
    tokens(first: 1000, where: $tokenFilter) {
        id
        owner
        listed
        tokenId
        isERC1155
        nftAddress
        pricePerItem
        listedTime
        lastSaleTime
        lastSalePrice
        payToken
        lastSalePayToken
        name
        auction {
          payToken
          currentBidder
          endTime
          id
          maximumBid
          nftAddress
          owner
          startTime
          reservePrice
          tokenId
        }
        bundle {
          id
          bundleId
          nftAddress
          tokenIds
          name
        }
      }
  }
`;

export const CollectionBundles = async (address) => {
  let res = await executeQuery(CollectionBundlesQuery, {
    tokenFilter: {
      bundle_: {
        nftAddress: address,
        active: true,
      },
    },
  });

  return res.data.tokens;
};

const BundleFromCreationTransactionQuery = `
  query bundlesFromCreationTransaction($creationTransaction: String) {
    bundles(where: {creationTransaction: $creationTransaction}) {
      id
      bundleId
      nftAddress
      name
      tokenIds
      creationTransaction
      token {
        id
        owner
        listed
        tokenId
        isERC1155
        nftAddress
        pricePerItem
        listedTime
        lastSaleTime
        lastSalePrice
        payToken
        lastSalePayToken
        name
      }
    }
  }
`;

export const BundleFromCreationTransaction = async (transactionHash) => {
  let res = await executeQuery(BundleFromCreationTransactionQuery, {
    creationTransaction: transactionHash,
  });

  return res.data.bundles[0];
};

const SingleTokenQuery = `
  query userTokens($collectionAddress: String, $tokenId: BigInt) {
    tokens(where: {nftAddress: $collectionAddress, tokenId: $tokenId}) {
      id
      owner
      tokenId
      isERC1155
      listed
      nftAddress
      pricePerItem
      name
    }
  }
`;

export const SingleToken = async (collectionAddress, tokenId) => {
  const res = await executeQuery(SingleTokenQuery, {
    collectionAddress,
    tokenId,
  });
  return res.data.tokens[0];
};

export const UserAuctions = async (address) => {
  let auctionsRes = await executeQuery(UserAuctionsQuery, {
    auctionFilter: {
      owner: address,
    },
  });

  return auctionsRes.data.auctions;
};

const UserTokensQuery = `
  query userTokens($auctionFilter: Auction_filter, $tokenFilter: Token_filter, $first: Int, $skip: Int, $orderBy: BigInt, $orderDirection: String) {
    tokens(first: $first, skip: $skip, where: $tokenFilter, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      owner
      tokenId
      listed
      nftAddress
      pricePerItem
      isERC1155
      lastSalePrice
      payToken
      lastSalePayToken
      name
      auction {
        payToken
        currentBidder
        endTime
        id
        maximumBid
        nftAddress
        owner
        startTime
        reservePrice
        tokenId
      }
      bundle {
        bundleId
        nftAddress
        tokenIds
      }
    }
  }
`;

export const UserTokens = async (
  address,
  filters = {},
  sorting = {},
  searchQuery,
  page = 1,
  excludeAuctions
) => {
  const first = LISTED_ITEMS_PER_PAGE;
  const skip = LISTED_ITEMS_PER_PAGE * (page - 1);

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let objs;

  if (filters.auction_) {
    filters.auction_ = { ...filters.auction_, owner: address };
  } else {
    objs = {
      owners_contains: [address],
    };
  }

  if (sorting?.orderBy === "pricePerItem") {
    objs["listed"] = true;
  }

  if (searchQuery !== "") {
    objs["nameLowerCase_contains"] = searchQuery.toLowerCase();
  }

  const tokenFilter = { ...objs, ...filters };

  const res = await client.query({
    query: gql(UserTokensQuery),
    variables: {
      tokenFilter,
      first,
      skip,
      ...sorting,
      auctionFilter: {
        owner: address,
        // endTime_gt: 0,
      },
    },
  });

  let auctions = [];
  if (page === 1 && !filters.auction_ && !excludeAuctions) {
    auctions = await UserTokensInAuction(address);
  }

  return [...auctions, ...res?.data?.tokens];
};

const UserTokensByCollectionQuery = `
  query userTokens($address: String, $collectionAddress: String) {
    tokens(where: {owner: $address, nftAddress: $collectionAddress}) {
      id
      owner
      tokenId
      isERC1155
      listed
      nftAddress
      pricePerItem
      name
    }
  }
`;

const UserTokensListedByCollectionQuery = `
  query userTokens($address: String, $listed: Boolean, $collectionAddress: String) {
    tokens(where: {owner: $address, listed: $listed, nftAddress: $collectionAddress}) {
      id
      owner
      tokenId
      isERC1155
      listed
      nftAddress
      pricePerItem
      name
    }
  }
`;

export const UserTokensByCollection = async (
  address,
  listed,
  collectionAddress
) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let objs = {
    address,
    collectionAddress,
  };

  if (listed) {
    objs["listed"] = true;
  }

  const res = await client.query({
    query: gql(
      listed ? UserTokensListedByCollectionQuery : UserTokensByCollectionQuery
    ),
    variables: objs,
  });

  return res?.data?.tokens;
};

const CollectionTokensQuery = `
  query userTokens($nftAddress: String, $first: Int, $skip: Int, $tokenFilter: Token_filter, $orderBy: BigInt, $orderDirection: String) {
    tokens(first: $first, skip: $skip, where: $tokenFilter,  orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      owner
      listed
      tokenId
      isERC1155
      nftAddress
      pricePerItem
      listedTime
      lastSaleTime
      lastSalePrice
      payToken
      lastSalePayToken
      name
      auction {
        payToken
        currentBidder
        endTime
        id
        maximumBid
        nftAddress
        owner
        startTime
        reservePrice
        tokenId
      }
    }
  }
`;

export const CollectionTokens = async (
  nftAddress,
  sorting = {},
  searchQuery,
  page = 1,
  filters = {}
) => {
  const { orderBy, orderDirection } = sorting;
  const first = LISTED_ITEMS_PER_PAGE;
  const skip = LISTED_ITEMS_PER_PAGE * (page - 1);

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let tokenFilter = {
    ...filters,
    nftAddress,
  };

  if (orderBy === "pricePerItem") {
    tokenFilter["listed"] = true;
  }

  if (searchQuery !== "") {
    tokenFilter["nameLowerCase_contains"] = searchQuery.toLowerCase();
  }

  const res = await client.query({
    query: gql(CollectionTokensQuery),
    variables: {
      tokenFilter,
      first,
      skip,
      orderBy,
      orderDirection,
    },
  });

  return res?.data?.tokens;
};

const TokenOffersQuery = `
  query tokenOffers($offersFilter: Offer_filter, $collectionOffersFilter: CollectionOffer_filter,  $first: Int, $skip: Int ) {
    collectionOffers( first: $first, skip: $skip, where: $collectionOffersFilter, orderBy: pricePerItem, orderDirection: desc) {
      id
      creator
      nftAddress
      createdAt
      quantity
      payToken
      pricePerItem
      deadline
      createdAt
    }
    offers( first: $first, skip: $skip, where: $offersFilter, orderBy: pricePerItem, orderDirection: desc) {
      id
      creator
      nftAddress
      createdAt
      tokenId
      quantity
      payToken
      pricePerItem
      deadline
    }
  }
`;

// with listingPrice we can maybe remove listing
const ERC1155BalanceQuery = `
  query erc1155Balance($erc1155BalanceId: String) {
    erc1155Balance(id: $erc1155BalanceId) {
      id
      amount
      owner
    }
  }
`;

export const ERC1155Balance = async (nftAddress, tokenId, address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(ERC1155BalanceQuery),
    variables: {
      erc1155BalanceId: `${nftAddress}:${tokenId}:${address}`,
    },
  });

  return res?.data?.erc1155Balance;
};

const TokenDetailQuery = `
  query tokenDetail($auctionFilter: Auction_filter, $offerId: String, $tokenId: String, $id: String, $sellsFilter: Sell_filter, $nftAddress: String) {
    token(id: $id, where: {tokenId: $tokenId, nftAddress: $nftAddress}) {
      id
      owner
      tokenId
      nftAddress
      listed
      pricePerItem
      isERC1155
      payToken
      lastSalePayToken
      name
      erc1155Info {
        id
        supply
        owners
      }
      traits {
        value
        type
        count
      }
      bundle {
        id
        bundleId
        name
        nftAddress
        tokenIds
      }
    }
    sells(first: 1000, where: $sellsFilter) {
      id
      seller
      buyer
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      sellTime
    }
    collection(id: $nftAddress) {
      id
      royalty
      creator
      feeReceipient
      collectionSummary {
        nftCount
      }
    }
    offer(id: $offerId) {
      id
      creator
      nftAddress
      createdAt
      tokenId
      quantity
      payToken
      pricePerItem
      deadline
    }
    auctions(where: $auctionFilter) {
      payToken
      currentBidder
      endTime
      id
      maximumBid
      nftAddress
      owner
      startTime
      reservePrice
      tokenId
    }
  }
`;

const TokenListingsQuery = `
  query tokenListings($listingFilter: Listing_filter, $first: Int) {
    listings(first: $first, where: $listingFilter, orderBy: pricePerItem) {
      id
      owner
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      active
    }
  }
`;

const UserParticpatedAuctionsQuery = `
  query userparticipatedAuctions($auctionFilter: Auction_filter, $first: Int, $skip: Int) {
    auctions(where: $auctionFilter, first: $first, skip: $skip,) {
      payToken
      currentBidder
      endTime
      id
      maximumBid
      nftAddress
      owner
      startTime
      reservePrice
      tokenId
    }
  }
`;

export const UserParticipatedAuctions = async (address, page) => {
  const first = LISTED_ITEMS_PER_PAGE;
  const skip = LISTED_ITEMS_PER_PAGE * (page - 1);
  let auctionsRes = await executeQuery(UserParticpatedAuctionsQuery, {
    first,
    skip,
    auctionFilter: {
      bidders_contains: [address],
    },
  });

  return auctionsRes.data.auctions;
};

export const TokenListings = async (tokenId, nftAddress, isERC1155) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let res = await client.query({
    query: gql(TokenListingsQuery),
    variables: {
      listingFilter: { active: true, tokenId: tokenId, nftAddress },
      first: isERC1155 ? 1000 : 1,
    },
  });

  return res?.data?.listings;
};

export const TokenOffers = async (tokenId, nftAddress, owner, page) => {
  const time = parseInt(new Date().getTime() / 1000);

  let offerRes = await executeQuery(TokenOffersQuery, {
    ...getPaginationVariables(page),
    offersFilter: {
      deadline_gt: time,
      tokenId: tokenId,
      nftAddress,
      creator_not: owner,
    },
    collectionOffersFilter: {
      deadline_gt: time,
      creator_not: owner,
      nftAddress,
    },
  });

  const offerData = { ...offerRes?.data };

  const offers = mergeOffersAndCollectionOffers(offerData);
  return offers;
};

export const TokenDetail = async (tokenId, nftAddress, viewerAddress) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });
  const time = parseInt(new Date().getTime() / 1000);

  let res = await client.query({
    query: gql(TokenDetailQuery),
    variables: {
      listingFilter: { active: true, tokenId: tokenId, nftAddress },
      sellsFilter: {
        tokenId,
        nftAddress,
      },
      id: `${nftAddress}:${tokenId}`,
      tokenId,
      nftAddress,
      offerId: `${nftAddress}:${viewerAddress}:${tokenId}`,
      auctionFilter: {
        id: `${nftAddress}:${tokenId}`,
      },
    },
  });

  let auction;
  if (res?.data?.auctions) {
    auction = res?.data?.auctions[0];
  }

  let offerRes = await client.query({
    query: gql(TokenOffersQuery),
    variables: {
      first: 1,
      skip: 0,
      offersFilter: {
        deadline_gt: time,
        tokenId: tokenId,
        nftAddress,
        creator_not: res?.data?.token?.owner,
      },
      collectionOffersFilter: {
        deadline_gt: time,
        creator_not: res?.data?.token?.owner,
        nftAddress,
      },
    },
  });

  const data = { ...res?.data };
  const offerData = { ...offerRes?.data };

  if (data?.listings?.length > 0) {
    data.listing = res?.data?.listings[0];
  }

  if (
    data?.offer &&
    (data?.offer?.deadline < time ||
      isSameAddress(data?.offer?.creator, res?.data?.token?.owner))
  ) {
    data.offer = null;
  }

  const offers = mergeOffersAndCollectionOffers(offerData);

  return {
    ...data,
    offers,
    viewerOffer: data?.offer,
    auction: auction ? auction : null,
  };
};

const ListingsQuery = `
  query listedTokens($listingFilter: Listing_filter) {
    listings(where: $listingFilter) {
      id
      owner
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      active
    }
  }
`;

const RecentTokensQuery = `
  query recentTokens($tokenFilter: Token_filter, $first: Int, $skip: Int, $orderBy: BigInt, $orderDirection: String) {
    tokens(first: $first, skip: $skip, where: $tokenFilter ,orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      owner
      tokenId
      listed
      nftAddress
      pricePerItem
      lastSalePrice
      payToken
      lastSalePayToken
      isERC1155
      name
      auction {
        payToken
        currentBidder
        endTime
        id
        maximumBid
        nftAddress
        owner
        startTime
        reservePrice
        tokenId
      }
    }
  }
`;

export const Listings = async (filters) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(ListingsQuery),
    variables: {
      listingFilter: { ...filters, active: true },
    },
  });
  return res?.data?.listings;
};

export const RecentTokens = async (filters, sorting, searchQuery, page) => {
  const first = LISTED_ITEMS_PER_PAGE;
  const skip = LISTED_ITEMS_PER_PAGE * (page - 1);

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let _tokenFilter = { ...filters };

  if (sorting?.orderBy === "pricePerItem") {
    _tokenFilter["listed"] = true;
  }

  if (searchQuery !== "") {
    _tokenFilter["nameLowerCase_contains"] = searchQuery.toLowerCase();
  }

  const tokenFilter = {
    ..._tokenFilter,
    nftAddress_not_in: [BUNDLE_ADDRESS.toLowerCase()],
  };

  const res = await client.query({
    query: gql(RecentTokensQuery),
    variables: {
      tokenFilter,
      first,
      skip,
      ...sorting,
    },
  });
  return res?.data?.tokens;
};

const TokenActivityQuery = `
  query tokenActivity($tokenId: String, $nftAddress: String, $first: Int, $skip: Int, $orderBy: BigInt, $orderDirection: String) {
    tokenTransfers(where: {tokenId: $tokenId, nftAddress: $nftAddress}, first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      nftAddress
      tokenId
      from
      to
      transferType
      pricePerItem
      createdAt
      token {
        name
        isERC1155
      }
    }
  }
`;

export const TokenActivity = async (
  tokenId,
  nftAddress,
  pageNum,
  previousPageLastData // need to deduplicate some data because we have two TokenTransfer events for each sell, one with transferType == Sell, another with transferType == Transfer
) => {
  const queryVariables = {
    tokenId,
    nftAddress,
    orderBy: "createdAt",
    orderDirection: "desc",
    ...getPaginationVariables(pageNum),
  };
  const res = await executeQuery(TokenActivityQuery, queryVariables);

  // For every Sale we also have a Transfer since both are emitted on sale so we need to deduplicate them
  const transfersUnduplicated = (res?.data?.tokenTransfers || []).map(
    (transfer) => ({
      ...transfer,
      type: transfer.transferType,
      time: transfer.createdAt,
    })
  );
  return transfersUnduplicated; // TODO deduplicate
};

const UserSellActivityQuery = `
  query userSellActivity($address: String ) {
    sells(where: {seller: $address}) {
      id
      seller
      buyer
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      sellTime
      token {
        name
        isERC1155
      }
    }
  }
`;

const UserBuyActivityQuery = `
  query userBuyActivity($address: String ) {
    sells(where: {buyer: $address}) {
      id
      seller
      buyer
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      sellTime
      token {
        name
        isERC1155
      }
    }
  }
`;

export const UserActivity = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const sales = await client.query({
    query: gql(UserSellActivityQuery),
    variables: {
      address,
    },
  });

  const buys = await client.query({
    query: gql(UserBuyActivityQuery),
    variables: {
      address,
    },
  });

  let activity = [];

  if (sales?.data?.sells?.length > 0) {
    let _activity = sales?.data?.sells.map((tT) => {
      return { ...tT, type: "Sell" };
    });
    activity = [...activity, ..._activity];
  }

  if (buys?.data?.sells?.length > 0) {
    let _activity = buys?.data?.sells.map((tT) => {
      return { ...tT, type: "Buy" };
    });
    activity = [...activity, ..._activity];
  }

  return activity;
};

const UserTransactionActivityQuery = `
  query userTransactionActivity($address: String, $first: Int, $skip: Int) {
    sells(where: {transactors_contains: $address}, orderBy: sellTime, orderDirection: desc, first: $first, skip: $skip) {
      id
      seller
      buyer
      nftAddress
      tokenId
      isBundle
      quantity
      payToken
      pricePerItem
      sellTime
      token {
        name
        isERC1155
      }
    }
  }
`;

export const UserTransactionActivity = async ({ address, pageNum }) => {
  const queryVariables = {
    address,
    ...getPaginationVariables(pageNum),
  };
  const res = await executeQuery(UserTransactionActivityQuery, queryVariables);
  return (res?.data?.sells || []).map((sell) => {
    const type = sell.buyer === address ? "Buy" : "Sell";
    return { ...sell, type };
  });
};

const CollectionDailyStatsQuery = `
  query collectionDailyStats($collectionDailyStatFilter: CollectionDailyStat_filter ) {
    collectionDailyStats(first: 1000, where: $collectionDailyStatFilter) {
      id
      nftAddress
      volume
      count
      timestamp
      numOwners
    }
  }
`;

export const CollectionDailyStats = async (address, payToken) => {
  let obj = { nftAddress: address };
  if (payToken.value === ZERO_ADDRESS || !payToken) {
    obj["payToken_not_in"] = paytokenDropdownOptions
      .filter((o) => o.value !== ZERO_ADDRESS && o.value !== DEFAULT_ERC20)
      .map((o) => o.value);
  } else {
    obj.payToken = payToken.value;
  }

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(CollectionDailyStatsQuery),
    variables: {
      collectionDailyStatFilter: obj,
    },
  });

  return res?.data?.collectionDailyStats;
};

const CollectionSellsQuery = `
  query collectionActivity($address: String, $first: Int, $skip: Int, $orderBy: BigInt, $orderDirection: String) {
    sells(where: {nftAddress: $address}, first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      seller
      buyer
      nftAddress
      tokenId
      quantity
      payToken
      pricePerItem
      isBundle
      sellTime
      token {
        name
        isERC1155
      }
    }
  }
`;

export const CollectionSells = async ({ address, pageNum }) => {
  const queryVariables = {
    address,
    orderBy: "sellTime",
    orderDirection: "desc",
    ...getPaginationVariables(pageNum),
  };
  const res = await executeQuery(CollectionSellsQuery, queryVariables);

  const activity = (res?.data?.sells || []).map((sell) => ({
    ...sell,
    type: "Sell",
  }));
  return activity;
};

const ListedItemsQuery = `
  query market($address: String) {
    collectionSummary(id: $address) {
      id
      listingsCount
      sellsCount
      sellsVolume
      nftCount
      numOwners
    }
  }
`;

export const CollectionStats = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(ListedItemsQuery),
    variables: {
      address,
    },
  });
  return res?.data?.collectionSummary;
};

const ERC721BalanceQuery = `
  query balance($id: String) {
    erc721Balances(where: {id: $id}) {
      amount
    }
  }
`;

export const ERC721Balance = async (collectionAddress, userAddress) => {
  const id = collectionAddress.toLowerCase() + ":" + userAddress.toLowerCase();
  const res = await executeQuery(ERC721BalanceQuery, { id });
  const { erc721Balances } = res.data;
  if (erc721Balances.length === 0) {
    return 0;
  }
  return erc721Balances[0].amount;
};

const landingStatsQuery = `
query landingStats {
  collectionSummaries(first: 4, orderBy: sellsVolume, orderDirection: desc) {
    sellsVolume
    sellsCount
    numOwners
    nftCount
    id
  }
}
`;

const landingDailystats = `
query landingDailyStats($addresses: [String]) {
  collectionDailyStats(first: 50, orderBy: timestamp, orderDirection: desc, where: {nftAddress_in: $addresses, payToken_in: ["0x0000000000000000000000000000000000000000", "0xd4949664cd82660aae99bedc034a0dea8a0bd517"]}) {
    nftAddress
    volume
    timestamp
    id
  }
}

`;

export const LandingStats = async () => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const landingStats = await client.query({
    query: gql(landingStatsQuery),
  });

  const landingDailyStats = await client.query({
    query: gql(landingDailystats),
    variables: {
      addresses: landingStats.data.collectionSummaries.map((c) => c.id),
    },
  });

  const [floor1, floor2, floor3, floor4] = await Promise.all([
    client.query({
      query: gql(FloorPriceQuery),
      variables: {
        listingFilter: {
          active: true,
          nftAddress: landingStats.data.collectionSummaries[0].id,
          payToken_in: [
            "0x0000000000000000000000000000000000000000",
            "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
          ],
        },
      },
    }),
    client.query({
      query: gql(FloorPriceQuery),
      variables: {
        listingFilter: {
          active: true,
          nftAddress: landingStats.data.collectionSummaries[1].id,
          payToken_in: [
            "0x0000000000000000000000000000000000000000",
            "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
          ],
        },
      },
    }),
    client.query({
      query: gql(FloorPriceQuery),
      variables: {
        listingFilter: {
          active: true,
          nftAddress: landingStats.data.collectionSummaries[2].id,
          payToken_in: [
            "0x0000000000000000000000000000000000000000",
            "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
          ],
        },
      },
    }),
    client.query({
      query: gql(FloorPriceQuery),
      variables: {
        listingFilter: {
          active: true,
          nftAddress: landingStats.data.collectionSummaries[3].id,
          payToken_in: [
            "0x0000000000000000000000000000000000000000",
            "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
          ],
        },
      },
    }),
  ]);

  return {
    landingStats: landingStats.data,
    landingDailyStats: landingDailyStats.data,
    floors: [floor1, floor2, floor3, floor4].map((f) => f.data),
  };
};

const FloorPriceQuery = `
  query floor($listingFilter: Listing_filter) {
    listings(first: 1, orderBy: pricePerItem, orderDirection: asc, where: $listingFilter) {
      pricePerItem
      nftAddress
    }
  }
`;

export const FloorPrice = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(FloorPriceQuery),
    variables: {
      listingFilter: {
        active: true,
        nftAddress: address,
        payToken_in: [ZERO_ADDRESS, DEFAULT_ERC20],
      },
    },
  });
  return res?.data?.listings[0]?.pricePerItem;
};

const UserOffersMadeQuery = `
  query userOffersMade($address: String, $deadline: Int) {
    offers(where: {creator: $address, deadline_gt: $deadline}) {
      id
      creator
      nftAddress
      createdAt
      tokenId
      quantity
      payToken
      pricePerItem
      deadline
      createdAt
      token {
        isERC1155
        name
      }
    }
    collectionOffers(where: {creator: $address, deadline_gt: $deadline}) {
      id
      creator
      nftAddress
      createdAt
      quantity
      payToken
      pricePerItem
      deadline
      createdAt
    }
  }
`;

export const UserOffersMade = async (address) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const time = parseInt(new Date().getTime() / 1000);

  const res = await client.query({
    query: gql(UserOffersMadeQuery),
    variables: {
      address,
      deadline: time,
    },
  });

  const data = res?.data;

  let offers = [];

  if (data?.offers?.length > 0) {
    offers = data?.offers;
  }

  if (data?.collectionOffers?.length > 0) {
    const _collectionOffers = data?.collectionOffers?.map((co) => {
      return { ...co, type: "collection" };
    });
    offers = [...offers, ..._collectionOffers];
  }

  return offers;
};

const UserOffersQuery = `
query userOffers($collectionOffer: CollectionOffer_filter) {
  collectionOffers(first: 5, where: $collectionOffer) {
    id
    creator
    nftAddress
    createdAt
    quantity
    payToken
    pricePerItem
    deadline
    createdAt
  }
}
`;

const AllUserTokenIds = `
query userTokenIds($address: [String], $creator: String, $deadline: Int, $first: Int, $skip: Int) {
  tokens(first: $first, skip: $skip, where: {owners_contains: $address}) {
    tokenId
    nftAddress
    isERC1155
    lastSalePayToken
    name
    offers(first: 5, where: {creator_not: $creator, deadline_gt: $deadline}, orderBy: pricePerItem, orderDirection: desc) {
      id
      creator
      nftAddress
      createdAt
      tokenId
      quantity
      payToken
      pricePerItem
      deadline
    }
  }
}
`;

export const UserOffers = async (address, pageNum) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const time = parseInt(new Date().getTime() / 1000);

  const res = await client.query({
    query: gql(AllUserTokenIds),
    variables: {
      address: [address],
      creator: address,
      deadline: time,
      ...getPaginationVariables(pageNum),
    },
  });

  const tokens = res?.data?.tokens;

  const res2 = await client.query({
    query: gql(UserOffersQuery),
    variables: {
      collectionOffer: {
        nftAddress_in: _.chain(tokens).map("nftAddress").uniq().value(),
        deadline_gt: time,
        creator_not: address,
      },
    },
  });

  const groupedCollectionOffers = _.chain(res2?.data?.collectionOffers)
    .groupBy("nftAddress")
    .value();

  const tokensOffers = [];

  for (let token of tokens) {
    const individualOffers = token?.offers ?? [];
    const collectionOffers = groupedCollectionOffers[token?.nftAddress] ?? [];
    const offers = [...individualOffers, ...collectionOffers];
    const tokenOffers = {
      nftAddress: token?.nftAddress,
      tokenId: token?.tokenId,
      isERC1155: token?.isERC1155,
      groups: offers,
    };
    tokensOffers.push(tokenOffers);
  }

  return tokensOffers;
};

export class UserOffersFetcher {
  constructor() {
    this.pageNum = 1;
    this.address = null;
  }

  reset(address) {
    this.pageNum = 1;
    this.address = address;
  }

  async fetch(address) {
    if (address !== this.address) {
      this.reset(address);
    }
    let offers = [];
    while (offers.length < LISTED_ITEMS_PER_PAGE) {
      const fetchedOffers = await UserOffers(this.address, this.pageNum);
      this.pageNum += 1;
      if (fetchedOffers.length === 0) {
        break;
      }
      const fetchedOffersNonEmpty = fetchedOffers.filter(
        (offer) => offer.groups.length > 0
      );
      offers = [...offers, ...fetchedOffersNonEmpty];
    }
    return offers;
  }
}

// const CollectionsQuery = `
// query activeCollections {
//   collections {
//     id
//     royalty
//     creator
//     feeReceipient
//     collectionSummary {
//       nftCount
//       sellsVolume
//       listingsCount
//     }
//   }
// }
// `;

/**
 * So this isn't paginated and if we wanna feature some collections and add pagination we
 * need to do some things.
 * 1. For the first page pull all collections in collectionMeta that have an index (include this in the first page)
 * 2. Then pull all other collections with pagination
 *
 * Since we have so few collections now we don't need to worry about this now.
 */
export const ActiveCollections = async (filter) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  let ids;

  if (filter && filter !== "All") {
    ids = Object.keys(collectionMeta).filter((id) => {
      if (collectionMeta[id].category?.find((c) => c === filter)) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    ids = Object.keys(collectionMeta).map((id) => id.toLowerCase());
  }

  const res = await client.query({
    query: gql(CollectionsByIdsQuery),
    variables: {
      ids,
    },
  });

  let collections = [...(res?.data?.collections || [])];
  return collections.sort((c1, c2) => {
    const c1Meta = collectionMeta[c1.id];
    const c2Meta = collectionMeta[c2.id];
    const c1Index = c1Meta?.index ?? Number.MAX_SAFE_INTEGER;
    const c2Index = c2Meta?.index ?? Number.MAX_SAFE_INTEGER;
    return c1Index - c2Index;
  });
};

const CollectionsByIdsQuery = `
query activeCollections($ids: [String]) {
  collections(where: {id_in: $ids}) {
    id
    royalty
    creator
    feeReceipient
    collectionSummary {
       nftCount
       sellsVolume
       listingsCount
     }
  }
}
`;

export const CollectionsByIds = async (ids) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(CollectionsByIdsQuery),
    variables: {
      ids: ids,
    },
  });

  return res?.data?.collections;
};

const UserCollectionOfferQuery = `
query userCollectionOffers($id: String, $deadline: Int) {
  collectionOffer(id: $id, deadline_gt: $deadline) {
    id
    creator
    nftAddress
    quantity
    payToken
    pricePerItem
    deadline
    createdAt
  }
}

`;

export const UserCollectionOffer = async (collection, userAddress) => {
  const time = parseInt(new Date().getTime() / 1000);

  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(UserCollectionOfferQuery),
    variables: {
      id: `${collection}:${userAddress}`,
      deadline: time,
    },
  });

  const deadline = parseInt(res?.data?.collectionOffer?.deadline ?? 0);

  return deadline < time ? null : res?.data?.collectionOffer;
};

const CollectionTraitsQuery = `
query collectionTraitsQuery($address: String) {
  traits(first: 1000, where: {collection: $address}) {
    id
    type
    value
    count
  }
}

`;

export const CollectionTraits = async (collection, userAddress) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(CollectionTraitsQuery),
    variables: {
      address: collection,
    },
  });

  return res?.data?.traits;
};

const TransactionQuery = `
  query transaction($id: String) {
    transactions(where: {id: $id}) {
      id
      hash
    }
  }
`;

const getTransactionByHash = async (transactionHash) => {
  const client = new ApolloClient({
    uri: GRAPH_URL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(TransactionQuery),
    variables: {
      id: transactionHash.toLowerCase(),
    },
  });

  if (res?.data?.transactions?.length > 0) {
    return res.data.transactions[0];
  }
  return null;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const WaitForTransaction = async (transactionHash) => {
  for (var i = 0; i < 15; i++) {
    const transaction = await getTransactionByHash(transactionHash);
    if (transaction != null) {
      return transaction;
    }
    await sleep(2000);
  }

  return null; // failed, return null
};
