import Loading from "components/Loading";
import SortingDropdownMenu from "components/sorting/SortingDropdownMenu";
import EasyInfiniteScroll from "components/basics/EasyInfiniteScroll";
import ActiveFilters, {
  renderActiveFilterChips,
} from "components/filters/ActiveFilters";
import FilterPanel from "components/filters/FilterPanel";
import { useIsMobile } from "helpers/Hooks";
import { useState, useEffect } from "react";
import cs from "classnames";
import { SearchBarWithoutDropdown } from "layout/SearchBar";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";

function FilterButton({ onClick, numFilters }) {
  return (
    <button
      className="bg-purple text-white font-semibold rounded-full py-3 px-5 cursor-hand flex align-center"
      onClick={onClick}
    >
      Filter
      <span className="inline-block align-middle rounded-full bg-white text-purple w-6 h-6 text-center ml-2 text-md font-bold">
        <span className="">{numFilters}</span>
      </span>
    </button>
  );
}

function SlideInFullscreenOverlay({ isExpanded, children }) {
  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isExpanded]);
  return (
    <div
      className={cs(
        "fixed w-screen h-screen top-0 left-0 z-9999 overflow-y-scroll bg-dark text-white transform transition-transform transition-slowest ease-in-out",
        {
          "-translate-x-full": !isExpanded,
          "translate-x-0": isExpanded,
        }
      )}
    >
      {children}
    </div>
  );
}

function MobileView({
  filterComponentList,
  activeFilters,
  numFilters,
  searchBar,
  sortingDropdownMenu,
  infiniteScroll,
}) {
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  return (
    <div className="flex relative w-full">
      <SlideInFullscreenOverlay isExpanded={isFiltersExpanded}>
        <div className="p-8">
          <div className="flex justify-end py-4 border-b">
            <button onClick={() => setIsFiltersExpanded(false)}>Done</button>
          </div>
          <div className="py-4 border-b">{activeFilters}</div>
          {filterComponentList}
        </div>
      </SlideInFullscreenOverlay>

      <div className="flex-grow">
        <div className="flex md:flex-row flex-col border-b border-darkGray justify-between p-5 sticky top-16 z-10 bg-dark gap-3">
          {searchBar}
          {activeFilters}
          {sortingDropdownMenu}
        </div>
        {infiniteScroll}
        <div className="sticky bottom-0 z-10 w-full">
          <div className="absolute w-full h-full">
            <div className="fixed bottom-0 left-0 w-full z-10">
              <div className="mb-6 flex justify-center ">
                <FilterButton
                  onClick={() => setIsFiltersExpanded(true)}
                  numFilters={numFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DesktopView({
  filterComponentList,
  activeFilters,
  searchBar,
  sortingDropdownMenu,
  infiniteScroll,
}) {
  const stickyHeaderHeight = useSelector(
    (state) => state.stickyHeaderHeight.stickyHeaderHeight
  );
  return (
    <div className="flex w-full h-full">
      <div
        className="block border-r border-t border-darkGray sticky"
        style={{
          top: stickyHeaderHeight,
          height: window.innerHeight - stickyHeaderHeight,
        }}
      >
        <FilterPanel>{filterComponentList}</FilterPanel>
      </div>

      <div className="flex-grow">
        <div
          className="flex md:flex-row flex-col border-b border-t border-darkGray justify-between p-5 sticky z-10 bg-dark gap-5"
          style={{ top: stickyHeaderHeight }}
        >
          <div className="flex flex-col gap-5 flex-grow">
            {searchBar}
            {activeFilters}
          </div>
          {sortingDropdownMenu}
        </div>
        {infiniteScroll}
      </div>
    </div>
  );
}

const debounceCall = debounce((func, ...args) => func(...args), 750);

export default function SortableFilterableInfiniteScrollingNFTList({
  filters,
  filterComponentList,
  handleFilterRemove,
  onSortingOptionChange,
  sortingOption,
  onChangeSearchValue,
  onDataChanged,
  overrideComponent,
  renderEmptyState,
  fetchPage,
  sensitivityListForPaginationReset,
  renderData,
}) {
  const isMobile = useIsMobile();
  const [searchValue, setSearchValue] = useState("");
  const infiniteScroll =
    overrideComponent != null ? (
      <div style={{ flexGrow: 1 }}>{overrideComponent}</div>
    ) : (
      <EasyInfiniteScroll
        pageStart={1}
        loader={
          <div
            className={cs("flex w-full justify-center", {
              "py-24": isMobile,
              "py-12": !isMobile,
            })}
          >
            <Loading hidePadding hideTopPadding />
          </div>
        }
        onDataChanged={onDataChanged}
        threshold={800}
        renderData={renderData}
        renderEmptyState={renderEmptyState}
        fetchPage={fetchPage}
        sensitivityListForPaginationReset={sensitivityListForPaginationReset}
      />
    );
  const filterChips = renderActiveFilterChips({
    onRemove: handleFilterRemove,
    filters,
  });
  const activeFilters = <ActiveFilters chips={filterChips} />;
  const sortingDropdownMenu = (
    <SortingDropdownMenu
      onChange={onSortingOptionChange}
      value={sortingOption}
    />
  );
  const searchBar = (
    <SearchBarWithoutDropdown
      value={searchValue}
      onClick={
        () => null
        // searchValue !== null && searchValue !== "" ? setOpen(true) : null
      }
      onChange={(val) => {
        setSearchValue(val);
        debounceCall(onChangeSearchValue, val);
      }}
      onClose={() => {
        setSearchValue("");
        onChangeSearchValue("");
      }}
    />
  );

  return (
    <div className="text-white w-full">
      {isMobile ? (
        <MobileView
          filterComponentList={filterComponentList}
          activeFilters={activeFilters}
          searchBar={searchBar}
          numFilters={filterChips.length}
          sortingDropdownMenu={sortingDropdownMenu}
          infiniteScroll={infiniteScroll}
        />
      ) : (
        <DesktopView
          filterComponentList={filterComponentList}
          activeFilters={activeFilters}
          searchBar={searchBar}
          sortingDropdownMenu={sortingDropdownMenu}
          infiniteScroll={infiniteScroll}
        />
      )}
    </div>
  );
}
