import { useState } from "react";
import Orb from "../../assets/icons/WOrb.svg";
import Datetime from "react-datetime";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { ethers } from "ethers";
import Approve from "../Approve";
import cs from "classnames";
import { useApproveERC721 } from "../../hooks/marketTransactions";
import { DEFAULT_ERC20 } from "../../helpers/payTokens";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";
import ReactTooltip from "react-tooltip";

export default function CreateAuction({
  tokenData,
  collectionData,
  closeModal,
}) {
  const dispatch = useDispatch();
  const [listingPrice, setListingPrice] = useState(0);
  const [currentTime] = useState(
    new Date(new Date().getTime() + 2 * 60 * 1000)
  );
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() + 2 * 60 * 1000)
  );

  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10)
  );
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { erc721Contract, orbitContract, address, auctionContract } = useEVM();

  const isInvalidAmount =
    !listingPrice || listingPrice === "" || listingPrice === undefined;

  const { loading: loadingApprove, approve } = useApproveERC721(
    tokenData,
    (step) => {
      setStep(2);
    }
  );

  async function createOffer() {
    setLoading(true);
    try {
      const transaction = await auctionContract?.instance?.createAuction(
        erc721Contract?.instance?.attach(tokenData?.nftAddress).address,
        tokenData?.tokenId,
        DEFAULT_ERC20,
        ethers.utils.parseEther(listingPrice.toString()),
        parseInt(startTime.getTime() / 1000),
        true,
        parseInt(endTime.getTime() / 1000)
      );
      const toastId = loadingToast("Started auction...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          setStep(3);
          successToast("Auction started!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    approve(auctionContract?.instance?.address);
  }

  async function handleOffer() {
    const ercNFTContract = erc721Contract;
    const isApproved = await ercNFTContract?.instance
      ?.attach(tokenData?.nftAddress)
      ?.isApprovedForAll(address, auctionContract?.instance?.address);
    if (!isApproved) {
      setStep(1);
      setTransactionStarted(true);
    } else {
      setStep(2);
      setTransactionStarted(true);
    }
  }

  return (
    <>
      {transactionStarted ? (
        <Approve
          step={step}
          success={"Auction created!"}
          loading={loading || loadingApprove}
          approve={"Approve"}
          action={"Start auction"}
          closeModal={closeModal}
          applyStep1={handleApprove}
          applyStep2={createOffer}
        />
      ) : (
        <>
          <ReactTooltip
            aria-haspopup="true"
            place="bottom"
            id="collection-offer"
            effect="solid"
          >
            <div className="flex max-w-sm flex-col">
              <p>
                Minimum price that a seller would
                <br /> be willing to accept from a buyer.
              </p>
            </div>
          </ReactTooltip>
          <div className="flex border-gray gap-5 flex-col border-b border-t py-6 justify-between px-10 ">
            <div className="flex justify-between">
              <div className="flex items-start gap-3">
                {!tokenData?.bundle && (
                  <div className="w-16 self-center flex items-center flex-col justify-center">
                    <img
                      className="rounded-xl"
                      alt="marketCard"
                      src={tokenData?.image}
                    />
                  </div>
                )}
                <div className="flex flex-col">
                  <span className="font-semibold text-sm text-purple">
                    {collectionData?.name}
                  </span>
                  <span className="text-lg font-bold">
                    #{tokenData?.tokenId}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 flex-col">
                <div className="flex items-center">
                  <span className="font-bold w-32">Reserved Price</span>
                  <button
                    data-tip
                    data-for="collection-offer"
                    className="w-5 leading-3 font-semibold h-5  text-purple rounded-full border-2 border-purple"
                  >
                    ?
                  </button>
                </div>

                <div className="flex items-center border  border-darkGray rounded">
                  <div className="flex items-center bg-darkGray rounded-l gap-1 py-1 w-26 px-2.5">
                    <img className="h-3 mt-0.5" alt="eth" src={Orb} />
                    <span className="font-semibold">WEVMOS</span>
                  </div>
                  <input
                    value={listingPrice}
                    type="number"
                    onChange={(e) =>
                      parseInt(e.target.value) >= 0 || !e.target.value
                        ? setListingPrice(parseFloat(e.target.value))
                        : null
                    }
                    className="flex-1 outline-none rounded-r bg-transparent border-darkGray border-l py-1 pl-2"
                    placeholder="Amount"
                  />
                </div>
              </div>

              <div className="flex gap-2 flex-col ">
                <div className="flex gap-1 text-white flex-col">
                  <span className="font-bold w-24">Start Date</span>
                  <Datetime
                    value={startTime}
                    className={
                      "calendarAboveInput outline-none bg-dark border-darkGray border rounded-md pl-2 p-0.5 py-1.5"
                    }
                    onChange={(val) => setStartTime(val.toDate())}
                    inputProps={{
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                    closeOnSelect
                    isValidDate={(cur) =>
                      cur.valueOf() >
                        currentTime.getTime() - 1000 * 60 * 60 * 23 &&
                      cur.valueOf() <
                        currentTime.getTime() + 31 * 1000 * 60 * 60 * 23
                    }
                  />
                </div>
                <div className="flex gap-1 text-white flex-col">
                  <span className="font-bold w-24">End Date</span>
                  <Datetime
                    value={endTime}
                    className={
                      "calendarAboveInput outline-none bg-dark border-darkGray border rounded-md pl-2 p-0.5 py-1.5 "
                    }
                    onChange={(val) => setEndTime(val.toDate())}
                    inputProps={{
                      onKeyDown: (e) => e.preventDefault(),
                    }}
                    closeOnSelect
                    isValidDate={(cur) =>
                      cur.valueOf() >
                        startTime.getTime() - 1000 * 60 * 60 * 23 &&
                      cur.valueOf() <
                        startTime.getTime() + 31 * 1000 * 60 * 60 * 23
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-3 pb-4 self-center">
            <button
              disabled={loading || loadingApprove || isInvalidAmount}
              onClick={handleOffer}
              className={cs("font-bold h-8 text-white rounded-md w-36 ", {
                "bg-purple": !(loading || loadingApprove) || !isInvalidAmount,
                "bg-purple opacity-40":
                  loading || loadingApprove || isInvalidAmount,
              })}
            >
              {loading || loadingApprove ? "Loading.." : "Start auction"}
            </button>
          </div>
        </>
      )}
    </>
  );
}
