import cs from "classnames";
import { nFormatter } from "helpers/formatNumber";

export default function StakeBox({
  userRewards,
  orbitalBalance,
  onClaim,
  loading,
}) {
  return (
    <div className="rounded-xl bg-dark border-2 border-darkGray w-full max-w-lg p-4 gap-7 flex flex-col shadow">
      <div className="flex gap-3 flex-1 w-full flex-col">
        <span className="text-purple font-semibold text-2xl">
          Claim My Rewards
        </span>
        <div className="flex text-white flex-1 justify-between">
          <span>Orbital Apes Balance</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <span>{orbitalBalance}</span>
        </div>
        <div className="flex text-white flex-1 justify-between">
          <span>My WEVMOS Rewards</span>
          <div className="border-b border-gray border-dotted flex-1 mx-2 mb-1" />
          <span>{nFormatter(userRewards, 3)}</span>
        </div>
        <div className="flex mt-4 self-center">
          <button
            disabled={loading}
            onClick={onClaim}
            className={cs(
              "bg-purple rounded px-5 py-1 text-white font-medium",
              {
                "bg-opacity-50": loading,
              }
            )}
          >
            Claim
          </button>
        </div>
      </div>
    </div>
  );
}
