import { useState } from "react";
import { nFormatter } from "helpers/formatNumber";
import { formatEther, payTokenMeta } from "helpers/payTokens";
import { isSameAddress } from "helpers/address";
import BuyModal from "components/BuyModal";
import ListItemModal from "components/List/ListItemModal";
import Address from "components/Address";
import { Link } from "react-router-dom";

export default function ERC1155ListingsRow({
  listing,
  erc1155Balance,
  address,
  viewerListing,
  collectionMeta,
  data,
}) {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  return (
    <div className="flex flex-col">
      {showBuyModal && (
        <BuyModal
          collectionMeta={collectionMeta}
          data={{ ...data, ...listing }}
          closeModal={() => setShowBuyModal(false)}
        />
      )}
      {showListModal && (
        <ListItemModal
          tokenData={data}
          collectionData={collectionMeta}
          nftId={listing?.tokenId}
          viewerListing={viewerListing}
          erc1155Balance={erc1155Balance}
          collection={listing?.nftAddress}
          closeModal={() => setShowListModal(false)}
        />
      )}
      <div className="flex py-4 text-white border-b px-4 border-darkGray">
        <div className="w-3/12 items-center gap-2 flex">
          <img
            className="h-3.5"
            alt="token"
            src={payTokenMeta[listing?.payToken]?.image}
          />
          {nFormatter(formatEther(listing?.pricePerItem, listing?.payToken), 2)}
        </div>
        <div className="w-3/12 items-center gap-2 flex">
          <img
            className="h-3.5"
            alt="token"
            src={payTokenMeta[listing?.payToken]?.image}
          />
          {nFormatter(
            formatEther(listing?.pricePerItem, listing?.payToken) /
              listing?.quantity,
            2
          )}
        </div>
        <span className="w-2/12">{listing?.quantity}</span>
        <Link to={`/profile/${listing?.owner}`} className="w-4/12 text-purple">
          <Address address={listing?.owner} length={10} />
        </Link>
        {isSameAddress(listing?.owner, address) && (
          <button
            onClick={() => setShowListModal(true)}
            className="h-8 font-bold text-lightbg rounded-md w-32 bg-purple"
          >
            {"Edit listing"}
          </button>
        )}
        {!isSameAddress(address, listing?.owner) && (
          <button
            onClick={() => setShowBuyModal(true)}
            className=" h-8 font-bold text-lightbg rounded-md w-32  bg-purple"
          >
            {"Buy"}
          </button>
        )}
      </div>
    </div>
  );
}
