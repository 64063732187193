import bg from "../../assets/backgrounds/1.jpg";
import Card from "./Card";
import { Link } from "react-router-dom";

export default function CoverSection() {
  return (
    <div
      style={{ backgroundImage: `url(${bg})` }}
      className="flex  w-full bg-cover  self-center flex-col"
    >
      <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-dark to-dark via-transparent w-full">
        <div className="gap-2 py-28 flex px-8 justify-center bg-dark bg-opacity-50 w-full">
          <div className="flex md:flex-row flex-col items-center max-w-7xl w-full gap-8 justify-between  self-center">
            <div className="flex  self-center flex-col">
              <div className="flex flex-col gap-14">
                <div
                  data-aos="zoom-in"
                  className="font-bold text-white self-start flex flex-col gap-2 text-5xl"
                >
                  <div className="text-white flex gap-2">
                    Earn
                    <span className="text-purple uppercase ">Rewards</span>
                  </div>
                  <div className="text-white flex gap-2">
                    Collect
                    <span className="text-purple uppercase ">NFTS</span>
                  </div>
                </div>
                <div
                  data-aos="zoom-in"
                  className="max-w-md text-white flex flex-col gap-10 text-lg"
                >
                  <span className="text-white">
                    The largest NFT Marketplace in
                    <a
                      href="https://evmos.org/"
                      className="text-purple ml-1 font-semibold underline"
                    >
                      Evmos
                    </a>
                    . Built by the community, for the community.
                    <br className="" />
                    <br className="" />
                    <span className="text-xl font-medium">
                      Explore, trade, and share your NFTs
                    </span>
                  </span>
                  <span></span>
                  <div className="flex gap-3">
                    <Link
                      to="/collections"
                      className="bg-purple shadow font-medium text-lg px-5 py-2 rounded-full  text-white"
                    >
                      Explore Collections
                    </Link>
                    <a
                      href="/collection/0x4c275ade386af17276834579b1a68146cef3c770"
                      className="bg-dark bg-opacity-60 shadow font-medium text-lg px-5 py-2 rounded-full text-purple border-2 border-purple"
                    >
                      Orbital Apes
                    </a>
                  </div>
                </div>
                <div className="flex mt-2 gap-10">
                  <div data-aos="fade-up" className="flex flex-col">
                    <span className="font-bold text-white text-3xl">20%</span>
                    <span className="text-white font-medium">
                      Fees to Orbital Apes
                    </span>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="flex flex-col"
                  >
                    <span className="font-bold text-white text-3xl">100%</span>
                    <span className="text-white font-medium">
                      Community Focused
                    </span>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="flex flex-col"
                  >
                    <span className="font-bold text-white text-3xl">Top</span>
                    <span className="text-white font-medium">
                      Evmos NFT Collections
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-down-left" className=" ">
              <Card />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
