import RightArrow from "assets/icons/arrow-right-svgrepo-com.svg";
import LeftArrow from "assets/icons/arrow-left-svgrepo-com.svg";
import React from "react";

import { useState } from "react";
import cs from "classnames";
import { useIsMobile } from "helpers/Hooks";

export default function FilterPanel({ children }) {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(!isMobile);

  return (
    <div
      style={{ maxHeight: "90vh" }}
      className={cs(
        "flex transition-width bg-dark transition-slowest ease-in-out duration-500 flex-col h-full",
        {
          "w-72 min-w-72": open,
          "w-24 min-w-24": !open,
        }
      )}
    >
      <button onClick={() => setOpen(!open)} className="sticky">
        <div
          className={cs(
            "flex border-darkGray bg-dark border-b py-2 justify-between px-2"
          )}
        >
          <span
            className={cs("font-semibold text-lg pr", {
              // flex: open,
              // hidden: !open,
            })}
          >
            Filters
          </span>
          <img
            className="pr"
            alt="arrow indicator"
            src={open ? LeftArrow : RightArrow}
          />
        </div>
      </button>

      <div
        className={cs("flex flex-col overflow-y-scroll", {
          flex: open,
          hidden: !open,
        })}
      >
        {React.Children.map(children, (childFilter) => (
          <div className="flex w-full border-darkGray border-b  justify-center">
            {childFilter}
          </div>
        ))}
      </div>
    </div>
  );
}
