import Modal from "react-modal";
import ListItem from "./ListItem";
import UpdateListingItem from "./UpdateListingItem";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#000",
  },
  overlay: {
    backgroundColor: "rgba(0, 0,0, 0.8)",
  },
};

function ListItemModal({
  closeModal,
  nftId,
  collection,
  tokenData,
  collectionData,
  erc1155Balance,
  viewerListing,
}) {
  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              {viewerListing && <>Edit Listing</>}
              {!viewerListing && <>List Item</>}
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>
          {!viewerListing && (
            <ListItem
              data={tokenData}
              collectionData={collectionData}
              nftId={nftId}
              closeModal={closeModal}
              collection={collection}
              erc1155Balance={erc1155Balance}
            />
          )}
          {viewerListing && (
            <UpdateListingItem
              data={tokenData}
              collectionData={collectionData}
              nftId={nftId}
              viewerListing={viewerListing}
              closeModal={closeModal}
              collection={collection}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ListItemModal;
