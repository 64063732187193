import cs from "classnames";

export default function Menu({ onChange, active }) {
  function handleMenuChange(id) {
    onChange(id);
  }

  return (
    <div className="flex  border-gray">
      <button
        onClick={() => handleMenuChange(0)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 0,
          "bg-darkGray": active !== 0,
          "border-gray": active !== 0 && active !== 1,
        })}
      >
        Items
      </button>
      <button
        onClick={() => handleMenuChange(2)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 2,
          "bg-darkGray": active !== 1 || active !== 0,
          "border-gray": active !== 0 && active !== 1,
        })}
      >
        Bundles
      </button>
      <button
        onClick={() => handleMenuChange(1)}
        className={cs("text-white text-lg font-medium px-5 py-1.5", {
          "bg-dark border-t-2 border-purple": active === 1,
          "bg-darkGray ": active !== 1,
          "border-gray": active !== 1 && active !== 2,
        })}
      >
        Activity
      </button>
    </div>
  );
}
