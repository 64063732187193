import {
  Legend,
  Bar,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Line,
  ComposedChart,
} from "recharts";
import ReactResizeDetector from "react-resize-detector";
import cs from "classnames";
import moment from "moment";
import { formatEther } from "helpers/payTokens";

export default function ActivityGraph({ data = [], payToken }) {
  const sorted = [...data];
  let maxDayCount = 0;
  let maxPrice = 0;
  const formatted = sorted
    ?.sort((a, b) => parseInt(a?.timestamp) - parseInt(b?.timestamp))
    .map((d) => {
      let avgPrice = parseFloat(
        (formatEther(d.volume, payToken) / d?.count).toFixed(2)
      );

      maxDayCount = d?.count > maxDayCount ? d?.count : maxDayCount;
      maxPrice = avgPrice > maxPrice ? avgPrice : maxPrice;

      return {
        "Num. sales": d?.count,
        "Avg. price": avgPrice,
        date: moment(
          new Date(parseInt(d?.timestamp) * 1000).toISOString()
        ).format("D/M"),
      };
    });

  return (
    <div className="flex z-10 w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark border-b border-darkGray  bg-transparent  py-3  px-4"
        )}
      >
        <span className="font-semibold text-white pl-4 text-lg">
          Avg. price {"&"} sell count
        </span>
      </div>
      <div className=" flex flex-col ">
        <ReactResizeDetector>
          {({ width }) =>
            width > 0 ? (
              <div className="py-3  h-80 overflow-hidden relative">
                <div className="absolute overflow-hidden">
                  <ComposedChart
                    width={width}
                    syncId={"collection"}
                    height={300}
                    data={formatted}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <XAxis dataKey="date" />
                    <YAxis
                      type="number"
                      axisLine={false}
                      hide={true}
                      domain={[0, maxDayCount * 2]}
                      yAxisId="right"
                      orientation="right"
                    />
                    <YAxis yAxisId="left" />
                    <ChartTooltip />
                    <Legend />
                    <CartesianGrid stroke="#343444" />
                    <Bar
                      yAxisId="right"
                      dataKey="Num. sales"
                      barSize={30}
                      fill="#8884d8"
                    />
                    <Line
                      yAxisId="left"
                      dot={false}
                      type="monotone"
                      dataKey="Avg. price"
                      stroke="#8454EB"
                    />
                  </ComposedChart>
                </div>
              </div>
            ) : (
              <div>{width}</div>
            )
          }
        </ReactResizeDetector>
      </div>
    </div>
  );
}
