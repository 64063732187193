import { useState } from "react";
import cs from "classnames";

export default function Banner({ data }) {
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);

  return (
    <div
      style={{ height: "220px" }}
      className="flex items-center z-10 overflow-hidden justify-center"
    >
      <img
        className={cs("object-cover w-full", {
          hidden: !bannerLoaded,
          flex: bannerLoaded,
        })}
        alt="banner"
        onLoad={() => setBannerLoaded(true)}
        src={data?.bannerURL}
      />

      {!bannerLoaded && (
        <div
          style={{ height: "220px" }}
          className="overflow-hidden w-full flex"
        >
          <span
            data-placeholder
            style={{ height: "220px" }}
            className=" w-full overflow-hidden absolute  bg-darkGray"
          >
            {" "}
          </span>
        </div>
      )}
      <div
        style={{ top: "220px" }}
        className={cs(
          "flex justify-center items-center rounded-full w-32 h-32 left-8 md:left-16 absolute border-4 border-purple z-1",
          { "bg-darkGray": !profileLoaded }
        )}
      >
        <img
          onLoad={() => setProfileLoaded(true)}
          alt="collection-profile"
          src={data?.imageURL}
          className={cs("w-full rounded-full bg-black", {
            hidden: !profileLoaded,
            flex: profileLoaded,
          })}
        />
      </div>
    </div>
  );
}
