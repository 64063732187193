/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { providers, Signer, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import OrbitAddressRegistryDeployment from "./deployments/evmos/OrbitAddressRegistry.json";
import { OrbitAddressRegistry } from "./typechain/OrbitAddressRegistry";
import { OrbitAddressRegistry__factory } from "./typechain/factories/OrbitAddressRegistry__factory";
import OrbitDeployment from "./deployments/evmos/Orbit.json";
import { Orbit } from "./typechain/Orbit";
import { Orbit__factory } from "./typechain/factories/Orbit__factory";
import OrbitAddressRegistryV2Deployment from "./deployments/evmos/OrbitAddressRegistryV2.json";
import { OrbitAddressRegistryV2 } from "./typechain/OrbitAddressRegistryV2";
import { OrbitAddressRegistryV2__factory } from "./typechain/factories/OrbitAddressRegistryV2__factory";
import { OrbitAuction } from "./typechain/OrbitAuction";
import { OrbitAuction__factory } from "./typechain/factories/OrbitAuction__factory";
import OrbitCollectionRegistryV2Deployment from "./deployments/evmos/OrbitCollectionRegistryV2.json";
import { OrbitCollectionRegistryV2 } from "./typechain/OrbitCollectionRegistryV2";
import { OrbitCollectionRegistryV2__factory } from "./typechain/factories/OrbitCollectionRegistryV2__factory";
import OrbitCollectionRegistryDeployment from "./deployments/evmos/OrbitCollectionRegistry.json";
import { OrbitCollectionRegistry } from "./typechain/OrbitCollectionRegistry";
import { OrbitCollectionRegistry__factory } from "./typechain/factories/OrbitCollectionRegistry__factory";
import OrbitRewardsDistributorDeployment from "./deployments/evmos/OrbitRewardsDistributor.json";
import { OrbitRewardsDistributor } from "./typechain/OrbitRewardsDistributor";
import { OrbitRewardsDistributor__factory } from "./typechain/factories/OrbitRewardsDistributor__factory";
import OrbitAuctionV2Deployment from "./deployments/evmos/OrbitAuctionV2.json";
import { OrbitAuctionV2 } from "./typechain/OrbitAuctionV2";
import { OrbitAuctionV2__factory } from "./typechain/factories/OrbitAuctionV2__factory";
import { WEVMOS } from "./typechain/WEVMOS";
import { WEVMOS__factory } from "./typechain/factories/WEVMOS__factory";
import OrbitTokenRegistryDeployment from "./deployments/evmos/OrbitTokenRegistry.json";
import { OrbitTokenRegistry } from "./typechain/OrbitTokenRegistry";
import { OrbitTokenRegistry__factory } from "./typechain/factories/OrbitTokenRegistry__factory";
import OrbitBundlesDeployment from "./deployments/evmos/OrbitBundles.json";
import { OrbitBundles } from "./typechain/OrbitBundles";
import { OrbitBundles__factory } from "./typechain/factories/OrbitBundles__factory";
import OrbitV2Deployment from "./deployments/evmos/OrbitV2.json";
import { OrbitV2 } from "./typechain/OrbitV2";
import { OrbitV2__factory } from "./typechain/factories/OrbitV2__factory";
import { BoredApesTest } from "./typechain/BoredApesTest";
import { BoredApesTest__factory } from "./typechain/factories/BoredApesTest__factory";
import { CoolCatsCollabsTest } from "./typechain/CoolCatsCollabsTest";
import { CoolCatsCollabsTest__factory } from "./typechain/factories/CoolCatsCollabsTest__factory";
import { Currency } from "./typechain/Currency";
import { Currency__factory } from "./typechain/factories/Currency__factory";
import { CoolCatsTest } from "./typechain/CoolCatsTest";
import { CoolCatsTest__factory } from "./typechain/factories/CoolCatsTest__factory";
import { ERC1155 } from "./typechain/ERC1155";
import { ERC1155__factory } from "./typechain/factories/ERC1155__factory";
import { ERC20 } from "./typechain/ERC20";
import { ERC20__factory } from "./typechain/factories/ERC20__factory";
import { ERC721 } from "./typechain/ERC721";
import { ERC721__factory } from "./typechain/factories/ERC721__factory";

const emptyContract = {
  instance: undefined,
  factory: undefined,
};
const defaultProvider: providers.Provider | undefined = undefined;
export const ProviderContext = React.createContext<
  [
    providers.Provider | undefined,
    React.Dispatch<React.SetStateAction<providers.Provider | undefined>>
  ]
>([defaultProvider, () => {}]);
const defaultCurrentAddress: string = "";
export const CurrentAddressContext = React.createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>([defaultCurrentAddress, () => {}]);
const defaultSigner: Signer | undefined = undefined;
export const SignerContext = React.createContext<
  [Signer | undefined, React.Dispatch<React.SetStateAction<Signer | undefined>>]
>([defaultSigner, () => {}]);
const defaultSymfoniContext: SymfoniContextInterface = {
  currentHardhatProvider: "",
  init: () => {
    throw Error("Symfoni context not initialized");
  },
  loading: false,
  messages: [],
  providers: [],
};
export const SymfoniContext = React.createContext<SymfoniContextInterface>(
  defaultSymfoniContext
);
export const OrbitAddressRegistryContext =
  React.createContext<SymfoniOrbitAddressRegistry>(emptyContract);
export const OrbitContext = React.createContext<SymfoniOrbit>(emptyContract);
export const OrbitAddressRegistryV2Context =
  React.createContext<SymfoniOrbitAddressRegistryV2>(emptyContract);
export const OrbitAuctionContext =
  React.createContext<SymfoniOrbitAuction>(emptyContract);
export const OrbitCollectionRegistryV2Context =
  React.createContext<SymfoniOrbitCollectionRegistryV2>(emptyContract);
export const OrbitCollectionRegistryContext =
  React.createContext<SymfoniOrbitCollectionRegistry>(emptyContract);
export const OrbitRewardsDistributorContext =
  React.createContext<SymfoniOrbitRewardsDistributor>(emptyContract);
export const OrbitAuctionV2Context =
  React.createContext<SymfoniOrbitAuctionV2>(emptyContract);
export const WEVMOSContext = React.createContext<SymfoniWEVMOS>(emptyContract);
export const OrbitTokenRegistryContext =
  React.createContext<SymfoniOrbitTokenRegistry>(emptyContract);
export const OrbitBundlesContext =
  React.createContext<SymfoniOrbitBundles>(emptyContract);
export const OrbitV2Context =
  React.createContext<SymfoniOrbitV2>(emptyContract);
export const BoredApesTestContext =
  React.createContext<SymfoniBoredApesTest>(emptyContract);
export const CoolCatsCollabsTestContext =
  React.createContext<SymfoniCoolCatsCollabsTest>(emptyContract);
export const CurrencyContext =
  React.createContext<SymfoniCurrency>(emptyContract);
export const CoolCatsTestContext =
  React.createContext<SymfoniCoolCatsTest>(emptyContract);
export const ERC1155Context =
  React.createContext<SymfoniERC1155>(emptyContract);
export const ERC20Context = React.createContext<SymfoniERC20>(emptyContract);
export const ERC721Context = React.createContext<SymfoniERC721>(emptyContract);

export interface SymfoniContextInterface {
  init: (provider?: string) => void;
  loading: boolean;
  messages: string[];
  currentHardhatProvider: string;
  providers: string[];
}

export interface SymfoniProps {
  autoInit?: boolean;
  showLoading?: boolean;
  loadingComponent?: React.ReactNode;
}

export interface SymfoniOrbitAddressRegistry {
  instance?: OrbitAddressRegistry;
  factory?: OrbitAddressRegistry__factory;
}

export interface SymfoniOrbit {
  instance?: Orbit;
  factory?: Orbit__factory;
}

export interface SymfoniOrbitAddressRegistryV2 {
  instance?: OrbitAddressRegistryV2;
  factory?: OrbitAddressRegistryV2__factory;
}

export interface SymfoniOrbitAuction {
  instance?: OrbitAuction;
  factory?: OrbitAuction__factory;
}

export interface SymfoniOrbitCollectionRegistryV2 {
  instance?: OrbitCollectionRegistryV2;
  factory?: OrbitCollectionRegistryV2__factory;
}

export interface SymfoniOrbitCollectionRegistry {
  instance?: OrbitCollectionRegistry;
  factory?: OrbitCollectionRegistry__factory;
}

export interface SymfoniOrbitRewardsDistributor {
  instance?: OrbitRewardsDistributor;
  factory?: OrbitRewardsDistributor__factory;
}

export interface SymfoniOrbitAuctionV2 {
  instance?: OrbitAuctionV2;
  factory?: OrbitAuctionV2__factory;
}

export interface SymfoniWEVMOS {
  instance?: WEVMOS;
  factory?: WEVMOS__factory;
}

export interface SymfoniOrbitTokenRegistry {
  instance?: OrbitTokenRegistry;
  factory?: OrbitTokenRegistry__factory;
}

export interface SymfoniOrbitBundles {
  instance?: OrbitBundles;
  factory?: OrbitBundles__factory;
}

export interface SymfoniOrbitV2 {
  instance?: OrbitV2;
  factory?: OrbitV2__factory;
}

export interface SymfoniBoredApesTest {
  instance?: BoredApesTest;
  factory?: BoredApesTest__factory;
}

export interface SymfoniCoolCatsCollabsTest {
  instance?: CoolCatsCollabsTest;
  factory?: CoolCatsCollabsTest__factory;
}

export interface SymfoniCurrency {
  instance?: Currency;
  factory?: Currency__factory;
}

export interface SymfoniCoolCatsTest {
  instance?: CoolCatsTest;
  factory?: CoolCatsTest__factory;
}

export interface SymfoniERC1155 {
  instance?: ERC1155;
  factory?: ERC1155__factory;
}

export interface SymfoniERC20 {
  instance?: ERC20;
  factory?: ERC20__factory;
}

export interface SymfoniERC721 {
  instance?: ERC721;
  factory?: ERC721__factory;
}

export const Symfoni: React.FC<SymfoniProps> = ({
  showLoading = true,
  autoInit = true,
  ...props
}) => {
  const [initializeCounter, setInitializeCounter] = useState(0);
  const [currentHardhatProvider, setCurrentHardhatProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [signer, setSigner] = useState<Signer | undefined>(defaultSigner);
  const [provider, setProvider] = useState<providers.Provider | undefined>(
    defaultProvider
  );
  const [currentAddress, setCurrentAddress] = useState<string>(
    defaultCurrentAddress
  );
  const [fallbackProvider] = useState<string | undefined>(undefined);
  const [providerPriority, setProviderPriority] = useState<string[]>([
    "web3modal",
    "hardhat",
  ]);
  const [OrbitAddressRegistry, setOrbitAddressRegistry] =
    useState<SymfoniOrbitAddressRegistry>(emptyContract);
  const [Orbit, setOrbit] = useState<SymfoniOrbit>(emptyContract);
  const [OrbitAddressRegistryV2, setOrbitAddressRegistryV2] =
    useState<SymfoniOrbitAddressRegistryV2>(emptyContract);
  const [OrbitAuction, setOrbitAuction] =
    useState<SymfoniOrbitAuction>(emptyContract);
  const [OrbitCollectionRegistryV2, setOrbitCollectionRegistryV2] =
    useState<SymfoniOrbitCollectionRegistryV2>(emptyContract);
  const [OrbitCollectionRegistry, setOrbitCollectionRegistry] =
    useState<SymfoniOrbitCollectionRegistry>(emptyContract);
  const [OrbitRewardsDistributor, setOrbitRewardsDistributor] =
    useState<SymfoniOrbitRewardsDistributor>(emptyContract);
  const [OrbitAuctionV2, setOrbitAuctionV2] =
    useState<SymfoniOrbitAuctionV2>(emptyContract);
  const [WEVMOS, setWEVMOS] = useState<SymfoniWEVMOS>(emptyContract);
  const [OrbitTokenRegistry, setOrbitTokenRegistry] =
    useState<SymfoniOrbitTokenRegistry>(emptyContract);
  const [OrbitBundles, setOrbitBundles] =
    useState<SymfoniOrbitBundles>(emptyContract);
  const [OrbitV2, setOrbitV2] = useState<SymfoniOrbitV2>(emptyContract);
  const [BoredApesTest, setBoredApesTest] =
    useState<SymfoniBoredApesTest>(emptyContract);
  const [CoolCatsCollabsTest, setCoolCatsCollabsTest] =
    useState<SymfoniCoolCatsCollabsTest>(emptyContract);
  const [Currency, setCurrency] = useState<SymfoniCurrency>(emptyContract);
  const [CoolCatsTest, setCoolCatsTest] =
    useState<SymfoniCoolCatsTest>(emptyContract);
  const [ERC1155, setERC1155] = useState<SymfoniERC1155>(emptyContract);
  const [ERC20, setERC20] = useState<SymfoniERC20>(emptyContract);
  const [ERC721, setERC721] = useState<SymfoniERC721>(emptyContract);
  useEffect(() => {
    if (messages.length > 0) console.debug(messages.pop());
  }, [messages]);

  const getProvider = async (): Promise<
    { provider: providers.Provider; hardhatProviderName: string } | undefined
  > => {
    let hardhatProviderName = "Not set";
    let _providerPriority = [...providerPriority];
    // Fallback provider
    if (fallbackProvider && autoInit && initializeCounter === 0) {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === null) {
        _providerPriority = _providerPriority.sort((a, b) => {
          return a === fallbackProvider ? -1 : b === fallbackProvider ? 1 : 0;
        });
      }
    }
    const provider = await _providerPriority.reduce(
      async (
        maybeProvider: Promise<providers.Provider | undefined>,
        providerIdentification
      ) => {
        let foundProvider = await maybeProvider;
        if (foundProvider) {
          return Promise.resolve(foundProvider);
        } else {
          switch (providerIdentification.toLowerCase()) {
            case "web3modal":
              try {
                const provider = await getWeb3ModalProvider();
                const web3provider = new ethers.providers.Web3Provider(
                  provider
                );
                hardhatProviderName = "web3modal";
                return Promise.resolve(web3provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            case "hardhat":
              try {
                const provider = new ethers.providers.JsonRpcProvider({
                  url: "https://evmos-mainnet.public.blastapi.io",
                });
                hardhatProviderName = "hardhat";
                return Promise.resolve(provider);
              } catch (error) {
                return Promise.resolve(undefined);
              }
            default:
              return Promise.resolve(undefined);
          }
        }
      },
      Promise.resolve(undefined)
    ); // end reduce
    return provider ? { provider, hardhatProviderName } : undefined;
  };
  const getSigner = async (
    _provider: providers.Provider,
    hardhatProviderName: string
  ): Promise<Signer | undefined> => {
    switch (hardhatProviderName) {
      case "web3modal":
        const web3provider = _provider as ethers.providers.Web3Provider;
        return await web3provider.getSigner();
      case "hardhat":
        return ethers.Wallet.fromMnemonic(
          "test test test test test test test test test test test junk"
        ).connect(_provider);
      default:
        return undefined;
    }
  };
  const getWeb3ModalProvider = async (): Promise<any> => {
    const providerOptions: IProviderOptions = {};
    const web3Modal = new Web3Modal({
      // network: "mainnet",
      cacheProvider: false,
      providerOptions, // required
    });
    return await web3Modal.connect();
  };

  useEffect(() => {
    let subscribed = true;
    const doAsync = async () => {
      const finish = (text: string) => {
        setLoading(false);
        setMessages((old) => [...old, text]);
      };
      const finishWithContracts = (text: string) => {
        setOrbitAddressRegistry(getOrbitAddressRegistry(_provider, _signer));
        setOrbit(getOrbit(_provider, _signer));
        setOrbitAddressRegistryV2(
          getOrbitAddressRegistryV2(_provider, _signer)
        );
        setOrbitAuction(getOrbitAuction(_provider, _signer));
        setOrbitCollectionRegistryV2(
          getOrbitCollectionRegistryV2(_provider, _signer)
        );
        setOrbitCollectionRegistry(
          getOrbitCollectionRegistry(_provider, _signer)
        );
        setOrbitRewardsDistributor(
          getOrbitRewardsDistributor(_provider, _signer)
        );
        setOrbitAuctionV2(getOrbitAuctionV2(_provider, _signer));
        setWEVMOS(getWEVMOS(_provider, _signer));
        setOrbitTokenRegistry(getOrbitTokenRegistry(_provider, _signer));
        setOrbitBundles(getOrbitBundles(_provider, _signer));
        setOrbitV2(getOrbitV2(_provider, _signer));
        setBoredApesTest(getBoredApesTest(_provider, _signer));
        setCoolCatsCollabsTest(getCoolCatsCollabsTest(_provider, _signer));
        setCurrency(getCurrency(_provider, _signer));
        setCoolCatsTest(getCoolCatsTest(_provider, _signer));
        setERC1155(getERC1155(_provider, _signer));
        setERC20(getERC20(_provider, _signer));
        setERC721(getERC721(_provider, _signer));
        finish(text);
      };
      if (!autoInit && initializeCounter === 0)
        return finish("Auto init turned off.");
      setLoading(true);
      setMessages((old) => [...old, "Initiating Symfoni React"]);
      const providerObject = await getProvider(); // getProvider can actually return undefined, see issue https://github.com/microsoft/TypeScript/issues/11094

      if (!subscribed || !providerObject)
        return finish("No provider or signer.");
      const _provider = providerObject.provider;
      setProvider(_provider);
      setMessages((old) => [
        ...old,
        "Useing " + providerObject.hardhatProviderName,
      ]);
      setCurrentHardhatProvider(providerObject.hardhatProviderName);
      const _signer = await getSigner(
        _provider,
        providerObject.hardhatProviderName
      );

      if (!subscribed || !_signer)
        return finishWithContracts("Provider, without signer.");
      setSigner(_signer);
      setMessages((old) => [...old, "Useing signer"]);
      const address = await _signer.getAddress();

      if (!subscribed || !address)
        return finishWithContracts("Provider and signer, without address.");
      setCurrentAddress(address);

      return finishWithContracts("Completed Symfoni context initialization.");
    };
    doAsync();
    return () => {
      subscribed = false;
    };
  }, [initializeCounter]);

  const getOrbitAddressRegistry = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitAddressRegistryDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitAddressRegistry__factory.connect(contractAddress, _signer)
      : OrbitAddressRegistry__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitAddressRegistry = {
      instance: instance,
      factory: _signer ? new OrbitAddressRegistry__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbit = (_provider: providers.Provider, _signer?: Signer) => {
    const contractAddress = OrbitDeployment.receipt.contractAddress;
    const instance = _signer
      ? Orbit__factory.connect(contractAddress, _signer)
      : Orbit__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbit = {
      instance: instance,
      factory: _signer ? new Orbit__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitAddressRegistryV2 = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitAddressRegistryV2Deployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitAddressRegistryV2__factory.connect(contractAddress, _signer)
      : OrbitAddressRegistryV2__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitAddressRegistryV2 = {
      instance: instance,
      factory: _signer
        ? new OrbitAddressRegistryV2__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitAuction = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? OrbitAuction__factory.connect(ethers.constants.AddressZero, _signer)
      : OrbitAuction__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniOrbitAuction = {
      instance: instance,
      factory: _signer ? new OrbitAuction__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitCollectionRegistryV2 = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitCollectionRegistryV2Deployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitCollectionRegistryV2__factory.connect(contractAddress, _signer)
      : OrbitCollectionRegistryV2__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitCollectionRegistryV2 = {
      instance: instance,
      factory: _signer
        ? new OrbitCollectionRegistryV2__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitCollectionRegistry = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitCollectionRegistryDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitCollectionRegistry__factory.connect(contractAddress, _signer)
      : OrbitCollectionRegistry__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitCollectionRegistry = {
      instance: instance,
      factory: _signer
        ? new OrbitCollectionRegistry__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitRewardsDistributor = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitRewardsDistributorDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitRewardsDistributor__factory.connect(contractAddress, _signer)
      : OrbitRewardsDistributor__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitRewardsDistributor = {
      instance: instance,
      factory: _signer
        ? new OrbitRewardsDistributor__factory(_signer)
        : undefined,
    };
    return contract;
  };
  const getOrbitAuctionV2 = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress = OrbitAuctionV2Deployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitAuctionV2__factory.connect(contractAddress, _signer)
      : OrbitAuctionV2__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitAuctionV2 = {
      instance: instance,
      factory: _signer ? new OrbitAuctionV2__factory(_signer) : undefined,
    };
    return contract;
  };
  const getWEVMOS = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? WEVMOS__factory.connect(ethers.constants.AddressZero, _signer)
      : WEVMOS__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniWEVMOS = {
      instance: instance,
      factory: _signer ? new WEVMOS__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitTokenRegistry = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    const contractAddress =
      OrbitTokenRegistryDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitTokenRegistry__factory.connect(contractAddress, _signer)
      : OrbitTokenRegistry__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitTokenRegistry = {
      instance: instance,
      factory: _signer ? new OrbitTokenRegistry__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitBundles = (_provider: providers.Provider, _signer?: Signer) => {
    const contractAddress = OrbitBundlesDeployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitBundles__factory.connect(contractAddress, _signer)
      : OrbitBundles__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitBundles = {
      instance: instance,
      factory: _signer ? new OrbitBundles__factory(_signer) : undefined,
    };
    return contract;
  };
  const getOrbitV2 = (_provider: providers.Provider, _signer?: Signer) => {
    const contractAddress = OrbitV2Deployment.receipt.contractAddress;
    const instance = _signer
      ? OrbitV2__factory.connect(contractAddress, _signer)
      : OrbitV2__factory.connect(contractAddress, _provider);
    const contract: SymfoniOrbitV2 = {
      instance: instance,
      factory: _signer ? new OrbitV2__factory(_signer) : undefined,
    };
    return contract;
  };
  const getBoredApesTest = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? BoredApesTest__factory.connect(ethers.constants.AddressZero, _signer)
      : BoredApesTest__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniBoredApesTest = {
      instance: instance,
      factory: _signer ? new BoredApesTest__factory(_signer) : undefined,
    };
    return contract;
  };
  const getCoolCatsCollabsTest = (
    _provider: providers.Provider,
    _signer?: Signer
  ) => {
    let instance = _signer
      ? CoolCatsCollabsTest__factory.connect(
          ethers.constants.AddressZero,
          _signer
        )
      : CoolCatsCollabsTest__factory.connect(
          ethers.constants.AddressZero,
          _provider
        );
    const contract: SymfoniCoolCatsCollabsTest = {
      instance: instance,
      factory: _signer ? new CoolCatsCollabsTest__factory(_signer) : undefined,
    };
    return contract;
  };
  const getCurrency = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? Currency__factory.connect(ethers.constants.AddressZero, _signer)
      : Currency__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniCurrency = {
      instance: instance,
      factory: _signer ? new Currency__factory(_signer) : undefined,
    };
    return contract;
  };
  const getCoolCatsTest = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? CoolCatsTest__factory.connect(ethers.constants.AddressZero, _signer)
      : CoolCatsTest__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniCoolCatsTest = {
      instance: instance,
      factory: _signer ? new CoolCatsTest__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC1155 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC1155__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC1155__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC1155 = {
      instance: instance,
      factory: _signer ? new ERC1155__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC20 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC20__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC20__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC20 = {
      instance: instance,
      factory: _signer ? new ERC20__factory(_signer) : undefined,
    };
    return contract;
  };
  const getERC721 = (_provider: providers.Provider, _signer?: Signer) => {
    let instance = _signer
      ? ERC721__factory.connect(ethers.constants.AddressZero, _signer)
      : ERC721__factory.connect(ethers.constants.AddressZero, _provider);
    const contract: SymfoniERC721 = {
      instance: instance,
      factory: _signer ? new ERC721__factory(_signer) : undefined,
    };
    return contract;
  };
  const handleInitProvider = (provider?: string) => {
    if (provider) {
      setProviderPriority((old) =>
        old.sort((a, b) => {
          return a === provider ? -1 : b === provider ? 1 : 0;
        })
      );
    }
    setInitializeCounter(initializeCounter + 1);
  };
  return (
    <SymfoniContext.Provider
      value={{
        init: (provider) => handleInitProvider(provider),
        providers: providerPriority,
        currentHardhatProvider,
        loading,
        messages,
      }}
    >
      <ProviderContext.Provider value={[provider, setProvider]}>
        <SignerContext.Provider value={[signer, setSigner]}>
          <CurrentAddressContext.Provider
            value={[currentAddress, setCurrentAddress]}
          >
            <OrbitAddressRegistryContext.Provider value={OrbitAddressRegistry}>
              <OrbitContext.Provider value={Orbit}>
                <OrbitAddressRegistryV2Context.Provider
                  value={OrbitAddressRegistryV2}
                >
                  <OrbitAuctionContext.Provider value={OrbitAuction}>
                    <OrbitCollectionRegistryV2Context.Provider
                      value={OrbitCollectionRegistryV2}
                    >
                      <OrbitCollectionRegistryContext.Provider
                        value={OrbitCollectionRegistry}
                      >
                        <OrbitRewardsDistributorContext.Provider
                          value={OrbitRewardsDistributor}
                        >
                          <OrbitAuctionV2Context.Provider
                            value={OrbitAuctionV2}
                          >
                            <WEVMOSContext.Provider value={WEVMOS}>
                              <OrbitTokenRegistryContext.Provider
                                value={OrbitTokenRegistry}
                              >
                                <OrbitBundlesContext.Provider
                                  value={OrbitBundles}
                                >
                                  <OrbitV2Context.Provider value={OrbitV2}>
                                    <BoredApesTestContext.Provider
                                      value={BoredApesTest}
                                    >
                                      <CoolCatsCollabsTestContext.Provider
                                        value={CoolCatsCollabsTest}
                                      >
                                        <CurrencyContext.Provider
                                          value={Currency}
                                        >
                                          <CoolCatsTestContext.Provider
                                            value={CoolCatsTest}
                                          >
                                            <ERC1155Context.Provider
                                              value={ERC1155}
                                            >
                                              <ERC20Context.Provider
                                                value={ERC20}
                                              >
                                                <ERC721Context.Provider
                                                  value={ERC721}
                                                >
                                                  {showLoading && loading ? (
                                                    props.loadingComponent ? (
                                                      props.loadingComponent
                                                    ) : (
                                                      <div>
                                                        {messages.map(
                                                          (msg, i) => (
                                                            <p key={i}>{msg}</p>
                                                          )
                                                        )}
                                                      </div>
                                                    )
                                                  ) : (
                                                    props.children
                                                  )}
                                                </ERC721Context.Provider>
                                              </ERC20Context.Provider>
                                            </ERC1155Context.Provider>
                                          </CoolCatsTestContext.Provider>
                                        </CurrencyContext.Provider>
                                      </CoolCatsCollabsTestContext.Provider>
                                    </BoredApesTestContext.Provider>
                                  </OrbitV2Context.Provider>
                                </OrbitBundlesContext.Provider>
                              </OrbitTokenRegistryContext.Provider>
                            </WEVMOSContext.Provider>
                          </OrbitAuctionV2Context.Provider>
                        </OrbitRewardsDistributorContext.Provider>
                      </OrbitCollectionRegistryContext.Provider>
                    </OrbitCollectionRegistryV2Context.Provider>
                  </OrbitAuctionContext.Provider>
                </OrbitAddressRegistryV2Context.Provider>
              </OrbitContext.Provider>
            </OrbitAddressRegistryContext.Provider>
          </CurrentAddressContext.Provider>
        </SignerContext.Provider>
      </ProviderContext.Provider>
    </SymfoniContext.Provider>
  );
};
