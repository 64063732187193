import Modal from "react-modal";
import Button from "./Button";
import { ethers } from "ethers";
import Approve from "./Approve";
import { useAcceptOffer } from "../hooks/marketTransactions";
import { nFormatter } from "helpers/formatNumber";
import { getTokenName } from "helpers/tokens";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function AcceptOfferModal({ closeModal, data, collectionMeta }) {
  const {
    step,
    loading,
    transactionStarted,
    handleAcceptOffer,
    handleApprove,
    acceptOffer,
  } = useAcceptOffer(data);

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Accept offer
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>

          {transactionStarted && (
            <Approve
              step={step}
              success={"Offer accepted!"}
              approve={"Approve"}
              closeModal={closeModal}
              action={"Accept offer"}
              loading={loading}
              applyStep1={handleApprove}
              applyStep2={acceptOffer}
            />
          )}

          {!transactionStarted && (
            <>
              <div className="flex border-gray border-t py-4 justify-between px-10 ">
                <div className="flex items-center gap-6">
                  <div className="w-24 self-center flex items-center justify-center">
                    <img
                      alt="marketCard"
                      className="rounded-xl"
                      src={data?.image}
                    />
                  </div>
                  <div className="flex gap-1 self-start flex-col">
                    <span className="text-lg text-purple font-semibold">
                      {collectionMeta?.name}
                    </span>
                    <span className=" font-medium">{getTokenName(data)}</span>
                  </div>
                </div>
              </div>
              <div className="flex px-10 flex-col">
                <div className="flex justify-between flex-1">
                  <span className="text-gray text-sm font-semibold">
                    Platform fee
                  </span>
                  <div className="border-b mb-1 border-gray flex-1 border-dotted mx-1.5" />
                  <span className="text-gray self-end text-sm font-semibold">
                    2.5%
                  </span>
                </div>
                <div className="flex justify-between flex-1">
                  <span className="text-gray text-sm font-semibold">
                    Creator fee
                  </span>
                  <div className="border-b mb-1 border-gray flex-1 border-dotted mx-1.5" />

                  <span className="text-gray self-end text-sm font-semibold">
                    {collectionMeta.royalty ? collectionMeta.royalty / 100 : ""}
                    %
                  </span>
                </div>
              </div>
              <div className="flex px-10 mt-4 border-gray pb-4 border-b justify-between">
                <span className="font-semibold text-lg">Subtotal</span>
                <div className="flex items-center gap-1">
                  <span className="font-semibold text-lg">
                    {data?.pricePerItem
                      ? nFormatter(
                          ethers.utils.formatEther(data?.pricePerItem),
                          2
                        )
                      : ""}{" "}
                  </span>
                  <span className="font-semibold">{"WEVMOS"}</span>
                </div>
              </div>
              <div className="w-48 text-white py-6 self-center">
                <Button
                  onClick={handleAcceptOffer}
                  disabled={loading}
                  text={loading ? "loading..." : "Accept offer"}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AcceptOfferModal;
