import { useBundleTokensData } from "helpers/tokens";
import { useState } from "react";
import cs from "classnames";
import UpArrow from "assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "assets/icons/arrow-down-svgrepo-com.svg";
import { Link } from "react-router-dom";
import { collectionMeta } from "helpers/collectionInfo";

function BundleItemRow({ collectionNFTAddress, itemId, imageSrc }) {
  const itemLinkAddress = `/nft/${collectionNFTAddress}/${itemId}`;
  const collectionLinkAddress = `/collection/${collectionNFTAddress}`;
  const collectionName = collectionMeta[collectionNFTAddress]?.name;
  return (
    <Link to={itemLinkAddress}>
      <div className="flex text-white p-2 gap-5">
        <img alt="imag" className="w-20 h-20" src={imageSrc} />
        <div>
          <Link to={collectionLinkAddress}>
            <p className="text-purple">{collectionName}</p>
          </Link>
          <p>#{itemId}</p>
        </div>
      </div>
    </Link>
  );
}

export default function BundleBreakdownTable({ bundle }) {
  const [isOpen, setIsOpen] = useState(true);
  const tokensData = useBundleTokensData(bundle?.tokenIds, bundle?.nftAddress);

  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark  cursor-pointer bg-transparent  py-3  px-4",
          {
            "border-b border-darkGray": isOpen,
            "rounded-b-lg": !isOpen,
          }
        )}
      >
        <span className="font-semibold text-white text-lg">
          {bundle?.tokenIds?.length} Items
        </span>
        <img alt="arrow indicator" src={isOpen ? UpArrow : DownArrow} />
      </div>
      {isOpen && (
        <div className="bg-grayDark ">
          {bundle?.tokenIds?.map((tokenId, idx) => (
            <BundleItemRow
              collectionNFTAddress={bundle.nftAddress}
              itemId={tokenId}
              imageSrc={tokensData[idx]?.image}
            />
          ))}
        </div>
      )}
    </div>
  );
}
