import Button from "components/Button";
import TokenCard from "components/TokenCard";

const NFT_PREVIEW_WIDTH = 200;
const NFT_PREVIEW_HEIGHT = NFT_PREVIEW_WIDTH * 1.5;
const NFT_PREVIEW_SCALE = 0.4;

function SelectedNFTPreview({ token, onClick }) {
  const scaleTransform = `scale(${NFT_PREVIEW_SCALE})`;
  return (
    <div
      style={{
        width: NFT_PREVIEW_WIDTH * NFT_PREVIEW_SCALE,
        height: NFT_PREVIEW_HEIGHT * NFT_PREVIEW_SCALE,
      }}
    >
      <div
        style={{
          transform: `${scaleTransform}`,
          transformOrigin: "top left",
        }}
      >
        <TokenCard
          onClick={() => onClick(token)}
          width={NFT_PREVIEW_WIDTH}
          profile={true}
          key={`${token.nftAddress}:${token.tokenId}`}
          data={token}
        />
      </div>
    </div>
  );
}

export default function BundleSelectionTray({
  selectedNFTs,
  setSelectedNFTs,
  onCreateBundleClicked,
  onNFTXout,
}) {
  return (
    <div className="border-t border-white border-solid w-full px-6 bg-dark">
      <div className="flex justify-between">
        {selectedNFTs.length === 0 ? (
          <div className="w-full h-40 flex-1 flex justify-center items-center">
            <p className="text-xl text-white">Select NFTs to create a bundle</p>
          </div>
        ) : (
          <div className="overflow-y-scroll flex-1 h-40">
            <div className="flex gap-2 py-6 flex-wrap w-full">
              {selectedNFTs.map((nft) => (
                <SelectedNFTPreview
                  key={nft.id}
                  token={nft}
                  onClick={onNFTXout}
                />
              ))}
            </div>
          </div>
        )}
        <div style={{ maxWidth: 200 }} className="flex pl-5 py-6 items-end">
          <Button
            text="Create Bundle"
            onClick={onCreateBundleClicked}
            disabled={selectedNFTs.length < 1}
          />
        </div>
      </div>
    </div>
  );
}
