import { useState } from "react";
import Button from "components/Button";
import Filter from "components/filters/Filter";
import HorizontalSelector from "components/basics/HorizontalSelector";
import { payTokenMeta, evmosTokenMeta } from "helpers/payTokens";

export default function PriceFilter({
  onApply,
  onPayTokenChanged,
  defaultOpen = null,
}) {
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [payTokenAddress, setPayTokenAddress] = useState(
    evmosTokenMeta.address
  );

  return (
    <Filter label="Price" defaultOpen={defaultOpen}>
      <div className="flex gap-5 py-6 px-1 flex-col">
        <HorizontalSelector
          selectedValue={payTokenAddress}
          onValueChanged={(address) => {
            onPayTokenChanged(payTokenMeta[address]);
            setPayTokenAddress(address);
          }}
          options={Object.keys(payTokenMeta)
            .filter(
              (tokenAddress) => payTokenMeta[tokenAddress].wrapped === false
            )
            .map((tokenAddress) => ({
              value: payTokenMeta[tokenAddress].address,
              label: payTokenMeta[tokenAddress].name,
            }))}
        />
        <div className="flex self-center items-center gap-4">
          <input
            value={min}
            onChange={(e) =>
              setMin(
                parseInt(e.target.value) >= 0 || !e.target.value
                  ? e.target.value
                  : null
              )
            }
            type="number"
            className="w-28 outline-none bg-darkGray  py-2 px-4"
            placeholder="Min"
          />
          <span className="font-semibold text-white">to</span>
          <input
            value={max}
            onChange={(e) =>
              setMax(
                parseInt(e.target.value) >= 0 || !e.target.value
                  ? e.target.value
                  : null
              )
            }
            type="number"
            className="w-28 outline-none bg-darkGray  py-2 px-4"
            placeholder="Max"
          />
        </div>
        <div className="w-24 self-center">
          <Button
            onClick={() =>
              onApply({ min, max, payToken: payTokenMeta[payTokenAddress] })
            }
            disabled={(!max && !min) || (max === 0 && min === 0)}
            text="Apply"
          />
        </div>
      </div>
    </Filter>
  );
}
