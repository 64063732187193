import { ethers } from "ethers";
import moment from "moment";
import { useEVM } from "../../../hooks/EVMhook";
import {
  loadingToast,
  dismissToast,
  successToast,
} from "../../../components/Toasts";
import { handleError } from "../../../helpers/errors";
import { useState } from "react";
import { useTokenData, getTokenName } from "../../../helpers/tokens";
import Orb from "../../../assets/icons/WOrb.svg";
import cs from "classnames";
import { Link } from "react-router-dom";

export default function MyOfferRow({ data }) {
  const [loading, setLoading] = useState();
  const { orbitContract } = useEVM();
  const [imageLoaded, setImageLoaded] = useState(false);

  const meta = useTokenData(
    data?.tokenId,
    data?.nftAddress,
    data?.token?.isERC1155
  );

  async function handleCancel() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.cancelOffer(
        data.nftAddress,
        data?.tokenId
      );
      const toastId = loadingToast("Canceling offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("Offer canceled!");
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleError(error);
    }
  }

  return (
    <div className="flex px-4 py-3 pl-8 border-b border-darkGray items-center">
      <Link
        to={`/nft/${data?.nftAddress}/${data?.tokenId}`}
        className="w-5/12 flex items-center gap-2"
      >
        <img
          onLoad={() => setImageLoaded(true)}
          className={cs("w-9 h-9 rounded-full", {
            hidden: !imageLoaded,
            flex: imageLoaded,
          })}
          src={meta?.image}
          alt="item"
        />
        {!imageLoaded && (
          <div className="w-9 h-9 bg-darkGray rounded-full animate-pulse" />
        )}
        <span className="text-purple font-semibold">
          {getTokenName({ ...data, ...meta, name: data?.token?.name })}
        </span>
      </Link>
      <div className="flex w-3/12 gap-2 items-center">
        <img className="h-3 mt-0.5" alt="eth" src={Orb} />
        <span className=" text-white">
          {ethers.utils.formatEther(data?.pricePerItem)}
        </span>
      </div>
      <span className="w-4/12 text-white">
        {`${moment(new Date(data?.deadline * 1000).toISOString()).format(
          "MMMM Do YYYY, h:mm a"
        )}`}
      </span>
      <div className="flex w-36">
        <button
          disabled={loading}
          onClick={() => {
            handleCancel();
          }}
          className={cs(
            " h-8 font-bold text-lightbg rounded-md w-full bg-purple",
            {
              "opacity-40": loading,
            }
          )}
        >
          {"Cancel offer"}
        </button>
      </div>
    </div>
  );
}
