export default function mergeOffersAndCollectionOffers(offerData) {
  let offers = [];

  if (offerData?.offers?.length > 0) {
    offers = offerData?.offers;
  }

  if (offerData?.collectionOffers?.length > 0) {
    const _collectionOffers = offerData?.collectionOffers?.map((co) => {
      return { ...co, type: "collection" };
    });
    offers = [...offers, ..._collectionOffers].sort(
      (a, b) => b.pricePerItem - a.pricePerItem
    );
  }

  return offers;
}
