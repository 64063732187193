import {
  Legend,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Area,
  AreaChart,
} from "recharts";
import ReactResizeDetector from "react-resize-detector";
import cs from "classnames";
import moment from "moment";
import { formatEther } from "helpers/payTokens";

export default function TotalVolumeGraph({ data = [], payToken }) {
  const sorted = [...data];
  let sum = 0;
  const volumeSum = sorted
    ?.sort((a, b) => parseInt(a?.timestamp) - parseInt(b?.timestamp))
    .map((d) => {
      sum += parseFloat(formatEther(d.volume, payToken));
      return {
        Volume: parseFloat(sum.toFixed(2)),
        date: moment(
          new Date(parseInt(d?.timestamp) * 1000).toISOString()
        ).format("D/M"),
      };
    });

  return (
    <div className="flex w-full border rounded-lg border-darkGray  bg-darkGray bg-opacity-20 flex-col">
      <div
        className={cs(
          "flex w-full justify-between rounded-t-lg bg-dark border-b border-darkGray  bg-transparent  py-3  px-4"
        )}
      >
        <span className="font-semibold text-white pl-4 text-lg">
          Total Volume
        </span>
      </div>
      <div className=" flex flex-col ">
        <ReactResizeDetector>
          {({ width }) =>
            width > 0 ? (
              <div className="py-3  h-80 overflow-hidden relative">
                <div className="absolute overflow-hidden">
                  <AreaChart
                    syncId={"collection"}
                    width={width}
                    height={300}
                    data={volumeSum}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#8884d8"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#8884d8"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Legend />
                    <ChartTooltip />

                    <CartesianGrid stroke="#343444" />
                    <Area
                      type="monotone"
                      dataKey="Volume"
                      stroke="#8884d8"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                  </AreaChart>
                </div>
              </div>
            ) : (
              <div>{width}</div>
            )
          }
        </ReactResizeDetector>
      </div>
    </div>
  );
}
