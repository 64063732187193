import { useState } from "react";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

function Header() {
  const [show, setShow] = useState(false);
  const [showWrapModal, setShowWrapModal] = useState(false);

  return (
    <>
      {show && (
        <MobileHeader
          setShowWrapModal={setShowWrapModal}
          show={show}
          setShow={setShow}
        />
      )}
      <DesktopHeader
        showWrapModal={showWrapModal}
        setShowWrapModal={setShowWrapModal}
        show={show}
        setShow={setShow}
      />
    </>
  );
}

export default Header;
