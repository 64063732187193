import { useState } from "react";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import cs from "classnames";
import { WaitForTransaction } from "graphql/api";
import { useDispatch } from "react-redux";
import { incrementTransactionCounter } from "redux/slices/dataRefreshSlice";

export default function CancelAuction({ nftAddress, tokenId }) {
  const dispatch = useDispatch();
  const { auctionContract } = useEVM();

  const [loading, setLoading] = useState(false);

  async function handleCancelAuction() {
    setLoading(true);
    try {
      const transaction = await auctionContract?.instance?.cancelAuction(
        nftAddress,
        tokenId
      );
      const toastId = loadingToast("Canceling auction...");
      auctionContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(async () => {
          await WaitForTransaction(transaction.hash);
          setLoading(false);
          dismissToast(toastId);
          successToast("Auction canceled!");
          dispatch(incrementTransactionCounter());
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  return (
    <button
      disabled={loading}
      onClick={() => {
        handleCancelAuction();
      }}
      className={cs("h-8 font-bold text-white rounded-md w-36  bg-purple", {
        "opacity-60": loading,
      })}
    >
      {"Cancel auction"}
    </button>
  );
}
