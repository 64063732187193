import TokenCard from "components/TokenCard";
import { ethers } from "ethers";
import { UserTokens } from "graphql/api";
import CheckboxFilter from "components/filters/CheckboxFilter";
import PriceFilter from "components/filters/PriceFilter";
import CollectionFilter from "components/filters/CollectionFilter";
import { RECENTLY_LISTED } from "components/sorting/SortingDropdownMenu";
import { useURLState, JSONURLState } from "helpers/URLState";
import SortableFilterableInfiniteScrollingNFTList from "components/SortableFilterableInfiniteScrollingNFTList";
import TokenWidthMeasuringContainer from "components/TokenWidthMeasuringContainer";
import { useState, useEffect } from "react";
import { useCallWith404OnFailure } from "helpers/Hooks";
import BundleSelectionTray from "pages/Profile/CreateBundle/BundleSelectionTray";
import BundleCreationModal from "pages/Profile/CreateBundle/BundleCreationModal";
import { errorToast } from "components/Toasts";
import { Link } from "react-router-dom";
import { evmosTokenMeta } from "helpers/payTokens";

const BUNDLE_SIZE_LIMIT = 10;

export default function CreateBundle({ collections, userAddress }) {
  const {
    selectedCollection,
    setSelectedCollection,
    filters,
    setFilters,
    sortingOption,
    setSortingOption,
  } = useURLState({
    selectedCollection: JSONURLState(null),
    filters: JSONURLState({}),
    sortingOption: JSONURLState(RECENTLY_LISTED),
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedNFTIDs, setSelectedNFTIDs] = useState([]);
  const [allNFTs, setAllNFTs] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);
  const [priceFilterPayToken, setPriceFilterPayToken] =
    useState(evmosTokenMeta);

  const fetchPage = useCallWith404OnFailure(async (pageNum) => {
    return await UserTokens(
      userAddress,
      filters,
      sortingOption?.value,
      searchValue,
      pageNum,
      true
    );
  });

  function handleFilterRemove(filter) {
    if (filter.filter === "nftAddress") {
      setSelectedNFTIDs([]);
      setSelectedCollection(null);
    }
    const updatedFilters = { ...filters };
    delete updatedFilters[filter.filter];
    if (
      updatedFilters.pricePerItem_gt == null &&
      updatedFilters.pricePerItem_lt == null &&
      updatedFilters.payToken != null
    ) {
      delete updatedFilters["payToken"];
    }
    setFilters(updatedFilters);
  }

  function applyStatusFilter(onlyShowListed) {
    const { listed, ...otherFilters } = filters;
    if (onlyShowListed) {
      setFilters({ ...otherFilters, listed: true });
    } else {
      setFilters(otherFilters);
    }
  }

  function applyPriceFilters({ min, max, payToken }) {
    const updatedFilters = { ...filters, payToken: payToken.address };
    if (min !== 0 && min) {
      updatedFilters["pricePerItem_gt"] = ethers.utils
        .parseEther(min)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_gt"];
    }
    if (max !== 0 && max) {
      updatedFilters["pricePerItem_lt"] = ethers.utils
        .parseEther(max)
        .toString();
    } else {
      delete updatedFilters["pricePerItem_lt"];
    }
    setFilters(updatedFilters);
  }

  function applyCollectionFilter(value) {
    if (selectedCollection === value) {
      return;
    }
    setSelectedNFTIDs([]);
    setFilters({ ...filters, ...{ nftAddress: value } });
    setSelectedCollection(value);
  }

  const isPriceSortApplied = sortingOption.value.orderBy === "pricePerItem";
  const isPriceFilterApplied =
    filters.pricePerItem_gt != null || filters.pricePerItem_lt != null;
  useEffect(() => {
    if (isPriceSortApplied) {
      if (filters.payToken !== priceFilterPayToken.address) {
        setFilters({ ...filters, payToken: priceFilterPayToken.address });
      }
    } else if (!isPriceFilterApplied && filters.payToken != null) {
      const updatedFilters = { ...filters };
      delete updatedFilters["payToken"];
      setFilters(updatedFilters);
    }
  }, [
    isPriceSortApplied,
    isPriceFilterApplied,
    filters.payToken,
    priceFilterPayToken.address,
    filters,
    setFilters,
  ]);

  const allFilters = [
    <CheckboxFilter
      filterLabel="Status"
      checkboxLabel="Listed"
      value={filters.listed === true}
      onChange={applyStatusFilter}
      defaultOpen
    />,
    <PriceFilter
      onApply={applyPriceFilters}
      defaultOpen
      onPayTokenChanged={setPriceFilterPayToken}
    />,
    <CollectionFilter
      onCollectionSelected={applyCollectionFilter}
      collections={collections}
      selectedCollection={selectedCollection}
      userAddress={userAddress}
    />,
  ];

  let nftIdToObject = Object.assign(
    {},
    ...allNFTs.map((nft) => ({ [nft.id]: nft }))
  );
  const selectedNFTs = selectedNFTIDs.map((id) => nftIdToObject[id]);

  const hasCollectionSelected = selectedCollection != null;
  const overrideComponent = !hasCollectionSelected ? (
    <div className="w-full flex justify-center items-center py-10">
      <p className="text-xl">Select a collection to create a bundle.</p>
    </div>
  ) : null;

  function onRemoveTokenFromSelection(tokenId) {
    setSelectedNFTIDs(selectedNFTIDs.filter((nft) => nft !== tokenId));
  }

  function onAddTokenToSelection(tokenId) {
    if (selectedNFTIDs.length === BUNDLE_SIZE_LIMIT) {
      errorToast(
        `Can't add more NFTs to this bundle because you've reached the bundle size limit of ${BUNDLE_SIZE_LIMIT} NFTs.`
      );
      return;
    }
    setSelectedNFTIDs([...selectedNFTIDs, tokenId]);
  }

  return (
    <div className="w-full">
      <SortableFilterableInfiniteScrollingNFTList
        filters={filters}
        filterComponentList={allFilters}
        handleFilterRemove={handleFilterRemove}
        overrideComponent={overrideComponent}
        onSortingOptionChange={setSortingOption}
        sortingOption={sortingOption}
        onChangeSearchValue={setSearchValue}
        onDataChanged={(data) => setAllNFTs(data)}
        renderEmptyState={() => (
          <div className="flex-1 font-semibold text-white text-2xl text-center py-12 px-8">
            You don't own any NFTs yet. Visit the{" "}
            <Link to="/collections">marketplace</Link> to buy NFTs.
          </div>
        )}
        fetchPage={fetchPage}
        sensitivityListForPaginationReset={[
          filters,
          sortingOption,
          userAddress,
          searchValue,
        ]}
        renderData={(data) => (
          <TokenWidthMeasuringContainer>
            {({ cardWidth }) =>
              data?.map((token, index) => (
                <TokenCard
                  onClick={() => {
                    const tokenId = token.id;
                    if (selectedNFTIDs.includes(tokenId)) {
                      onRemoveTokenFromSelection(tokenId);
                    } else {
                      onAddTokenToSelection(tokenId);
                    }
                  }}
                  width={cardWidth}
                  profile={true}
                  isSelected={selectedNFTIDs.includes(token.id)}
                  key={`${token.nftAddress}:${token.tokenId}`}
                  data={token}
                />
              ))
            }
          </TokenWidthMeasuringContainer>
        )}
      />
      {hasCollectionSelected && (
        <>
          <div className="h-40" />
          <div className="fixed bottom-0 w-full">
            <BundleSelectionTray
              selectedNFTs={selectedNFTs}
              onNFTXout={(token) => {
                setSelectedNFTIDs(
                  selectedNFTIDs.filter((nft) => nft !== token.id)
                );
              }}
              onCreateBundleClicked={() => {
                if (selectedNFTs.length > 1) {
                  setIsModalShown(true);
                } else {
                  errorToast(
                    "You need to select more than 1 NFT to create a bundle."
                  );
                }
              }}
            />
          </div>
        </>
      )}
      {isModalShown && (
        <BundleCreationModal
          closeModal={() => setIsModalShown(false)}
          selectedNFTs={selectedNFTs}
        />
      )}
    </div>
  );
}
