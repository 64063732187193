import ListingCoinDropdown from "components/CoinDropdowns/ListingCoinDropdown";
import { useEffect, useState } from "react";

import ActivityGraph from "./ActivityGraph";
import ActivityList from "./ActivityList";
import { options as paytokenDropdownOptionsUnfiltered } from "../CoinDropdowns/ListingCoinDropdown";
import TotalVolumeGraph from "./TotalVolumeGraph";
import OwnersGraph from "./OwnersGraph";

const paytokenDropdownOptions = paytokenDropdownOptionsUnfiltered.filter(
  (item) => item.wrapped === false
);

export default function Activity({
  nftAddress,
  fetchCollectionDailyStats,
  fetchActivityPage,
  collectionMeta,
}) {
  const [dailyStats, setDailyStats] = useState([]);
  const [payToken, setPayToken] = useState(paytokenDropdownOptions[0]);
  useEffect(() => {
    async function fetch() {
      const dailyStats = await fetchCollectionDailyStats(nftAddress, payToken);
      setDailyStats(dailyStats);
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftAddress, payToken]);

  return (
    <div className="flex gap-10 flex-col w-full max-w-7xl ">
      <div className="relative z-20">
        <ListingCoinDropdown
          options={paytokenDropdownOptions}
          onChange={setPayToken}
          value={payToken}
        />
      </div>
      <ActivityGraph payToken={payToken.value} data={dailyStats} />

      <div className="flex  gap-4">
        <TotalVolumeGraph payToken={payToken.value} data={dailyStats} />
        <OwnersGraph payToken={payToken.value} data={dailyStats} />
      </div>

      <ActivityList
        collectionMeta={collectionMeta}
        payToken={payToken}
        fetchPage={fetchActivityPage}
      />
    </div>
  );
}
