import UpArrow from "../../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../../assets/icons/arrow-down-svgrepo-com.svg";
import cs from "classnames";
import { useState } from "react";
import { formatToken } from "helpers/tokens";
import { Link } from "react-router-dom";

export default function Details({ data, collectionData, collection }) {
  const [open, setOpen] = useState(true);

  return (
    <div className="flex w-full text-white   flex-col">
      <div
        onClick={() => setOpen(!open)}
        className="flex border-b px-4 py-2.5 justify-between items-center border-darkGray rounded-t-lg bg-transparent"
      >
        <span className="font-semibold text-lg">About</span>
        <img alt="arrow indicator" src={open ? UpArrow : DownArrow} />
      </div>
      {open && (
        <div
          className={cs(
            "py-4 border-b border-darkGray flex flex-col gap-3 p-5"
          )}
        >
          <div className="flex justify-between">
            <span>Contract Address</span>
            <Link
              className="text-purple"
              to={`/collection/${collection}`}
            >{`${collection.substring(0, 6)}...${collection.substring(
              37,
              42
            )}`}</Link>
          </div>
          <div className="flex justify-between">
            <span>Token ID</span>
            <span>{formatToken(data.tokenId)}</span>
          </div>
          <div className="flex justify-between">
            <span>Token Standard</span>
            <span>{data?.isERC1155 ? "ERC-1155" : "ERC-721"}</span>
          </div>
          <div className="flex justify-between">
            <span>Royalty</span>
            <span>{collectionData?.royalty / 100}%</span>
          </div>
        </div>
      )}
    </div>
  );
}
