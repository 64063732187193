import cs from "classnames";

import Filter from "components/filters/Filter";

import Check from "assets/icons/check.svg";
import { ERC721Balance } from "graphql/api";
import { useState, useEffect } from "react";

function BalanceCircle({ balance }) {
  return (
    <span
      style={{ minWidth: 32, height: 32, lineHeight: 0.9 }}
      className="relative flex align-center justify-center align-middle rounded-full bg-purple text-white text-center text-md font-bold p-2"
    >
      {balance}
    </span>
  );
}

function CollectionRow({
  onCollectionSelected,
  collection,
  selectedCollection,
  userAddress,
}) {
  const [balance, setBalance] = useState(null);
  useEffect(() => {
    if (userAddress == null) {
      return;
    }
    ERC721Balance(collection.contract, userAddress).then(setBalance);
  }, [collection.contract, userAddress]);

  const isSelected =
    selectedCollection &&
    selectedCollection?.toLowerCase() === collection?.contract?.toLowerCase();
  return (
    <div
      onClick={() => onCollectionSelected(collection?.contract?.toLowerCase())}
      key={collection?.contract?.toLowerCase()}
      className={cs(
        "flex px-4 py-3 cursor-pointer hover:bg-darkGray justify-between items-center"
      )}
    >
      <div className="flex justify-between align-center w-full">
        <div className="flex items-center gap-2">
          {isSelected ? (
            <div className="border text-center border-purple p-1.5 rounded-full w-8 h-8">
              <img alt="check" src={Check} />
            </div>
          ) : (
            <img
              alt="collection"
              src={collection?.imageURL}
              className="w-8 rounded-full"
            />
          )}
          <span className="text-white">{collection?.name}</span>
        </div>
        {balance != null && balance !== 0 && (
          <BalanceCircle balance={balance} />
        )}
      </div>
    </div>
  );
}

export default function CollectionFilter({
  onCollectionSelected,
  collections,
  selectedCollection,
  userAddress,
}) {
  return (
    <Filter label="Collection">
      <div className="flex py-3 flex-col">
        {collections.map((collection) => {
          return (
            <CollectionRow
              onCollectionSelected={onCollectionSelected}
              collection={collection}
              selectedCollection={selectedCollection}
              userAddress={userAddress}
              key={collection?.contract?.toLowerCase()}
            />
          );
        })}
      </div>
    </Filter>
  );
}
