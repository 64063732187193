import { ethers } from "ethers";
import WOrb from "../../assets/icons/Orb.svg";
import { useEVM } from "../../hooks/EVMhook";
import { loadingToast, dismissToast, successToast } from "../Toasts";
import { handleError } from "../../helpers/errors";
import { useState } from "react";
import { nFormatter } from "helpers/formatNumber";
import cs from "classnames";

export default function CancelCollectionOffer({
  collectionData,
  offerData,
  closeModal,
}) {
  const { orbitContract } = useEVM();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleCancel() {
    setLoading(true);
    try {
      const transaction = await orbitContract?.instance?.cancelCollectionOffer(
        offerData.nftAddress
      );
      const toastId = loadingToast("Canceling collection offer...");
      orbitContract?.instance?.provider
        ?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          dismissToast(toastId);
          successToast("Collection offer cancelled!");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  return (
    <>
      <div className="flex border-gray gap-5 flex-col border-b border-t py-4 justify-between px-10 ">
        <span className="text-gray text-center text-sm">
          It appears you already have an offer. Cancel the offer below, then
          submit a new one!
        </span>
        <div className="flex flex-col">
          <div className="flex items-center justify-around">
            <div className="gap-2  flex items-start flex-col justify-center">
              <span className="font-semibold text-lg text-purple">
                {collectionData?.name}
              </span>
              <img
                className="rounded-xl w-18 h-18"
                alt="marketCard"
                src={collectionData?.imageURL}
              />
            </div>
            <div className="flex mr-1 flex-col">
              <span className="text-white">Offer</span>
              <div className="flex text-xl gap-1.5 font-bold items-center">
                <img className="h-3 mt-0.5" alt="eth" src={WOrb} />
                {nFormatter(
                  ethers?.utils?.formatEther(offerData?.pricePerItem),
                  2
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-4 self-center">
        <button
          disabled={loading}
          onClick={() => (success ? closeModal() : handleCancel())}
          className={cs("font-bold h-8 text-white rounded-md w-36 bg-purple", {
            "opacity-40": loading,
          })}
        >
          {success ? "Offer canceled" : loading ? "Loading..." : "Cancel offer"}
        </button>
      </div>
    </>
  );
}
