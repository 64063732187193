import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Internet from "../../assets/icons/internet.svg";
import Telegram from "../../assets/icons/telegram.svg";

import cs from "classnames";

function getSocialImage(type) {
  if (type === "web") {
    return Internet;
  } else if (type === "twitter") {
    return Twitter;
  } else if (type === "discord") {
    return Discord;
  } else if (type === "telegram") {
    return Telegram;
  }
}

function getSocialPadding(type) {
  if (type === "discord") {
    return "p-2.5";
  } else if (type === "telegram") {
    return "p-2.5 pt-3";
  } else {
    return "p-3";
  }
}

export default function Socials({ collectionInfo }) {
  return (
    <div className="border inline-flex border-darkGray rounded-lg">
      {collectionInfo?.socials?.map((social, index) => (
        <a
          target="_blank"
          key={index}
          href={social.value}
          className={cs(
            " inline-table hover:bg-darkGray w-10 ",
            getSocialPadding(social.type),
            {
              "rounded-l-md": index === 0,
              "rounded-r-md": index === collectionInfo?.socials.length - 1,
            }
          )}
          rel="noreferrer"
        >
          <img className="" alt="internet" src={getSocialImage(social.type)} />
        </a>
      ))}
    </div>
  );
}
