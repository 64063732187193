let collections = require("./collectionInfo").collectionMeta;

export function search(query) {
  let results = [];
  for (var address in collections) {
    let re = new RegExp(".*" + query + ".*", "i");
    if (re.test(collections[address].name)) {
      results.push({ ...collections[address], address });
    }
  }
  return results;
}
