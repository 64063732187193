export default function FourOhFour() {
  return (
    <div className=" w-screen h-screen flex items-center justify-center text-white">
      <span className="max-w-2xl text-center">
        <div className="text-9xl text-purple">404</div>
        <div className="h-6"></div>
        <div>
          The page you're looking for may have never existed or it was moved.
        </div>
        <div>Check your URL and try again.</div>
      </span>
    </div>
  );
}
