import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import CoverSection from "./CoverSection";
import Perks from "./Perks";
import Powered from "./Powered";
import Staking from "./Staking";
import NFTPreview from "./NFTPreview";
import TopCollections from "./TopCollections";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <div className="flex items-center  flex-col w-full">
      <CoverSection />

      <Perks />
      <NFTPreview />
      <TopCollections />

      <Staking />
      <Powered />

      <div className="  py-20 bg-purple items-center flex flex-col w-full">
        <div
          data-aos="zoom-in-up"
          className="flex flex-col gap-14 w-full max-w-7xl"
        >
          <div className="flex flex-col gap-4">
            <span className="text-center text-5xl text-white font-semibold self-center">
              What are you waiting for?
              <br />
              Explore now!
            </span>
            <Link
              to="/listings"
              className="bg-purple border-2 border-dark self-center font-medium text-center text-lg w-32 px-5 py-1 rounded-full text-white"
            >
              Explore
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b py-7 border-t border-dark from-purple to-purple justify-center flex w-full">
        <div className="flex justify-between w-full max-w-7xl">
          <div className="flex flex-col gap-2 w-full max-w-7xl">
            <div className="flex text-white items-center gap-1">
              <span>Copyright 2022 Orbit Marketplace</span>
            </div>
          </div>
          <div className="flex self-end gap-3">
            <div className=" w-8 h-8 rounded-full">
              <a
                target="_blank"
                href="http://discord.gg/orbitalapes"
                rel="noreferrer"
              >
                <img className="w-full" alt="discord" src={Discord} />
              </a>
            </div>
            <div className=" w-7 h-7 rounded-full">
              <a
                target="_blank"
                href="https://twitter.com/OrbitMarketOS"
                rel="noreferrer"
              >
                <img className="w-full" alt="twitter" src={Twitter} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
