import { useState } from "react";
import cs from "classnames";
import UpArrow from "assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "assets/icons/arrow-down-svgrepo-com.svg";

export default function Filter({ label, defaultOpen = false, children }) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="flex  w-full flex-col" key={label}>
      <div
        onClick={() => setOpen(!open)}
        className={cs(
          "flex justify-between cursor-pointer bg-dark  py-2  px-4",
          {
            "border-b border-darkGray": open,
          }
        )}
      >
        <span className="font-semibold text-lg">{label}</span>
        <img
          className="w-5"
          alt="arrow indicator"
          src={open ? UpArrow : DownArrow}
        />
      </div>
      {open && children}
    </div>
  );
}
