import { useState } from "react";
import Modal from "react-modal";
import Button from "./Button";
import cs from "classnames";
import { useSendItem } from "../hooks/marketTransactions";
import { getTokenName } from "helpers/tokens";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "#202036",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

function SendModal({
  closeModal = {},
  data = {},
  collectionMeta = {},
  erc1155Balance,
}) {
  const [destinationAddress, setDestinationAddress] = useState(0);
  const [erc1155Quantity, setErc1155Quantity] = useState(1);

  const { send, loading, send1155 } = useSendItem();

  async function handleSend() {
    if (data?.isERC1155) {
      send1155(
        destinationAddress,
        data?.nftAddress,
        data?.tokenId,
        erc1155Balance?.owner,
        erc1155Quantity,
        () => {
          closeModal();
        }
      );
    } else {
      send(
        destinationAddress,
        data?.nftAddress,
        data?.tokenId,
        data?.owner,
        () => {
          closeModal();
        }
      );
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white w-100 flex pt-4 flex-col gap-1">
          <div className="flex px-4 pt-2 pb-4 items-center">
            <span className="text-xl pl-8 w-full text-center font-bold">
              Send NFT
            </span>
            <button
              className="text-2xl pr-4 justify-self-end text-gray font-semibold"
              onClick={closeModal}
            >
              X
            </button>
          </div>

          <div className="flex border-gray border-t py-4 justify-between px-10 ">
            <div className="flex items-center gap-6">
              <div className="w-24 self-center flex items-center justify-center">
                <img
                  alt="marketCard"
                  className="rounded-xl"
                  src={data?.image}
                />
              </div>
              <div className="flex gap-1 self-start flex-col">
                <span className="text-lg text-purple font-semibold">
                  {collectionMeta?.name}
                </span>
                <span className=" font-medium">{getTokenName(data)}</span>
              </div>
            </div>
          </div>

          {data?.isERC1155 && (
            <div className="flex px-10 gap-2.5 flex-col">
              <span className="font-bold">
                Quantity
                <span className="text-sm font-light ml-2">
                  (amount of NFTS to send)
                </span>
              </span>

              <div className="flex items-center bg-darkGray border justify-center border-darkGray rounded">
                <input
                  value={erc1155Quantity}
                  onChange={(e) => setErc1155Quantity(e.target.value)}
                  min="0"
                  type="number"
                  className="border rounded text-white px-2 py-1.5 flex-1 h-8"
                  placeholder="Quantity"
                />
              </div>
              <span className="self-end -mt-1 text-sm">
                Balance: {erc1155Balance?.amount}
              </span>
            </div>
          )}

          <div
            className={cs(
              "text-xs border-gray pb-4 w-full flex px-10 flex-col font-bold border-b gap-2.5 text-gray"
            )}
          >
            <span className="text-white text-lg font-semibold">Address</span>
            <input
              onChange={(e) => setDestinationAddress(e.target.value)}
              className="border rounded text-white px-2 py-1.5"
              placeholder="enter destination address"
            />
          </div>
          <div className="w-48 text-white py-6 self-center">
            <Button
              onClick={handleSend}
              disabled={
                loading ||
                !destinationAddress ||
                destinationAddress === "" ||
                destinationAddress === undefined
              }
              text={loading ? "loading..." : "Send"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SendModal;
