import { ethers } from "ethers";
import { LandingStats } from "graphql/api";
import { useEffect, useState } from "react";
import cs from "classnames";
import { collectionMeta } from "../../helpers/collectionInfo";
import { Link } from "react-router-dom";

const _ = require("lodash");

function Row({ data, address }) {
  const hourChange = () => {
    if (data?.daily == null) {
      return 0;
    }
    const sorted = data?.daily?.sort((a, b) => a.timestamp - b.timestamp);
    if (sorted.length < 2)
      return parseFloat(ethers.utils.formatEther(sorted[0].volume)) / 0.1;

    return (
      ((parseFloat(ethers.utils.formatEther(sorted[sorted.length - 1].volume)) -
        parseFloat(
          ethers.utils.formatEther(sorted[sorted.length - 2].volume)
        )) /
        parseFloat(
          ethers.utils.formatEther(sorted[sorted.length - 2].volume)
        )) *
      100
    ).toFixed(2);
  };
  const dayChange = () => {
    if (data?.daily == null) {
      return 0;
    }
    const sorted = data?.daily?.sort((a, b) => a.timestamp - b.timestamp);

    if (sorted.length < 7)
      return (
        sorted.reduce(
          (acc, a) => acc + parseFloat(ethers.utils.formatEther(a.volume)),
          0
        ) * 100
      ).toFixed(2);

    return (
      ((parseFloat(ethers.utils.formatEther(sorted[sorted.length - 1].volume)) -
        parseFloat(ethers.utils.formatEther(sorted[0].volume))) /
        parseFloat(ethers.utils.formatEther(sorted[0].volume))) *
      100
    ).toFixed(2);
  };

  return (
    <div className="flex py-6 border-b items-center border-darkGray w-full">
      <div className="flex w-4/12 text-right items-center gap-3">
        <img
          alt="detail"
          src={collectionMeta[address.toLowerCase()].imageURL}
          className="w-10 h-10 rounded-full"
        />
        <Link
          to={"/collection/" + address.toLowerCase()}
          className="font-medium text-purple"
        >
          {collectionMeta[address.toLowerCase()].name}
        </Link>
      </div>
      <span className="font-medium md:w-1/12 text-right w-2/12">
        {parseFloat(ethers.utils.formatEther(data.stats.sellsVolume)).toFixed(
          0
        )}
      </span>
      <span
        className={cs("font-medium md:w-2/12 hidden md:block text-right", {
          "text-green": hourChange() > 0,
          "text-red": hourChange() < 0,
        })}
      >
        {hourChange() > 0 ? "+" + hourChange() : hourChange()}%
      </span>
      <span
        className={cs("font-medium w-3/12 md:w-2/12 text-right", {
          "text-green": dayChange() > 0,
          "text-red": dayChange() < 0,
        })}
      >
        {dayChange() > 0 ? "+" + dayChange() : dayChange()}%
      </span>
      <span className="font-medium w-2/12 text-right">
        {ethers.utils.formatEther(data?.floor?.listings[0].pricePerItem)}
      </span>
      <span className="font-medium w-1/12 hidden md:block text-right">
        {data?.stats?.numOwners}
      </span>
      <span className="font-medium w-1/12 hidden md:block text-right">
        {data?.stats?.nftCount}
      </span>
    </div>
  );
}

export default function TopCollections() {
  const [daily, setDaily] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetInfo() {
      const stats = await LandingStats();
      const daily = _.chain(stats.landingDailyStats.collectionDailyStats)
        .groupBy("nftAddress")
        .value();
      setDaily(daily);
      setData(stats);
    }
    fetInfo();
  }, []);

  function renderRow() {
    const _d = data?.landingStats?.collectionSummaries?.map((cs) => {
      const floor = data.floors.find(
        (f) => f["listings"][0]?.nftAddress === cs.id
      );
      if (floor == null) {
        return null;
      }
      return (
        <Row
          address={cs.id}
          data={{
            daily: daily[cs.id],
            floor,
            stats: cs,
          }}
        />
      );
    });
    return _d;
  }

  return (
    <div className="bg-gradient-to-b py-28 px-5 bg-dark items-center flex justify-center flex-wrap  w-full">
      <div className="max-w-5xl items-center text-white  flex-col gap-20 flex justify-center flex-wrap  w-full">
        <span className="text-center text-3xl font-semibold self-center">
          Top NFT collections by Volume
        </span>
        <div className="flex w-full flex-col">
          <div className="flex pb-6 border-b border-darkGray font-semibold w-full">
            <span className="font-bold  w-4/12">Collection</span>
            <span className="font-bold text-right md:w-1/12 w-2/12">
              Volume
            </span>
            <span className="font-bold text-right hidden md:block w-2/12">
              24h%
            </span>
            <span className="font-bold text-right w-3/12 md:w-2/12">7d%</span>
            <span className="font-bold text-right w-3/12 md:w-2/12">
              Floor Price
            </span>
            <span className="font-bold text-right hidden md:block w-1/12">
              Owners
            </span>
            <span className="font-bold text-right hidden md:block w-1/12">
              Items{" "}
            </span>
          </div>
          <div className="flex flex-col w-full">{renderRow()}</div>
        </div>
      </div>
    </div>
  );
}
