import TraitFilter from "components/filters/TraitFilter";

const _ = require("lodash");

export function getListOfTraitFilterComponents({
  traits,
  selectedTraits,
  applyTraitFilter,
}) {
  if (traits?.length <= 0) return [];
  const traitsGroupedByType = _.chain(traits).groupBy("type").value();
  const traitFilters = [];
  for (let traitType in traitsGroupedByType) {
    let traitsForType = traitsGroupedByType[traitType];
    traitFilters.push(
      <TraitFilter
        traits={traitsForType}
        type={traitType}
        selectedTraits={selectedTraits}
        onApply={applyTraitFilter}
      />
    );
  }
  return traitFilters;
}
