import { createSlice } from "@reduxjs/toolkit";

export const dataRefreshSlice = createSlice({
  name: "dataRefresh",
  initialState: {
    transactionCounter: 0,
  },
  reducers: {
    incrementTransactionCounter: (state) => {
      state.transactionCounter += 1;
    },
  },
});

export const { incrementTransactionCounter } = dataRefreshSlice.actions;

export default dataRefreshSlice.reducer;
